import locales from 'locales';
import React from 'react';
import { BaseComponent, componentInjection, getJSON, putJSON } from '../../../utils';
import { Space, Icon } from '../../../ui';
import { Button, Input, message } from 'udesk-ui';
//该处不可引入models中导出，会导致国际化locales.get取不到值， 所用导入当页面写死。此处引入的值在对应的models中改变时，该页面也需要手动更改
// import { alarmRecordsModelKey, handleStatusTypes, processingMethods, alarmIgnoreStatus } from '../../../models/alarm';
import Ignore from './ignore';
import CreateModal from '../../mission/modal';
class Default extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            warningData: null,
            status: null,
            ignoranceState: null,
            inputTextarea: null,
            datas: null,
        };

        //将原本从model中导入的数据卸载页面中
        this.alarmRecordsModelKey = 'earlyWarningRecord';
        this.handleStatusTypesModel = [
            { name: locales.get('models-9jl5qfmeul'), value: 0, color: '#F75959', classname: 'info-right-statusFalse' },
            { name: locales.get('models-m7xx4w4sjfk'), value: 1, color: '#0D1326', classname: 'info-right-statusTrue' },
        ];

        this.processingMethodsModel = [
            { name: locales.get('components-alarm-records-x2l95mylhgk'), value: 1 },
            { name: locales.get('components-alarm-records-mk4y8hxi2b'), value: 2 },
        ];

        this.alarmIgnoreStatusModel = {
            ignore: {
                text: locales.get('components-alarm-records-x2l95mylhgk'),
                value: 1,
                methods: {
                    edit: { text: locales.get('components-alarm-records-qglhwr0awhp'), value: 0 },
                    editEnd: { text: locales.get('models-l2myge3yff'), value: 1 },
                },
            },
        };
    }

    componentDidMount(props) {
        if (this.props.id) {
            this.reload();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id && this.props.id !== null) {
            this.reload();
        }
    }
    getOrganizationName(data) {
        if (data && data['applyOrganizationIds'] !== null) {
            let organName = '';
            // if (key == 'applyOrganizationIds') {
            let orglist = data['applyOrganizationIds'].split(';');
            let orgArr = [];
            if (orglist) {
                orglist.map((items) => {
                    let listId = items.split(',');
                    listId.map((itemss, index) => {
                        let organObj = this.appContext.organizationList.find(
                            (current) => current.id === parseInt(itemss, 10)
                        );
                        if (typeof organObj !== 'undefined') {
                            if (index === listId.length - 1) {
                                organName += organObj.name + ' ';
                                orgArr = [...orgArr, itemss];
                            } else {
                                if (listId.length - index <= 2) organName += organObj.name + '-';
                            }
                        }

                        return organName;
                    });

                    return organName;
                });
            }
            return {
                ...data,
                applyOrganizationIds: organName,
                orgArr,
            };
        } else {
            return data;
        }
    }

    reload = () => {
        const { id } = this.props;
        getJSON(`/${this.alarmRecordsModelKey}/${id}`).always((res) => {
            if (res.data) {
                this.setState({
                    loading: false,
                    warningData: this.getOrganizationName(res.data) || {},
                    status: res.data.status,
                });
            }
        });
    };

    onShowModal = () => {
        this.setState({
            modalVisible: true,
        });
    };
    onModalClose = () => {
        this.setState({
            modalVisible: false,
        });
    };
    editorChange = (value) => {
        this.setState({
            warningData: {
                ...this.state.warningData,
                describe: value,
            },
        });
    };
    ignoreTask() {
        this.setState({ ignoreVisible: true });
    }
    onIgnoreClose = (visible) => {
        this.setState({
            ignoreVisible: visible,
        });
    };
    onIgnoreOk = (data) => {
        this.updateIgnore(data);
        if (this.props.onCloseDrawer) {
            this.props.onCloseDrawer(false);
        }
    };
    editignore = () => {
        const { warningData } = this.state;
        this.setState({
            ignoranceState: this.alarmIgnoreStatusModel.ignore.methods.edit.value,
            inputTextarea: warningData.ignoreReason,
        });
    };
    onCloseIngnore = () => {
        this.setState({
            ignoranceState: this.alarmIgnoreStatusModel.ignore.methods.editEnd.value,
        });
    };
    onChangeIgnore = (e) => {
        this.setState({
            inputTextarea: e.target.value,
        });
    };
    ignoreError = () => {
        message.error(locales.get('components-alarm-records-t6j7t9xavqi'));
    };
    onSubmitIgnore = () => {
        if (this.state.inputTextarea === '') {
            this.ignoreError();
        } else {
            const { inputTextarea } = this.state;
            this.updateIgnore(inputTextarea);
            this.setState({
                ignoranceState: this.alarmIgnoreStatusModel.ignore.methods.editEnd.value,
            });
        }
    };
    onOk = (dataId) => {
        this.updateTask(dataId);
    };
    updateTask(data) {
        const url = `/${this.alarmRecordsModelKey}/${this.props.id}`;
        let datas = {
            taskId: data,
        };
        this.setState({ ignoreVisible: false });
        putJSON(url, datas).always((res) => {
            if (res.ok) {
                this.reload();
                this.props.onloadList();
            }
        });
    }
    updateIgnore(data) {
        const url = `/${this.alarmRecordsModelKey}/${this.props.id}`;
        let datas = {
            ignoreReason: data,
        };
        this.setState({ ignoreVisible: false });
        putJSON(url, datas).always((res) => {
            if (res.ok) {
                this.reload();
                this.props.onloadList();
            }
        });
    }
    render() {
        const { warningData, ignoreVisible, modalVisible } = this.state;
        const { recordReload } = this.props;
        return (
            <div style={{ height: '100%' }}>
                <div className="alarm-record-processing-box">
                    <div className="alarm-content">
                        <div className="info-box" style={{ marginBottom: '10px', lineHeight: '1.5' }}>
                            <div className="info-left-small">
                                {warningData && warningData.code && `预警编号：${warningData.code}`}
                            </div>
                            <div className="info-right-small">
                                {locales.get('components-alarm-records-kz6bnpqdqqp')}
                                <span style={{ verticalAlign: 'top' }}>{warningData && warningData.triggerTime}</span>
                            </div>
                        </div>
                        <div className="right-title-yu">
                            <div className="right-title-icon">
                                <Icon type="yujingzhongleibeifen" />
                            </div>

                            <h2 className="right-title-name">{warningData && warningData.warningName}</h2>
                        </div>
                        <div className="info-box info-box-alarm">
                            <div className="info-left">
                                <Icon type="yujingzhuangtaibeifen" style={{ color: '#CFD0D4' }} />
                                <span className="left-name">{locales.get('components-alarm-records-mnw48l2f0fa')}</span>
                            </div>
                            <div className="info-right">
                                {warningData ? (
                                    <span
                                        className={
                                            this.handleStatusTypesModel.find(
                                                (current) => current.value === warningData.status
                                            ).classname
                                        }
                                    >
                                        {
                                            this.handleStatusTypesModel.find(
                                                (current) => current.value === warningData.status
                                            ).name
                                        }
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="info-box info-box-alarm">
                            <div className="info-left">
                                <Icon type="xiangmubeifen" />
                                <span className="left-name">{locales.get('components-alarm-records-cw5hsss95y8')}</span>
                            </div>
                            <div className="info-right">
                                <span>{warningData && warningData.projectName}</span>
                            </div>
                        </div>
                        <div className="info-box info-box-alarm">
                            <div className="info-left">
                                <Icon type="tiaojianbeifen" />
                                <span className="left-name">{locales.get('components-alarm-records-sw6kum7cjzk')}</span>
                            </div>
                        </div>
                        <div className="info-box info-box-alarm">
                            <div className="info-right-box">
                                {warningData
                                    ? JSON.parse(warningData.triggerReason).map((item, index) => {
                                          return (
                                              <div>
                                                  <div>
                                                      {index + 1}、{item.questionName}
                                                  </div>
                                                  <ul>
                                                      {item.warningInfo &&
                                                          item.warningInfo.map((items) => (
                                                              <li>
                                                                  <span>{items}</span>
                                                              </li>
                                                          ))}
                                                  </ul>
                                              </div>
                                          );
                                      })
                                    : []}
                            </div>
                        </div>
                        <div className="info-box info-box-alarm">
                            <div className="info-left">
                                <Icon type="zhouqibeifen" />
                                <span className="left-name">{locales.get('components-alarm-records-z1qcdkaaho')}</span>
                            </div>

                            <div className="info-right info-box-alarm">
                                <span>
                                    {warningData && warningData.warningPeriod}
                                    {locales.get('components-alarm-records-hphmkyb4ytl')}
                                </span>
                            </div>
                        </div>
                        <div className="info-box info-box-alarm">
                            <div className="info-left">
                                <Icon type="renyuanbeifen" />
                                <span className="left-name">{locales.get('components-alarm-records-g5otott17gv')}</span>
                            </div>
                            <div className="info-right user-box">
                                <span>{warningData && warningData.noticeStaffName}</span>
                            </div>
                        </div>
                        {this.state.status !== null &&
                        this.state.status === this.alarmIgnoreStatusModel.ignore.value ? (
                            <div>
                                <div className="info-box info-box-alarm">
                                    <div className="info-left">
                                        <Icon type="renyuanbeifen" />
                                        <span className="left-name">
                                            {locales.get('components-alarm-records-va60qibcu9')}
                                        </span>
                                    </div>
                                    <div className="info-right user-box">
                                        <span>{warningData && warningData.handlerName}</span>
                                    </div>
                                </div>
                                <div className="info-box info-box-alarm">
                                    <div className="info-left">
                                        <Icon type="zhouqibeifen" />
                                        <span className="left-name">
                                            {locales.get('components-alarm-records-lcq2ds2z93q')}
                                        </span>
                                    </div>
                                    <div className="info-right user-box">
                                        <span>{warningData && warningData.handleTime}</span>
                                    </div>
                                </div>
                                <div className="info-box info-box-alarm">
                                    <div className="info-left">
                                        <Icon type="chulifangshi" />
                                        <span className="left-name">
                                            {locales.get('components-alarm-records-e5n4o9kwjzk')}
                                        </span>
                                    </div>
                                    <div className="info-right user-box">
                                        <span>
                                            {warningData &&
                                                this.processingMethodsModel.find(
                                                    (current) => current.value === warningData.handleType
                                                ).name}
                                        </span>
                                    </div>
                                </div>
                                <div className="info-box info-box-alarm">
                                    <div className="info-left">
                                        <Icon type="chulifangshi" />
                                        <span className="left-name">
                                            {locales.get('components-alarm-records-5bxetb58qa4')}
                                        </span>
                                    </div>
                                    <div className="info-right user-box">
                                        <span>{warningData && warningData.applyOrganizationIds}</span>
                                    </div>
                                </div>

                                {this.state.status !== null &&
                                this.alarmIgnoreStatusModel.ignore.value === warningData.handleType ? (
                                    <div className="info-box info-box-alarm">
                                        <div className="info-left">
                                            <Icon type="hulveyuanyin" />
                                            <span className="left-name">
                                                {locales.get('components-alarm-records-rea89wfs3a')}
                                            </span>
                                        </div>
                                        {this.state.ignoranceState ===
                                        this.alarmIgnoreStatusModel.ignore.methods.edit.value ? (
                                            <div className="info-right">
                                                <Input.TextArea
                                                    type="textarea"
                                                    onChange={this.onChangeIgnore}
                                                    defaultValue={warningData && warningData.ignoreReason}
                                                    style={{ width: '250px' }}
                                                    maxLength={100}
                                                />
                                            </div>
                                        ) : (
                                            <div className="info-right">
                                                <span style={{ display: 'inline-block', width: '250px' }}>
                                                    {warningData && warningData.ignoreReason}
                                                </span>
                                                <Button
                                                    type="link"
                                                    onClick={this.editignore}
                                                    style={{ marginTop: '-5px' }}
                                                >
                                                    {locales.get('components-alarm-records-qglhwr0awhp')}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div></div>
                                )}

                                <div className="info-box-style"></div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>

                {this.state.status !== null && this.state.status !== this.alarmIgnoreStatusModel.ignore.value ? (
                    <div className="info-box-button">
                        <Button style={{ marginLright: 8 }} onClick={this.ignoreTask.bind(this)}>
                            {locales.get('components-alarm-records-x2l95mylhgk')}
                        </Button>
                        <Space />
                        <Button type="primary" onClick={this.onShowModal}>
                            {locales.get('components-alarm-records-mk4y8hxi2b')}
                        </Button>
                    </div>
                ) : this.state.ignoranceState === this.alarmIgnoreStatusModel.ignore.methods.edit.value ? (
                    <div className="info-box-button">
                        <Button style={{ marginLright: 8 }} onClick={this.onCloseIngnore}>
                            {locales.get('components-alarm-records-euc67z93qxl')}
                        </Button>
                        <Space />
                        <Button type="primary" onClick={this.onSubmitIgnore}>
                            {locales.get('components-alarm-records-03ews9u1ytdt')}
                        </Button>
                    </div>
                ) : (
                    <div></div>
                )}

                {ignoreVisible && (
                    <Ignore
                        visible={ignoreVisible}
                        onIgnoreClose={this.onIgnoreClose}
                        showDetailAlarmId={this.props.showDetailAlarmId}
                        onIgnoreOk={this.onIgnoreOk}
                    />
                )}
                <CreateModal
                    visible={modalVisible}
                    onModalClose={this.onModalClose}
                    reload={this.reload}
                    onOkRecords={this.onOk}
                    recordReload={recordReload}
                    isAlarm={true}
                    alarmId={this.props.id}
                />
            </div>
        );
    }
}

export default componentInjection(Default);
