/**
 * 渠道中心搜索参数
 */
import { createReducer, createAction } from 'redux-act';
import Base from '../base';

const base = new Base();

export const objectSetProperties = createAction('objectSetProperties');
export const objectDeleteProperties = createAction('objectDeleteProperties');

export default createReducer(
    {
        [objectSetProperties]: base.objectSetProperties.bind(base),
        [objectDeleteProperties]: base.objectDeleteProperties.bind(base)
    },
    {}
);
