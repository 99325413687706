import { createStore } from 'redux';
import { connect as reduxConnect } from 'react-redux';
import isUndefined from 'lodash/isUndefined';
import filter from 'lodash/filter';
import mergeReducers from './mergeReducers';
import mergeActions from './mergeActions';

const rootReducer = mergeReducers();
const store = createStore(rootReducer);

let actions, data;

export function mapStateToProps(state) {
    data = state;
    return state;
}

export function mapDispatchToProps(dispatch) {
    actions = mergeActions(dispatch);

    return actions;
}

/**
 * 获取actions
 */
export function getActions(moduleName) {
    actions = actions || {};
    return moduleName ? actions[moduleName + 'Actions'] : actions;
}

/**
 * 派发action
 * @param {string} moduleName
 * @param {string} [actionName='upsert']
 * @param {Object} data
 */
export function dispatch(moduleName, actionName, data) {
    let actions = getActions(moduleName);

    if (isUndefined(data)) {
        data = actionName;
        actionName = 'upsert';
    }

    if (actions[actionName] && data) {
        return actions[actionName](data);
    }
}

/**
 * 获取数据
 * @param {string} moduleName
 * @param {Object|Function} [conditions] - 条件
 * @returns {Object[]}
 * @example
 *    getData('organizations', { id: 1 })
 */
export function getData(moduleName, conditions) {
    let result;

    data = data || {};
    if (!moduleName || moduleName === '*') {
        return data;
    }

    result = data[moduleName + 'Reducer'];
    if (conditions) {
        result = filter(result, conditions);
    }
    return result || [];
}

/**
 * 筛选数据
 * @param {string} moduleName
 * @param {string} [key] - 条件键
 * @param {*} [val] - 条件值
 * @returns {Object[]}
 * @example
 *    filterDataBy('organizations', 'id', 1)
 */
export function filterData(moduleName, key, val) {
    return getData(
        moduleName,
        {
            [key]: val
        },
        'filter'
    );
}

/**
 * 查找一条数据
 * @param {string} moduleName
 * @param {string} [key] - 条件键
 * @param {*} [val] - 条件值
 */
export function findData(moduleName, key, val) {
    return getData(
        moduleName,
        {
            [key]: val
        },
        'find'
    );
}

export function dispatchCommon(...params) {
    return dispatch('common', ...params);
}

export function getCommonData(...params) {
    return getData('common', ...params);
}

export function filterCommonData(...params) {
    return filterData('common', ...params);
}

export function findCommonData(...params) {
    return findData('common', ...params);
}

export function connect(Cmp) {
    return reduxConnect(mapStateToProps, mapDispatchToProps)(Cmp);
}

export default function() {
    return store;
}
