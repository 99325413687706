import React from 'react';
import $ from 'jquery';
import isFunction from 'lodash/isFunction';
import has from 'lodash/has';
import isObject from 'lodash/isObject';
import each from 'lodash/each';
import isString from 'lodash/isString';
import filter from 'lodash/filter';

import moment from 'moment';
import classnames from 'classnames';
import withStyles from 'react-jss';
import locales from 'locales';
// import PropTypes from 'prop-types';
import { APP_NAME, DATETIME_FORMAT, LOGIN_URL, DATE_FORMAT, API_PATH, SHORT_DATE_FORMAT } from './consts';
import { SUPPOER_LOCALES } from './locales';

let _id = 0;

export const global = window;
export const {
    document,
    location,
    navigator,
    isNaN,
    Number,
    parseInt,
    parseFloat,
    localStorage,
    sessionStorage,
} = global;
export { $, moment, classnames, withStyles };

export function emptyFn() {}

export function genId() {
    return APP_NAME + ++_id;
}
export function getApiPath() {
    return API_PATH;
}
/**
 * 统一输出日志
 * @param {*} type - 日志类型：log(默认)|warn|error|info|trace|assert
 * @param  {...any} params
 */
export function log(type, ...params) {
    if (isFunction(console[type])) {
        console[type]('[CEM]', ...params);
    } else {
        console.log('[CEM]', type, ...params);
    }
}

export function uuid() {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4();
}

export function toFixed(val) {
    return !isNaN(val) ? Number(val.toFixed(2)) : val;
}

export function isHasOrTrue(obj, key) {
    return has(obj, key) || obj.key === true;
}

export function getAttr(obj, namePath) {
    if (!namePath || !obj) return;

    let arr = namePath.split('.');
    for (var i = 0, l = arr.length; i < l; i++) {
        obj && (obj = obj[arr[i]]);
        if (typeof obj === 'undefined') {
            return;
        }
    }
    return obj;
}

export function getDateRange(interval = 7) {
    let startDate = moment()
        .subtract(interval - 1, 'days')
        .startOf('day');
    let endDate = moment().endOf('day');
    let start = startDate.format(DATETIME_FORMAT);
    let end = endDate.format(DATETIME_FORMAT);

    return {
        startDate: startDate._d,
        endDate: endDate._d,
        start,
        end,
        value: `${start},${end}`,
    };
}

export function getDateRangeArr(interval = 7, format = SHORT_DATE_FORMAT) {
    let date;
    let index = 0;
    let endDate = new Date();
    let result = [];

    if (isObject(format)) {
        format.endDate && (endDate = format.endDate);
        format = format.format || SHORT_DATE_FORMAT;
    }

    date = moment(endDate).subtract(interval - 1, 'days');

    interval = interval > 100 ? 100 : interval;
    while (index < interval) {
        result.push({
            date: moment(date),
            dateStr: date.format(format),
        });
        date.add(1, 'days');
        index++;
    }

    return result;
}

export function dateFormat(date, format = DATETIME_FORMAT) {
    return date ? moment(date).format(format) : '';
}

export function shortDateFormat(date, format = DATE_FORMAT) {
    return dateFormat(date, format);
}

export function numFormat(num, unit = locales.get('utils-56pwkc31ura')) {
    let max = 10000;
    if (num >= max) {
        return parseFloat((num / max).toFixed(2)) + unit || '';
    }
    return num;
}

export function stopEvent(ev) {
    if (ev) {
        ev.preventDefault();
        ev.stopPropagation();
        // ev.nativeEvent.stopImmediatePropagation();
    }
}

/**
 * 深冻结函数
 * @param {Array|Object} obj
 */
export function deepFreeze(obj) {
    const Obj = global.Object;
    const fn = Obj.freeze;

    if (!isFunction(fn)) {
        return obj;
    }

    // 在冻结自身之前冻结属性
    each(obj, (val) => {
        isObject(val) && deepFreeze(val);
    });

    // 冻结自身(no-op if already frozen)
    return Obj.freeze(obj);
}

/**
 * 获取单词单数
 * @param {string} word
 */
export function getWordSingular(word) {
    return word ? ('' + word).replace(/s$/, '') : word;
}

/**
 * 执行方法
 * @param {Object} obj
 * @param {string} method
 * @param  {...any} params
 */
export function execFn(obj, method, ...params) {
    let fn = obj && obj[method];

    if (isFunction(fn)) {
        return fn(...params);
    }
}

export function logout() {
    location.replace(LOGIN_URL);
}

export function getDownloadUrl(modelKey) {
    return `${API_PATH}/dataModels/${getWordSingular(modelKey)}`;
}

export function getImportUrl(modelKey) {
    return `${API_PATH}/${modelKey}/import`;
}

export function isEndRecord({ pageNum, pageSize, total }) {
    return pageNum * pageSize > total;
}

/**
 * 截取字符串字节长度
 * @param {string} str - 原字符串
 * @param {number} maxByteLen - 最大字节长度
 * @param {string} [suffix=..] - 后缀
 */
export function substrByByte(str, maxByteLen, suffix = '..') {
    let result = '';
    if (isString(str)) {
        result = `${str.slice(0, maxByteLen)}${suffix}`;
    }
    return result;
}

export function getByteLength(str) {
    let realLength = 0;
    if (isString(str)) {
        for (let i = 0; i < str.length; i++) {
            if (str.charCodeAt(i) > 255) {
                realLength += 2;
            } else {
                realLength += 1;
            }
        }
    }
    return realLength;
}

export function showTextByArr(arr, separator = '/') {
    return filter(arr, (item) => !!item).join('/');
}

/**
 * 乘法计算
 */
export function multiplication(num1, num2) {
    let baseNum = 0;

    try {
        baseNum += num1.toString().split('.')[1].length;
    } catch (e) {}

    try {
        baseNum += num2.toString().split('.')[1].length;
    } catch (e) {}

    num1 = num1 || '';
    num2 = num2 || '';
    return (
        (Number(num1.toString().replace('.', '')) * Number(num2.toString().replace('.', ''))) / Math.pow(10, baseNum)
    );
}
/**
 *
 * @param {locales} localesConfig 当前页面引入的locales
 */
export function checkIsEnglish(localesConfig) {
    return (
        localesConfig &&
        localesConfig.getInitOptions() &&
        localesConfig.getInitOptions().currentLocale === SUPPOER_LOCALES.find((item) => item.value === 'en-US').value
    );
}
/**
 *
 * @param {*} oneStyleKey 一个样式的style key值 如 width,height
 * @param {*} oneStyleKeyValue  一个样式的style key值 对应的value
 * @param {*} domName dom选择器的名字
 * @param {*} locales 国际化配置
 */
export function setDomOneStyleByEnglishLocale(oneStyleKey, oneStyleKeyValue, domName, locales) {
    const doms = document.querySelectorAll(domName);
    if (doms && doms.length && checkIsEnglish(locales)) {
        doms.forEach((item) => {
            item.style[oneStyleKey] = oneStyleKeyValue;
        });
    }
}

/**
 *
 * @param {locales} localesConfig 当前页面引入的locales
 */
export function getCurrentLocale(localesConfig) {
    return localesConfig && localesConfig.getInitOptions() ? localesConfig.getInitOptions().currentLocale : '';
}

export function TitleSpan(props) {
    return (
        <span {...props} title={props.label || ''}>
            {props.label || ''}
        </span>
    );
}
/**
 * 
 * @param {*} len 要生成的uuid长度
 * @param {*} radix 2/10/16 选择进制
 */
export function generateUUID(len, radix) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [], i;
    radix = radix || chars.length;
    if (len) {
      // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
    } else {
      // rfc4122, version 4 form
        var r;
      // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';
      // Fill in random data.  At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random()*16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuid.join('');
}