import { combineReducers } from 'redux';
import commonReducer from './reducers/common';
import organizationsReducer from './reducers/organizations';
import companyReducer from './reducers/company';
import searchParamsReducer from './reducers/channels/searchParams';
import listParamsReducer from './reducers/project/listParams';
import listStatusReducer from './reducers/project/listStatus';
import columnsFilterReducer from './reducers/project/columnsFilter';
import filterReducer from './reducers/project/filter';

export default function () {
    return combineReducers({
        commonReducer,
        organizationsReducer,
        companyReducer,
        searchParamsReducer,
        listParamsReducer,
        listStatusReducer,
        columnsFilterReducer,
        filterReducer,
    });
}
