import locales from 'locales';
import React from 'react';
import { postJSON, BaseComponent, componentInjection, LONG_DATETIME_FORMAT } from '../../utils';
import extend from 'lodash/extend';

//该处不可引入models中导出，会导致国际化locales.get取不到值，所用导入当页面写死。 此处引入的值在对应的models中改变时，该页面也需要手动更改
// import { missionPriority, missionGoal } from '../../models/mission.js';
import { Editor } from '../../ui';
import { Form } from '@ant-design/compatible';
import '@Styles/_compatibleIndex.css';
import { Modal, Select, Input } from 'udesk-ui'; //antd 4.0 upgrade
import UsersSelect from '../common/select/users';
// import { truncate } from 'fs';
import { DatePicker as AntdDatePicker } from 'udesk-ui';

class Default extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            ccIds: [],
            isGoalDone: true,
            description: this.props.modalImg,
            mode: 'time',
            confirmLoading: false,
        };

        this.missionPriority = [
            { key: '3', color: '#F75959', text: locales.get('models-ld7h4tlgxce'), value: 3 },
            { key: '2', color: '#FC8D01', text: locales.get('models-1q9nqkllnyx'), value: 2 },
            { key: '1', color: '#FFD54F', text: locales.get('components-notice-detail-design-j0oz0x4neks'), value: 1 },
            { key: '0', color: '#53C205', text: locales.get('models-1cjqryc156i'), value: 0 },
        ];

        this.missionGoal = [
            { key: '0', text: locales.get('models-bjy9vbq0iz5'), value: 0 },
            { key: '1', text: locales.get('models-watryj2yvk'), value: 1 },
            { key: '2', text: locales.get('models-4lbxwlswpq4'), value: 2 },
            { key: '3', text: locales.get('components-common-ueditor-lang-zh-cn-66exlvjuey'), value: 3 },
        ];
    }

    selectPaperInputChange = (id) => {
        let { form } = this.props;
        let obj = {};
        obj[`leaderUserId`] = id;
        obj[`deadlineTime`] = null;
        form.setFieldsValue(obj);
        form.validateFields([`leaderUserId`], { force: true });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {
            //  onModalClose,
            onOkRecords,
        } = this.props;
        const url = `/tasks`;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    confirmLoading: true,
                });
                postJSON(
                    url,
                    (values = extend(values, {
                        alarmId: this.props.isAlarm ? this.props.alarmId : undefined,
                        type: this.props.isAlarm ? 1 : 0,
                        deadlineTime: values.deadlineTime.format('YYYY-MM-DD HH:mm'),
                        ccs: values.ccs
                            ? values.ccs.map((item) => {
                                  return { userId: item };
                              })
                            : [],
                        description: this.state.description,
                    }))
                ).always((res) => {
                    this.setState({
                        confirmLoading: false,
                    });
                    if (res.ok) {
                        // onModalClose && onModalClose();
                        this.resetModal();
                        this.props.form.resetFields();
                        onOkRecords && onOkRecords(res.data.id);
                        this.props.reload && this.props.reload();
                        this.props.recordReload && this.props.recordReload();
                    }
                });
            }
        });
    };

    resetModal = () => {
        this.setState({
            isGoalDone: true,
        });
        this.props.onModalClose();
    };

    render() {
        const { visible, modalImg } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { isGoalDone, mode, confirmLoading } = this.state;
        return (
            <Modal
                destroyOnClose
                visible={visible}
                className="mission-modal-box"
                title={locales.get('components-mission-4tflarmsagn')}
                onCancel={this.resetModal}
                maskClosable={false}
                width={'660px'}
                confirmLoading={confirmLoading}
                onOk={this.handleSubmit}
            >
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 15 }} onSubmit={this.handleSubmit}>
                    <Form.Item label={locales.get('components-mission-510c1eqap6q')}>
                        {getFieldDecorator('target', {
                            rules: [
                                {
                                    required: true,
                                    message: locales.get(
                                        'components-customercenter-group-components-dimension-components-select-58ne333gvk8'
                                    ),
                                },
                            ],
                        })(
                            <Select
                                placeholder={locales.get('components-mission-okekesc79ah')}
                                style={{ width: 160 }}
                                onChange={(value) => {
                                    if (value || value === 0) {
                                        this.setState({
                                            isGoalDone: false,
                                        });
                                    } else {
                                        this.setState({
                                            isGoalDone: true,
                                        });
                                    }
                                }}
                                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            >
                                <Select.Option key="-1" value={''}>
                                    {locales.get('components-mission-okekesc79ah')}
                                </Select.Option>
                                {this.missionGoal.map((item) => (
                                    <Select.Option key={item.key} value={item.value}>
                                        {item.text}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={locales.get('components-mission-bx0r9cwga5l')}>
                        {getFieldDecorator('name', {
                            rules: [
                                {
                                    required: true,
                                    message: locales.get('components-common-select-storageBox-igkqv6d4zp'),
                                },
                                { max: 50, message: locales.get('utils-vhji5bk6mro') },
                            ],
                        })(
                            <Input
                                placeholder={locales.get('components-mission-tdw9wjxro9c')}
                                autoComplete="off"
                                style={{ width: 420 }}
                                disabled={isGoalDone}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={locales.get('components-mission-3mjtdll3d98')}>
                        {getFieldDecorator('leaderUserId', {
                            rules: [
                                {
                                    required: true,
                                    message: locales.get('components-common-select-storageBox-igkqv6d4zp'),
                                },
                            ],
                        })(
                            <UsersSelect
                                style={{ width: 160 }}
                                placeholder={locales.get('components-mission-olbgkdnx6n')}
                                disabled={isGoalDone}
                            ></UsersSelect>
                        )}
                    </Form.Item>
                    <Form.Item label={locales.get('components-mission-3vl7gl207n9')}>
                        {getFieldDecorator('deadlineTime', {
                            rules: [
                                {
                                    required: true,
                                    message: locales.get(
                                        'components-customercenter-group-components-dimension-components-select-58ne333gvk8'
                                    ),
                                },
                            ],
                        })(
                            <AntdDatePicker
                                format={LONG_DATETIME_FORMAT}
                                disabled={isGoalDone}
                                mode={mode}
                                showTime={{ format: 'HH:mm' }}
                                style={{ width: 160 }}
                                onPanelChange={(moment, item) => {
                                    this.setState({ mode: item });
                                }}
                                onOpenChange={(open) => {
                                    if (open) {
                                        this.setState({ mode: 'date' });
                                    }
                                }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={locales.get('components-mission-2wvjydylcnf')}>
                        {getFieldDecorator('priority', {
                            initialValue: 3,
                        })(
                            <Select
                                style={{ width: 80 }}
                                disabled={isGoalDone}
                                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            >
                                {this.missionPriority.map((item) => (
                                    <Select.Option key={item.key} value={item.value}>
                                        {item.text}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={locales.get('components-mission-pydfsgxkaa')}>
                        {getFieldDecorator(
                            'ccs',
                            {}
                        )(
                            <UsersSelect
                                disabled={isGoalDone}
                                style={{ width: 420 }}
                                mode={'multiple'}
                                className="tree-box"
                                placeholder={locales.get('components-mission-21tzm2z04vu')}
                            ></UsersSelect>
                        )}
                    </Form.Item>
                    <Form.Item label={locales.get('components-mission-o9e4omkg1q')}>
                        {getFieldDecorator(
                            'description',
                            {}
                        )(
                            <div style={{ width: 420 }}>
                                <Editor
                                    defaultValue={modalImg}
                                    onChange={(value) => {
                                        this.setState({
                                            description: value,
                                        });
                                    }}
                                    config={{
                                        toolbar: [
                                            'bold italic underline alignleft aligncenter alignright link image fontsizeselect fontselect',
                                        ],
                                    }}
                                />
                            </div>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
Default = Form.create({})(Default);
export default componentInjection(Default);
