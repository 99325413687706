import { bindActionCreators } from 'redux';
import * as commonActions from './reducers/common';
import * as organizationsActions from './reducers/organizations';
import * as companyActions from './reducers/company';
import * as searchParamsActions from './reducers/channels/searchParams';
import * as listParamsActions from './reducers/project/listParams';
import * as listStatusActions from './reducers/project/listStatus';
import * as columnsFilterActions from './reducers/project/columnsFilter';
import * as filterActions from './reducers/project/filter';

export default function (dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        organizationsActions: bindActionCreators(organizationsActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        searchParamsActions: bindActionCreators(searchParamsActions, dispatch),
        listParamsActions: bindActionCreators(listParamsActions, dispatch),
        listStatusActions: bindActionCreators(listStatusActions, dispatch),
        columnsFilterActions: bindActionCreators(columnsFilterActions, dispatch),
        filterActions: bindActionCreators(filterActions, dispatch),
    };
}
