import locales from 'locales';
import React from 'react';
import { Input, message } from 'udesk-ui';
// loadJS, APP_STATIC_SRV_HOST,
import { genId, emptyFn, getAttr, uuid, getJSON, loadJS, APP_STATIC_SRV_HOST } from '../../utils';

import extend from 'lodash/extend';
import each from 'lodash/each';
import isArray from 'lodash/isArray';

import { UIBaseComponent } from '../../utils/baseComponent';

import oss from './oss.js';
export const defaultConfig = {
    menubar: false,
    branding: false,
    elementpath: false,
    convert_urls: false,
    relative_urls: false,
    paste_data_images: false,
    powerpaste_allow_local_images: false,
    remove_script_host: false,
    document_base_url: '',
    default_link_target: '_blank',
    removed_menuitems: 'newdocument',
    extended_valid_elements:
        '-table[id|border=2|cellspacing|cellpadding|width=300|frame|rules|' +
        'height=100|align|summary|bgcolor|background|bordercolor|style=border-color:black]',
    contextmenu: 'link image inserttable | cell row column deletetable | paste',
    plugins: [
        'advlist autolink lists link image charmap print preview anchor textcolor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code wordcount imagetools',
    ],
    toolbar: [
        'bold italic underline',
        'alignleft aligncenter alignright',
        'bullist numlist outdent indent',
        'formatselect fontsizeselect',
        'link image imageupload',
        'fontselect forecolor backcolor code',
    ].join(' | '),
    language: 'zh_CN',
    images_upload_handler: function (blobInfo, success, failure) {
        getJSON(`/oss-token`).always((res) => {
            if (res.ok) {
                let resp = res.data;
                let options = {
                    file: blobInfo.blob(),
                    ossHost: resp.host,
                    calculateObjectName: `${resp.dir}/${uuid()}/${blobInfo.blob().name}`,
                    ossPolicy: resp.policy,
                    ossAccessId: resp.accessId,
                    ossSignature: resp.signature,
                    progressCallback: (file, schedule) => {},
                    successCallback: (file, fileData) => {
                        let imgUrl = resp.host + '/' + fileData.filename;
                        success(imgUrl);
                    },
                    errorCallback: (file, reason, fileData) => {
                        failure(locales.get('ui-editor-eh8l6edyee'));
                    },
                };
                oss.upload(options);
            } else {
                message.error(locales.get('components-insight-card-kw7gdyfrjnh'));
            }
        });
    },
    block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;',
};

export class Editor extends UIBaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: genId(),
            value: props.value,
        };
    }

    state = {};
    initCompleted = false;
    editor = null;

    componentDidMount() {
        loadJS(`${APP_STATIC_SRV_HOST}/lib/tinymce/tinymce.min.js`).then(this.initTinyMce.bind(this));
        // window.tinymce = require(`../../lib/tinymce.min.js`);
        // this.initTinyMce.bind(this);
    }

    componentWillUnmount() {
        let activeEditor = getAttr(window, 'tinymce.activeEditor');
        if (activeEditor) {
            if (this.initCompleted) {
                activeEditor.destroy();
            } else {
                // 可能存在plugin和 theme未加载的情况
                window.tinymce.activeEditor = null;
            }
        }
        // activeEditor && activeEditor.destroy();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.editor) {
            if (prevProps.value !== this.props.value) {
                this.editor.setContent(this.props.value || '');
            }
        }
    }

    initTinyMce() {
        const { defaultValue, config, onInput = emptyFn, onChange = emptyFn, customizeToolbars } = this.props;
        const { id } = this.state;
        const that = this;
        const settings = extend({}, defaultConfig, config, {
            selector: `#${id}`,
            setup: (editor) => {
                //自定义toolbar button
                if (isArray(customizeToolbars) && customizeToolbars.length > 0) {
                    each(customizeToolbars, (item) => {
                        editor.addButton(item.name, {
                            icon: item.icon,
                            tooltip: item.tooltip,
                            onclick: function () {
                                item.onclick(editor);
                            },
                        });
                    });
                }
                editor.on('init', () => {
                    this.initCompleted = true;
                    this.editor = editor;
                    if (defaultValue) {
                        editor.setContent(defaultValue);
                    }
                });

                editor.on('change', () => {
                    onChange(editor.getContent());
                    that.setState({
                        value: editor.getContent(),
                    });
                });

                editor.on('input', () => {
                    onInput(editor.getContent());
                });
            },
        });

        window.tinymce.init(settings);
        window.tinymce.addI18n('zh_CN', {
            Redo: locales.get('components-common-ueditor-lang-zh-cn-h79zr6n6ysg'),
            Undo: locales.get('components-common-ueditor-lang-zh-cn-ujm79ydqcdb'),
            Cut: locales.get('ui-editor-28sxsgt1qh6'),
            Copy: locales.get('components-notice-detail-channel-vp0ajp2trfj'),
            Paste: locales.get('components-common-ueditor-lang-zh-cn-iygr2dbvnk'),
            'Select all': locales.get('components-common-select-users-v8t68084fqr'),
            'New document': locales.get('ui-editor-pzj9pb8l5fd'),
            Ok: locales.get('components-common-integrated-table-op96u9kmem'),
            Cancel: locales.get('components-alarm-records-euc67z93qxl'),
            'Visual aids': locales.get('ui-editor-kjx2qy520ka'),
            Bold: locales.get('ui-editor-6xyyfvahmy'),
            Italic: locales.get('components-common-ueditor-lang-zh-cn-ryha88t60ed'),
            Underline: locales.get('components-common-ueditor-lang-zh-cn-kusy8bytwao'),
            Strikethrough: locales.get('components-common-ueditor-lang-zh-cn-gq1j3fkvfce'),
            Superscript: locales.get('components-common-ueditor-lang-zh-cn-1s36fkf8ora'),
            Subscript: locales.get('components-common-ueditor-lang-zh-cn-7x336k04gqn'),
            'Clear formatting': locales.get('components-common-ueditor-lang-zh-cn-3vb4c5hgulo'),
            'Align left': locales.get('ui-editor-4evroprl6ew'),
            'Align center': locales.get('ui-editor-th45sc1lj9g'),
            'Align right': locales.get('ui-editor-h023s15tl0v'),
            Justify: locales.get('components-common-ueditor-lang-zh-cn-dzld8dqb5p4'),
            'Bullet list': locales.get('ui-editor-xmqu3mr98yc'),
            'Numbered list': locales.get('ui-editor-pvqn0orwbcb'),
            'Decrease indent': locales.get('ui-editor-wbt3yovflpr'),
            'Increase indent': locales.get('ui-editor-yl8mxxtamz'),
            Close: locales.get('components-customercenter-care-publishmodal-rpx2zfhsb3g'),
            Formats: locales.get('ui-editor-wu3usf3ktxp'),
            "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.": locales.get(
                'ui-editor-ncb0ii9oa4'
            ),
            Headers: locales.get('components-common-ueditor-lang-zh-cn-2zfptngg5c6'),
            'Header 1': locales.get('ui-editor-obz3dvhb45'),
            'Header 2': locales.get('ui-editor-vjbo67venbr'),
            'Header 3': locales.get('ui-editor-o8feibavwy'),
            'Header 4': locales.get('ui-editor-aou6fii1jc6'),
            'Header 5': locales.get('ui-editor-3m4cb1oyxuy'),
            'Header 6': locales.get('ui-editor-1u139m1xtlh'),
            Headings: locales.get('components-common-ueditor-lang-zh-cn-2zfptngg5c6'),
            'Heading 1': locales.get('ui-editor-obz3dvhb45'),
            'Heading 2': locales.get('ui-editor-vjbo67venbr'),
            'Heading 3': locales.get('ui-editor-o8feibavwy'),
            'Heading 4': locales.get('ui-editor-aou6fii1jc6'),
            'Heading 5': locales.get('ui-editor-3m4cb1oyxuy'),
            'Heading 6': locales.get('ui-editor-1u139m1xtlh'),
            Preformatted: locales.get('ui-editor-qaq7i3p0tx'),
            Div: 'Div',
            Pre: 'Pre',
            Code: locales.get('ui-editor-9a02d9rubms'),
            Paragraph: locales.get('components-common-ueditor-lang-zh-cn-o21bbm22e3l'),
            Blockquote: locales.get('ui-editor-s54vnzbgp2'),
            Inline: locales.get('components-interactive-edit-content-properties-components-sjdvym915sg'),
            Blocks: locales.get('ui-editor-5n292dyaavw'),
            'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.': locales.get(
                'ui-editor-2m34lmspcpa'
            ),
            Fonts: locales.get('components-common-ueditor-lang-zh-cn-wky7o0wpyig'),
            'Font Sizes': locales.get('components-common-ueditor-lang-zh-cn-1dapi5qcb6l'),
            Class: locales.get('components-common-react-echarts-demo-bvus424sz0j'),
            'Browse for an image': locales.get('ui-editor-ht6pmvnzy0q'),
            OR: locales.get('components-alarm-filter-container-8mbgddj8tph'),
            'Drop an image here': locales.get('ui-editor-5eabij0bz6'),
            Upload: locales.get('components-common-ueditor-lang-zh-cn-mwuv3b4g07p'),
            Block: locales.get('ui-editor-whcsuxt1kn'),
            Align: locales.get('ui-editor-0gfhkh2jmcqo'),
            Default: locales.get('components-common-ueditor-lang-zh-cn-bxleanyzqq5'),
            Circle: locales.get('ui-editor-sx06um47m1'),
            Disc: locales.get('ui-editor-bpvyxog7ok'),
            Square: locales.get('ui-editor-t2a3uaosini'),
            'Lower Alpha': locales.get('ui-editor-b8l0jymobvv'),
            'Lower Greek': locales.get('ui-editor-4j9m116yhav'),
            'Lower Roman': locales.get('ui-editor-o0922r07hza'),
            'Upper Alpha': locales.get('ui-editor-644aq77h05y'),
            'Upper Roman': locales.get('ui-editor-mdl585zdnk8'),
            'Anchor...': locales.get('ui-editor-fuyb6ej9474'),
            Name: locales.get('components-interactive-marketing-list-8x373c807k'),
            Id: locales.get('ui-editor-ivbq19fwxcp'),
            'Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.': locales.get(
                'ui-editor-54dueeunxv'
            ),
            'You have unsaved changes are you sure you want to navigate away?': locales.get('ui-editor-y6yh05xomoq'),
            'Restore last draft': locales.get('ui-editor-uxqmsytm0jd'),
            'Special characters...': locales.get('ui-editor-u8qa09gntno'),
            'Source code': locales.get('components-common-ueditor-lang-zh-cn-tery8xtj7fa'),
            'Insert/Edit code sample': locales.get('ui-editor-uknpnqpqjd9'),
            Language: locales.get('ui-editor-40wwlo5vwzz'),
            'Code sample...': locales.get('ui-editor-av4fejr3f7r'),
            'Color Picker': locales.get('ui-editor-e9xufaudrzl'),
            R: 'R',
            G: 'G',
            B: 'B',
            'Left to right': locales.get('ui-editor-2dap32oihj7'),
            'Right to left': locales.get('ui-editor-1gt94ffpqao'),
            'Emoticons...': locales.get('ui-editor-guswii6lm15'),
            'Metadata and Document Properties': locales.get('ui-editor-jqcyzc79x8h'),
            Title: locales.get('components-common-ueditor-lang-zh-cn-2zfptngg5c6'),
            Keywords: locales.get('components-common-react-echarts-demo-c2kf2d6rktj'),
            Description: locales.get('components-common-react-echarts-demo-ndp58jjkuq'),
            Robots: locales.get('ui-editor-gfzhsathmwi'),
            Author: locales.get('ui-editor-rfc1ponew79'),
            Encoding: locales.get('ui-editor-mpol9j76a0o'),
            Fullscreen: locales.get('components-common-ueditor-lang-zh-cn-bem2f0mgxq'),
            Action: locales.get('components-alarm-records-ehfy8jxz42f'),
            Shortcut: locales.get('components-common-ueditor-lang-zh-cn-4lx5r27dlax'),
            Help: locales.get('components-common-ueditor-lang-zh-cn-n726ki34aqh'),
            Address: locales.get('components-common-ueditor-lang-zh-cn-09lqmn1hjsej'),
            'Focus to menubar': locales.get('ui-editor-8iaz5zo9qco'),
            'Focus to toolbar': locales.get('ui-editor-o2yhvlzlxb'),
            'Focus to element path': locales.get('ui-editor-tuoqkef13fs'),
            'Focus to contextual toolbar': locales.get('ui-editor-euex7sfzo'),
            'Insert link (if link plugin activated)': locales.get('ui-editor-76p6vbv2r4o'),
            'Save (if save plugin activated)': locales.get('ui-editor-9f67eq2tj9'),
            'Find (if searchreplace plugin activated)': locales.get('ui-editor-u08jyvatqaq'),
            'Plugins installed ({0}):': locales.get('ui-editor-jrznxyksltq'),
            'Premium plugins:': locales.get('ui-editor-r4hu6cyamyn'),
            'Learn more...': locales.get('ui-editor-boum1ldqz9'),
            'You are using {0}': locales.get('ui-editor-brqimyumwdw'),
            Plugins: locales.get('ui-editor-2im7dfh04z2'),
            'Handy Shortcuts': locales.get('components-common-ueditor-lang-zh-cn-4lx5r27dlax'),
            'Horizontal line': locales.get('ui-editor-jh9qx6gj57'),
            'Insert/edit image': locales.get('ui-editor-id26wprl5sp'),
            'Image description': locales.get('ui-editor-i7fhyjfeyf9'),
            Source: locales.get('components-common-ueditor-lang-zh-cn-09lqmn1hjsej'),
            Dimensions: locales.get('models-gb01mqpyu5j'),
            'Constrain proportions': locales.get('ui-editor-jf8azldo5tl'),
            General: locales.get('ui-editor-0sf33cad3xs'),
            Advanced: locales.get('ui-editor-jyg4cjci3ue'),
            Style: locales.get('ui-editor-etjeptj8p6'),
            'Vertical space': locales.get('ui-editor-4a3hlihtv25'),
            'Horizontal space': locales.get('ui-editor-xajd6f9vkof'),
            Border: locales.get('ui-editor-lgw4t6k0wr'),
            'Insert image': locales.get('components-common-ueditor-14q6fltttoo'),
            'Image...': locales.get('ui-editor-pp9bilk97c'),
            'Image list': locales.get('models-aqtlqbrxem'),
            'Rotate counterclockwise': locales.get('ui-editor-8hq2440wxtk'),
            'Rotate clockwise': locales.get('ui-editor-x1vmknk821'),
            'Flip vertically': locales.get('ui-editor-m45o5gdvq1e'),
            'Flip horizontally': locales.get('ui-editor-swnr6qdgdn'),
            'Edit image': locales.get('ui-editor-bpza4xxtz1b'),
            'Image options': locales.get('ui-editor-sjfq32tr3o'),
            'Zoom in': locales.get('ui-editor-f8wvc86bdhp'),
            'Zoom out': locales.get('ui-editor-ij1xh37x07'),
            Crop: locales.get('ui-editor-krq6vdyyb9'),
            Resize: locales.get('ui-editor-mbrdc5j4bj'),
            Orientation: locales.get('ui-editor-2nnxtt0zxzo'),
            Brightness: locales.get('ui-editor-tmi4ik4e1q'),
            Sharpen: locales.get('ui-editor-fsqpwqsfyz8'),
            Contrast: locales.get('ui-editor-tafv9dlb95k'),
            'Color levels': locales.get('ui-editor-a2he8sryz3k'),
            Gamma: locales.get('ui-editor-x0k1mp6h6'),
            Invert: locales.get('ui-editor-fyn4s6ikkwl'),
            Apply: locales.get('ui-editor-pe9onthmil'),
            Back: locales.get('ui-editor-xly0m600md'),
            'Insert date/time': locales.get('ui-editor-ixm8hf7eqi'),
            'Date/time': locales.get('ui-editor-r03fw7ppxli'),
            'Insert/Edit Link': locales.get('ui-editor-wqf8aspaso'),
            'Insert/edit link': locales.get('ui-editor-wqf8aspaso'),
            'Text to display': locales.get('components-intelligence-target-content-by2sbz5opf'),
            Url: locales.get('components-common-ueditor-lang-zh-cn-09lqmn1hjsej'),
            'Open link in...': locales.get('ui-editor-xkx2zhmup0n'),
            'Current window': locales.get('ui-editor-vexf3ht3jjl'),
            None: locales.get('components-common-react-echarts-demo-dgv5rhc8ul8'),
            'New window': locales.get('ui-editor-wewbjy4mroo'),
            'Remove link': locales.get('ui-editor-wl1ejaxxfd'),
            Anchors: locales.get('components-common-ueditor-lang-zh-cn-6kshrh0za1h'),
            'Link...': locales.get('ui-editor-s3stiogpbgj'),
            'Paste or type a link': locales.get('ui-editor-6iejhm046d'),
            'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?': locales.get(
                'ui-editor-2fdxwh0exgc'
            ),
            'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?': locales.get(
                'ui-editor-y2l5j3edxec'
            ),
            'Link list': locales.get('ui-editor-pxa87kr2iyq'),
            'Insert video': locales.get('components-common-ueditor-lang-zh-cn-zzv4nl2har'),
            'Insert/edit video': locales.get('ui-editor-i17sv7dk86c'),
            'Insert/edit media': locales.get('ui-editor-yj4zykg085'),
            'Alternative source': locales.get('ui-editor-bslj9obblxn'),
            'Alternative source URL': locales.get('ui-editor-41s6c9ghodn'),
            'Media poster (Image URL)': locales.get('ui-editor-kgvyo602dkn'),
            'Paste your embed code below:': locales.get('ui-editor-euo1j8v9v5e'),
            Embed: locales.get('ui-editor-z1hrl0mq5g'),
            'Media...': locales.get('ui-editor-qxnl4ygo9p'),
            'Nonbreaking space': locales.get('ui-editor-uggo464pe1f'),
            'Page break': locales.get('ui-editor-th4tuzprj9e'),
            'Paste as text': locales.get('ui-editor-on9thldmj4c'),
            Preview: locales.get('components-common-ueditor-lang-zh-cn-n1j8ug0yvn'),
            'Print...': locales.get('ui-editor-j3l5uf73gan'),
            Save: locales.get('components-alarm-records-03ews9u1ytdt'),
            Find: locales.get('components-common-ueditor-lang-zh-cn-s0gc5t88ou7'),
            'Replace with': locales.get('ui-editor-dphp646fyvl'),
            Replace: locales.get('components-common-ueditor-lang-zh-cn-usrmmucxfyd'),
            'Replace all': locales.get('components-common-ueditor-lang-zh-cn-vsg03vguew'),
            Previous: locales.get('components-common-ueditor-lang-zh-cn-h1ack0s2d6d'),
            Next: locales.get('components-common-ueditor-lang-zh-cn-jlh4u3c0z4'),
            'Find and replace...': locales.get('ui-editor-bhlgkkfrnpd'),
            'Could not find the specified string.': locales.get('ui-editor-alxk4vds84d'),
            'Match case': locales.get('components-common-ueditor-lang-zh-cn-8zovekjwtq4'),
            'Find whole words only': locales.get('ui-editor-fqtnt9rsm59'),
            'Spell check': locales.get('ui-editor-lga2g1aisxq'),
            Ignore: locales.get('components-alarm-records-x2l95mylhgk'),
            'Ignore all': locales.get('ui-editor-vm91g5sbn8n'),
            Finish: locales.get('models-ztgmrkwic2a'),
            'Add to Dictionary': locales.get('ui-editor-czfwd5vrd4a'),
            'Insert table': locales.get('components-common-ueditor-lang-zh-cn-dpnrf5ah9mk'),
            'Table properties': locales.get('components-common-ueditor-lang-zh-cn-dzlv1ystvy'),
            'Delete table': locales.get('components-common-ueditor-lang-zh-cn-re0tu1lmn1s'),
            Cell: locales.get('ui-editor-eqxwr6z53co'),
            Row: locales.get('components-common-ueditor-lang-zh-cn-j3d45k0todn'),
            Column: locales.get('components-common-tableColumnFilterTransfer-o19wnb41fbn'),
            'Cell properties': locales.get('components-common-ueditor-lang-zh-cn-lt9s916w87'),
            'Merge cells': locales.get('components-common-ueditor-lang-zh-cn-6n8fxlbdq46'),
            'Split cell': locales.get('ui-editor-vjxjwvb6fvm'),
            'Insert row before': locales.get('ui-editor-7nj03px6naa'),
            'Insert row after': locales.get('ui-editor-xpqo2om6o58'),
            'Delete row': locales.get('components-common-ueditor-lang-zh-cn-10pd107f4iw'),
            'Row properties': locales.get('ui-editor-uq14yph9h7'),
            'Cut row': locales.get('ui-editor-ut7qpqxtrw7'),
            'Copy row': locales.get('ui-editor-pj58wozj6gr'),
            'Paste row before': locales.get('ui-editor-22fqo51pdh4'),
            'Paste row after': locales.get('ui-editor-2q1ko8bpcoo'),
            'Insert column before': locales.get('ui-editor-ov5p0icaex'),
            'Insert column after': locales.get('ui-editor-3zf2qg1m5kk'),
            'Delete column': locales.get('components-common-ueditor-lang-zh-cn-0x2g0zrrpoi'),
            Cols: locales.get('components-common-tableColumnFilterTransfer-o19wnb41fbn'),
            Rows: locales.get('components-common-ueditor-lang-zh-cn-j3d45k0todn'),
            Width: locales.get('ui-editor-hz59jtq2m4s'),
            Height: locales.get('models-1q9nqkllnyx'),
            'Cell spacing': locales.get('ui-editor-66nc4rfulp7'),
            'Cell padding': locales.get('ui-editor-ajlnt2r3ua'),
            'Show caption': locales.get('ui-editor-gkmcjh5hq2'),
            Left: locales.get('components-common-ueditor-lang-zh-cn-mgabky49o4r'),
            Center: locales.get('components-common-ueditor-lang-zh-cn-e179wc7mhuh'),
            Right: locales.get('components-common-ueditor-lang-zh-cn-05pfb3lzt8wu'),
            'Cell type': locales.get('ui-editor-x4zmjipuiv'),
            Scope: locales.get('components-common-react-echarts-demo-1fcif2n2be1'),
            Alignment: locales.get('components-common-ueditor-lang-zh-cn-bqxyhjzp1r7'),
            'H Align': locales.get('ui-editor-ok8p6xn3h7r'),
            'V Align': locales.get('ui-editor-bvo9f7ybvp4'),
            Top: locales.get('ui-editor-il7zao7nrp'),
            Middle: locales.get('ui-editor-parg6uzyeml'),
            Bottom: locales.get('ui-editor-fm5rh9j6vs'),
            'Header cell': locales.get('ui-editor-vmsp1fpbddc'),
            'Row group': locales.get('ui-editor-xl5lynf94ch'),
            'Column group': locales.get('ui-editor-c8d89crtn9'),
            'Row type': locales.get('ui-editor-mg7mqx7m3o8'),
            Header: locales.get('ui-editor-1h1c2ks9pba'),
            Body: locales.get('ui-editor-5elob0hnrcg'),
            Footer: locales.get('ui-editor-7sh9j4m6amb'),
            'Border color': locales.get('ui-editor-zbvoobuwox'),
            'Insert template...': locales.get('ui-editor-cvvrc8qjxon'),
            Templates: locales.get('components-common-ueditor-lang-zh-cn-rmqpd9lmj6b'),
            Template: locales.get('components-common-ueditor-lang-zh-cn-rmqpd9lmj6b'),
            'Text color': locales.get('components-customercenter-registe-hgox8rhe1qq'),
            'Background color': locales.get('components-common-ueditor-lang-zh-cn-5dolu7gar3'),
            'Custom...': locales.get('ui-editor-2v4ibrr24py'),
            'Custom color': locales.get('ui-editor-pi90h149fa'),
            'No color': locales.get('components-common-react-echarts-demo-dgv5rhc8ul8'),
            'Remove color': locales.get('ui-editor-r4zn0vknmpe'),
            'Table of Contents': locales.get('ui-editor-dvgrdq0me7'),
            'Show blocks': locales.get('ui-editor-rxbbpt7rycb'),
            'Show invisible characters': locales.get('ui-editor-tz07i1pvrir'),
            'Word count': locales.get('ui-editor-av4qvkmpabv'),
            'Words: {0}': locales.get('ui-editor-97vcdhi0vjt'),
            '{0} words': locales.get('ui-editor-fywuvaxxjso'),
            File: locales.get('ui-editor-aa5ho38whpv'),
            Edit: locales.get('components-alarm-records-qglhwr0awhp'),
            Insert: locales.get('ui-editor-w6xn0nq1a0g'),
            View: locales.get('ui-editor-u8slq5m5zx'),
            Format: locales.get('ui-editor-wu3usf3ktxp'),
            Table: locales.get('components-common-react-echarts-2wtk7u12ho9'),
            Tools: locales.get('ui-editor-64kf57vzceh'),
            'Powered by {0}': locales.get('ui-editor-fumb7bh4q2p'),
            'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help': locales.get(
                'ui-editor-40ttuq57eux'
            ),
            'Image title': locales.get('ui-editor-fugyinh4alo'),
            'Border width': locales.get('ui-editor-2ucwvcsvkpk'),
            'Border style': locales.get('ui-editor-69lp2x9rmx5'),
            Error: locales.get('ui-editor-cbh3oxszr0l'),
            Warn: locales.get('ui-editor-rt88h2htx7'),
            Valid: locales.get('ui-editor-8greewip84c'),
            'To open the popup, press Shift+Enter': locales.get('ui-editor-px22nlxwfko'),
            'Rich Text Area. Press ALT-0 for help.': locales.get('ui-editor-va8x3czdcj8'),
            'System Font': locales.get('ui-editor-t2fw57a7qfs'),
            'Failed to upload image: {0}': locales.get('ui-editor-l5jkn71ky0r'),
            'Failed to load plugin: {0} from url {1}': locales.get('ui-editor-rt7ma8y60yd'),
            'Failed to load plugin url: {0}': locales.get('ui-editor-3r5ql91fmir'),
            'Failed to initialize plugin: {0}': locales.get('ui-editor-93i3b05vhna'),
            example: locales.get('components-common-ueditor-lang-zh-cn-462ncsm8d6k'),
            Search: locales.get('components-common-option-4im7n4yc03g'),
            All: locales.get('components-alarm-records-783q2askh8s'),
            Currency: locales.get('ui-editor-zch9kp9cwq'),
            Text: locales.get('ui-editor-uyiz3e1azw'),
            Quotations: locales.get('components-common-ueditor-lang-zh-cn-lqo1kgxghui'),
            Mathematical: locales.get('ui-editor-qpmeze4x21'),
            'Extended Latin': locales.get('ui-editor-b350cgj5jm'),
            Symbols: locales.get('ui-editor-cckwwm2i0cs'),
            Arrows: locales.get('ui-editor-zmwi8tnup6'),
            'User Defined': locales.get('components-common-ueditor-lang-zh-cn-g0iiiy52odr'),
            'dollar sign': locales.get('ui-editor-uoj6jniji7'),
            'currency sign': locales.get('ui-editor-1mt979a096o'),
            'euro-currency sign': locales.get('ui-editor-i9ojae2i4z'),
            'colon sign': locales.get('ui-editor-muvp731qpc'),
            'cruzeiro sign': locales.get('ui-editor-xivvogfox6m'),
            'french franc sign': locales.get('ui-editor-1ln3b37yc7x'),
            'lira sign': locales.get('ui-editor-wydgf34djy'),
            'mill sign': locales.get('ui-editor-g0unbyu2sbr'),
            'naira sign': locales.get('ui-editor-6mtmz6coqme'),
            'peseta sign': locales.get('ui-editor-l3bqg6frx7'),
            'rupee sign': locales.get('ui-editor-jkk4eiyo4eq'),
            'won sign': locales.get('ui-editor-qw3xk325so'),
            'new sheqel sign': locales.get('ui-editor-bzdfirjkaku'),
            'dong sign': locales.get('ui-editor-aquothyai0f'),
            'kip sign': locales.get('ui-editor-1xr9dbofnbwi'),
            'tugrik sign': locales.get('ui-editor-qeejfh0bby'),
            'drachma sign': locales.get('ui-editor-npu2ohrjt6k'),
            'german penny symbol': locales.get('ui-editor-yt6v18s36fc'),
            'peso sign': locales.get('ui-editor-4fug31xp86x'),
            'guarani sign': locales.get('ui-editor-lyh76cp19ro'),
            'austral sign': locales.get('ui-editor-osqrsjuxuz'),
            'hryvnia sign': locales.get('ui-editor-3ml6vs6jfbg'),
            'cedi sign': locales.get('ui-editor-vra0omrxnnf'),
            'livre tournois sign': locales.get('ui-editor-hrm0p1ewna5'),
            'spesmilo sign': locales.get('ui-editor-hlsx2ha7hzu'),
            'tenge sign': locales.get('ui-editor-zwmz325pbi'),
            'indian rupee sign': locales.get('ui-editor-xzwjxgjhd3'),
            'turkish lira sign': locales.get('ui-editor-9o0f1vta04m'),
            'nordic mark sign': locales.get('ui-editor-asp5czon4fk'),
            'manat sign': locales.get('ui-editor-5hptmu4p0ga'),
            'ruble sign': locales.get('ui-editor-9zbxqfny1ou'),
            'yen character': locales.get('ui-editor-0qcwibn04pvo'),
            'yuan character': locales.get('ui-editor-o4izxvv8yvg'),
            'yuan character, in hong kong and taiwan': locales.get('ui-editor-3oa1ufkhgpw'),
            'yen/yuan character variant one': locales.get('ui-editor-o8ax0ls909'),
            'Loading emoticons...': locales.get('ui-editor-3i7gl21c24c'),
            'Could not load emoticons': locales.get('ui-editor-pgsyo9t3lb'),
            People: locales.get('ui-editor-62xm4rxlfha'),
            'Animals and Nature': locales.get('ui-editor-jwcyj5le4s'),
            'Food and Drink': locales.get('ui-editor-i8x57pav3gl'),
            Activity: locales.get('ui-editor-j2gkp06qsp'),
            'Travel and Places': locales.get('ui-editor-cfdde53l3zt'),
            Objects: locales.get('ui-editor-zrd04ks20if'),
            Flags: locales.get('ui-editor-19z444t3fr4'),
            Characters: locales.get('ui-editor-rgj0yvzwyvo'),
            'Characters (no spaces)': locales.get('ui-editor-mneqov4bpxr'),
            'Error: Form submit field collision.': locales.get('ui-editor-qi677n6zxb'),
            'Error: No form element found.': locales.get('ui-editor-b08g4ndl09g'),
            Update: locales.get('ui-editor-vbhibj7k1zl'),
            'Color swatch': locales.get('ui-editor-ttisjo8ym6'),
            Turquoise: locales.get('ui-editor-0ft8q6z8a5q'),
            Green: locales.get('ui-editor-xpn89rdh5d'),
            Blue: locales.get('ui-editor-x1z4f6l1kp'),
            Purple: locales.get('ui-editor-mgw8wscr9ee'),
            'Navy Blue': locales.get('ui-editor-d7s1do3eaxd'),
            'Dark Turquoise': locales.get('ui-editor-83hayrfqna7'),
            'Dark Green': locales.get('ui-editor-ckaxn0l4jsb'),
            'Medium Blue': locales.get('ui-editor-0gv24lv61xes'),
            'Medium Purple': locales.get('ui-editor-6gdccxjeivx'),
            'Midnight Blue': locales.get('ui-editor-om8d4ndbeid'),
            Yellow: locales.get('ui-editor-6z018cl4ldi'),
            Orange: locales.get('ui-editor-dccykvjwp4w'),
            Red: locales.get('ui-editor-68u81z7gc2p'),
            'Light Gray': locales.get('ui-editor-sf29u9rbkts'),
            Gray: locales.get('ui-editor-wtifpmh81jl'),
            'Dark Yellow': locales.get('ui-editor-md40rbfpvn'),
            'Dark Orange': locales.get('ui-editor-qbkkoun65sn'),
            'Dark Red': locales.get('ui-editor-sa5srfs6cvq'),
            'Medium Gray': locales.get('ui-editor-ycegt1chky9'),
            'Dark Gray': locales.get('ui-editor-j5mzjfgrg6q'),
            Black: locales.get('ui-editor-lyq0tucsu4s'),
            White: locales.get('ui-editor-g986br9b5hr'),
            'Switch to or from fullscreen mode': locales.get('ui-editor-grpfc07q47d'),
            'Open help dialog': locales.get('ui-editor-5o647iw23fj'),
            history: locales.get('ui-editor-fjgtbuap5et'),
            styles: locales.get('ui-editor-etjeptj8p6'),
            formatting: locales.get('ui-editor-q9senffprvo'),
            alignment: locales.get('ui-editor-0gfhkh2jmcqo'),
            indentation: locales.get('ui-editor-45f81knhs6d'),
            'permanent pen': locales.get('ui-editor-z9pvvzaih8'),
            comments: locales.get('components-common-react-echarts-demo-ux7je0a8ayf'),
            Anchor: locales.get('components-common-ueditor-lang-zh-cn-6kshrh0za1h'),
            'Special character': locales.get('ui-editor-1evjwwjls7z'),
            'Code sample': locales.get('ui-editor-nj71d7r1xwf'),
            Color: locales.get('ui-editor-0o3lyj932by'),
            Emoticons: locales.get('components-common-ueditor-lang-zh-cn-b463pr6lzc'),
            'Document properties': locales.get('ui-editor-tmtndipntab'),
            Image: locales.get('components-common-ueditor-lang-zh-cn-kz0xsb8muo'),
            'Insert link': locales.get('ui-editor-s6n8onq49rp'),
            Target: locales.get('ui-editor-vpqgat7agcc'),
            Link: locales.get('components-common-ueditor-lang-zh-cn-ahauv1vtv6k'),
            Poster: locales.get('components-interactive-edit-article-imhwy0edihf'),
            Media: locales.get('ui-editor-8m9n4c115gi'),
            Print: locales.get('components-common-ueditor-lang-zh-cn-pu21v5d9rdj'),
            Prev: locales.get('components-common-ueditor-lang-zh-cn-h1ack0s2d6d'),
            'Find and replace': locales.get('ui-editor-anznnngl4n'),
            'Whole words': locales.get('ui-editor-fqtnt9rsm59'),
            Spellcheck: locales.get('ui-editor-lga2g1aisxq'),
            Caption: locales.get('components-common-ueditor-lang-zh-cn-2zfptngg5c6'),
            'Insert template': locales.get('ui-editor-plc0hzis4h'),
        });
    }

    render() {
        return <Input.TextArea id={this.state.id} />;
    }
}
