import React, { useState, useImperativeHandle } from 'react';
import { IImageClipperProps, IClipperItem } from './types';
import { Divider, Radio } from 'udesk-ui';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './index.scss';

const defaultClipperItem: IClipperItem = {
    key: 'custom',
    title: '自定义',
    initialAspectRatio: 1,
    description: '自定义裁剪预览',
};

const defaultStyle = { width: '100%' };

const ImageClipper = React.forwardRef((props: IImageClipperProps, ref) => {
    const {
        clipperTitle = '裁剪区域',
        previewTitle = '预览区域',
        dragMode = 'move',
        guides = false,
        clipperStyle = {},
        viewMode = 1,
        zoomable = true,
        rotatable = true,
        responsive = true,
        checkCrossOrigin = true,
        clipperItems,
        imageSrc,
        maxImageHeight = 500,
        crop,
        cropend,
    } = props;
    const cropRefs: Record<string, any> = React.useRef({});
    const thisRef = React.useRef<HTMLDivElement>(null);

    let standardClipperItems: IClipperItem[] = [];

    if (clipperItems) {
        standardClipperItems = standardClipperItems.concat(clipperItems);
    } else {
        standardClipperItems = [defaultClipperItem];
    }

    const [currentClipperItem, setCurrentClipperItem] = useState(standardClipperItems[0]);

    const onAspectRatioChange = (e: any) => {
        setCurrentClipperItem(e.target.value);
    };

    const onReady = (e: any, item: IClipperItem) => {
        if (maxImageHeight && !clipperStyle.height) {
            for (let key of Object.keys(cropRefs.current)) {
                cropRefs.current[key].img.parentElement.style.height = maxImageHeight + 'px';
            }
            window.dispatchEvent(new Event('resize'));
        }
        onCropend(e, item);
    };

    const onCropend = (e: any, item: IClipperItem) => {
        if (cropend) {
            cropend(e, item, e.currentTarget.cropper.getCroppedCanvas().toDataURL());
        }
    };

    const onCrop = (e: any, item: IClipperItem) => {
        if (crop) {
            crop(e, item);
        }
    };

    useImperativeHandle(ref, () => ({
        getClipperImage: () => {
            return standardClipperItems.map((item) => {
                return {
                    key: item.key,
                    image: cropRefs.current[item.key]
                        .getCroppedCanvas()
                        .toDataURL(item.mimeType || 'image/jpeg', item.quality || 0.6),
                };
            });
        },
    }));

    return (
        <div className="image-clipper" ref={thisRef}>
            <div className="clipper-zone">
                <h3>{clipperTitle}</h3>
                <div className="content">
                    {standardClipperItems.map((item) => (
                        <div
                            key={item.key}
                            className="clipper"
                            // 要在隐藏时还能获取到clipper的高度
                            style={{
                                visibility: currentClipperItem.key === item.key ? 'visible' : 'hidden',
                                position: currentClipperItem.key === item.key ? 'relative' : 'absolute',
                            }}
                        >
                            <Cropper
                                ref={(el) => {
                                    cropRefs.current[item.key] = el;
                                }}
                                src={imageSrc}
                                style={Object.assign({}, defaultStyle, clipperStyle)}
                                initialAspectRatio={item.initialAspectRatio}
                                aspectRatio={item.aspectRatio}
                                guides={guides}
                                viewMode={viewMode}
                                zoomable={zoomable}
                                rotatable={rotatable}
                                checkCrossOrigin={checkCrossOrigin}
                                preview={`.img-preview-${item.key}`}
                                dragMode={dragMode}
                                crop={(e) => onCrop(e, item)}
                                responsive={responsive}
                                restore={false}
                                cropend={(e) => onCropend(e, item)}
                                ready={(e) => onReady(e, item)}
                            />
                        </div>
                    ))}
                </div>
                <div className="aspect-ratio-bar">
                    <Radio.Group onChange={onAspectRatioChange} defaultValue={currentClipperItem}>
                        {standardClipperItems.length > 1 &&
                            standardClipperItems.map((item) => (
                                <Radio.Button key={item.key} value={item}>
                                    {item.aspectRatioTitle}
                                </Radio.Button>
                            ))}
                    </Radio.Group>
                </div>
            </div>
            <Divider type="vertical" className="divider" />
            <div className="preview-zone">
                <h3>{previewTitle}</h3>
                <div className="content">
                    {standardClipperItems.map((item) => (
                        <div key={item.key} className="preview-clipper-item">
                            <div className="clipper-item-content">
                                <span className="title">{item.title}:</span>
                                <span className="description">{item.description}</span>
                            </div>
                            <div
                                className={`img-preview-${item.key}`}
                                style={{
                                    // width: item.previewBox && item.previewBox.width ? item.previewBox.width : 200,
                                    width: item.previewBox
                                        ? item.previewBox.width
                                            ? item.previewBox.width
                                            : item.previewBox.height
                                            ? item.previewBox.height * (item.aspectRatio || 1)
                                            : 200
                                        : 200,
                                    height: item.previewBox
                                        ? item.previewBox.height
                                            ? item.previewBox.height
                                            : item.previewBox.width
                                            ? item.previewBox.width / (item.aspectRatio || 1)
                                            : 200
                                        : `${200 / (item.aspectRatio || 1)}`,
                                    // height:
                                    //     item.previewBox && item.previewBox.height
                                    //         ? item.previewBox.height
                                    //         : 200 / (item.aspectRatio || 1)
                                    // minWidth: '200px',
                                    // minHeight: `${200 / (item.aspectRatio || 1)}px`
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default ImageClipper;
