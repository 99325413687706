import locales from 'locales';
import React from 'react';
import { DatePicker as AntdDatePicker } from 'udesk-ui';
import { moment, SHORT_DATE_FORMAT, emptyFn, LONG_TIME_FORMAT } from '../utils';

import extend from 'lodash/extend';

const { MonthPicker, WeekPicker } = AntdDatePicker;

export class DatePicker extends React.Component {
    render() {
        let newProps = extend({}, this.props, {
            style: extend(
                {
                    width: '100%',
                },
                this.props.style
            ),
        });
        if (newProps.defaultValue) {
            newProps.defaultValue = moment(this.props.defaultValue);
        }
        return <AntdDatePicker {...newProps} />;
    }
}

export function RangePicker(props) {
    const { showTime, onChange = emptyFn } = props;

    props = extend(
        {
            format: SHORT_DATE_FORMAT,
            ranges: {
                [locales.get('common.ui.date.today')]: [moment().startOf('day'), moment().endOf('day')],
                [locales.get('common.ui.date.yesterday')]: [
                    moment().subtract(1, 'days').startOf('day'),
                    moment().subtract(1, 'days').endOf('day'),
                ],
                [locales.get('common.ui.date.in-recent-7-days')]: [
                    moment().subtract(6, 'days').startOf('day'),
                    moment().endOf('day'),
                ],
                [locales.get('common.ui.date.in-the-last-30-days')]: [
                    moment().subtract(29, 'days').startOf('day'),
                    moment().endOf('day'),
                ],
                [locales.get('common.ui.date.this-month')]: [
                    moment().startOf('month').startOf('day'),
                    moment().endOf('month').endOf('day'),
                ],
                [locales.get('common.ui.date.last-month')]: [
                    moment().subtract(1, 'month').startOf('month').startOf('day'),
                    moment().subtract(1, 'month').endOf('month').endOf('day'),
                ],
                [locales.get('common.ui.date.this-year')]: [
                    moment().startOf('year').startOf('day'),
                    moment().endOf('year').endOf('day'),
                ],
                [locales.get('common.ui.date.last-year')]: [
                    moment().subtract(1, 'year').startOf('year').startOf('day'),
                    moment().subtract(1, 'year').endOf('year').endOf('day'),
                ],
            },
            placeholder: [
                locales.get('components-interactive-rank-detail-dacm1nm6d3d'),
                locales.get('components-interactive-rank-detail-msjafa0b18'),
            ],
        },
        props,
        {
            onChange: (dates, ...params) => {
                // 第一次选择时间的时候获取的是当前时间,而不是00:00:00,应接口要求,所以需要一次强行转换
                if (dates && dates.length) {
                    let start = moment(dates[0]);
                    let end = moment(dates[1]);
                    dates = [start.startOf('day'), end.endOf('day')];
                }
                onChange(dates || [], ...params);
            },
        }
    );

    if (props.format === SHORT_DATE_FORMAT) {
        props.style = extend({ width: 180 }, props.style);
    }

    return <AntdDatePicker.RangePicker {...props} />;
}

DatePicker.RangePicker = RangePicker;
DatePicker.MonthPicker = MonthPicker;
DatePicker.WeekPicker = WeekPicker;
