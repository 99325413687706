/**
 * 项目中心筛选参数
 */
import { createReducer, createAction } from 'redux-act';
import Base from '../base';

const base = new Base();

export const objectReplace = createAction('objectReplace');

export default createReducer(
    {
        [objectReplace]: base.objectReplace.bind(base)
    },
    {}
);
