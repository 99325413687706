import isFunction from 'lodash/isFunction';

class historyRecorder {
    private queue: string[] = [window.location.pathname];
    private prevUrl: string | null = null;
    /**
     *
     */
    constructor(private max: number = 20) {
        this.initPageListener();
    }

    get allHistory() {
        return this.queue;
    }

    get previewUrl() {
        return this.prevUrl;
    }

    private initPageListener() {
        let historyPushState = window.history.pushState;
        let historyReplaceState = window.history.replaceState;

        const recordHistoryChange = (params) => {
            const [, , url] = params;
            this.prevUrl = this.queue[0];
            if (this.queue.length >= this.max) {
                this.queue.pop();
            }
            this.queue.unshift(url);
            window.console.log(this.prevUrl, url);
        };

        // 不适用箭头函数保留 arguments
        window.history.pushState = function () {
            historyPushState.apply(window.history, arguments);
            recordHistoryChange(arguments);
        };
        window.history.replaceState = function () {
            historyReplaceState.apply(window.history, arguments);
            recordHistoryChange(arguments);
        };

        let event = isFunction(historyPushState) ? 'popstate' : 'hashchange';
        window.addEventListener(event, (...args) => {
            recordHistoryChange(args);
        });
    }
}

export default new historyRecorder();
