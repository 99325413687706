import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { createFromIconfontCN } from '@ant-design/icons';
import { classnames, isHasOrTrue } from '../utils';

import extend from 'lodash/extend';
import omit from 'lodash/omit';
import includes from 'lodash/includes';

import { STATIC_PATH, APP_VERSION } from '../utils/consts';

const IconFont = createFromIconfontCN({
    scriptUrl: `${STATIC_PATH}/fonts/iconfont.js?${APP_VERSION}`,
});

const colourfulIconTypes = [
    'logo',
    'logo-sm',
    'good2',
    'neutral2',
    'notgood2',
    'notsatisfied2',
    'satisfied2',
    'bumanyi-hong',
    'zhongli1',
    'manyi-cai',
    'xitongduijie',
    'yemiancuowu',
    'bianzu31',
    'ic-star-color',
    'ic-ask-ban',
    'ic-gold',
    'ic-ask',
    'ic-add-circle',
    'ic-minus-circle',
    'satisfied1',
    'good1',
];

/**
 * 图标组建
 * @param {boolean} [props.antd] - 如果为true加载antd Icon，否则加载iconfont
 * @param {string} props.type - 图标名称, 去掉前缀icon-
 * @param {string} [props.color] - 图标颜色
 * @param {any} [props.link] - 是否显示为链接样式，只要设置则为是
 * @see https://ant.design/components/icon-cn/#API
 */
export function Icon(props) {
    const { type } = props;
    const isAntd = isHasOrTrue(props, 'antd');
    // const isLink = isHasOrTrue(props, 'link');
    const isColourful = includes(colourfulIconTypes, type);

    if (isAntd) {
        return <LegacyIcon {...omit(props, 'antd')} />;
    }

    if (isColourful) {
        return <IconFont {...props} type={`icon-${type}`} />;
    }

    props = omit(props, 'type', 'link');

    extend(props, {
        className: classnames(`icon icon-${type}`, props.className),
    });

    return (
        <i {...props} />
        // isLink ? <a {...props} /> :
    );
}

export default Icon;
