import locales from 'locales';

export function isObj(v) {
    return Object.prototype.toString.call(v) === '[object Object]';
}

export function getEchoAddress(address) {
    let detailAddress = '';
    let selectAddress = '';
    let isReturnNumberStr = false;

    if (address) {
        try {
            let addressObj = JSON.parse(address);
            if (isObj(addressObj)) {
                detailAddress = addressObj.detail || '';
                selectAddress = addressObj.selectAddress || '';
            } else {
                isReturnNumberStr = true;
            }
        } catch (err) {
            // 进入catch 说明 dataSource中的address字段 JSON.parse的值不是对象
            // 1.  是对象
            // 2.  是字符串但是里面的值不是数字
            if (isObj(address)) {
                detailAddress = address.detail || '';
                selectAddress = address.selectAddress || '';
            } else {
                selectAddress = '';
                detailAddress = address;
            }
        }
        return isReturnNumberStr ? address : selectAddress + detailAddress;
    } else {
        return '';
    }
}

export function getPercentNum(num) {
    if (!num) {
        return '0%';
    } else {
        return (num * 100).toFixed(2) + '%';
    }
}

/**
 * 数字超过1万缩写处理
 * @param {number} originNumber 初始数值
 * @param {string} unit 转化单位
 */
export function get10000Number(originNumber, unit) {
    if (!unit) {
        throw new Error('unit is Required');
    }
    if (originNumber < 10000) {
        return originNumber;
    } else {
        const unitConfig = new Map([
            ['10000', { dividend: 10000, name: locales.get('pages.intelligence.unit.tenThousand') }],
        ]);
        // dividend  被除数
        let unitStr = typeof unit === 'string' ? unit : unit.toString();
        let dividend = unitConfig.get(unitStr).dividend;
        let formatNumberWithFixed = (originNumber / dividend).toFixed(1);
        let unitName = unitConfig.get(unitStr).name;

        return formatNumberWithFixed + unitName;
    }
}

export function getDeWeightArr(arr) {
    if (arr && Array.isArray(arr)) {
        return [...new Set(arr)];
    } else {
        return [];
    }
}
