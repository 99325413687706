import locales from 'locales';
import React from 'react';
import { BaseComponent, componentInjection } from '../../../utils';

import extend from 'lodash/extend';
import { Form } from '@ant-design/compatible';
import '@Styles/_compatibleIndex.css';
import { Modal, Input } from 'udesk-ui';
const styles = {};
class Default extends BaseComponent {
    constructor(props) {
        super(props);
        extend(this.state, {
            data: extend(
                {
                    amount: '',
                    validDateType: 1,
                },
                props.data
            ),
        });
    }
    onHandleSubmit = (e) => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFields((errors, values) => {
            if (errors) {
                return;
            }

            let datas = values.ignoreText;
            if (this.props.onIgnoreOk) {
                this.props.onIgnoreOk(datas);
            }
        });
    };
    onClose = () => {
        if (this.props.onIgnoreClose) {
            this.props.onIgnoreClose(false);
        }
    };
    render() {
        const { visible, form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Modal
                width={460}
                height={280}
                visible={visible}
                title={locales.get('components-alarm-records-ifdocy9dg')}
                closable
                mask
                onOk={this.onHandleSubmit}
                onCancel={this.onClose}
            >
                <Form>
                    <Form.Item label={locales.get('components-alarm-records-rea89wfs3a')} required>
                        {getFieldDecorator('ignoreText', {
                            rules: [
                                { required: true, message: locales.get('components-alarm-records-60c1s04mlmr') },
                                { max: 100, message: locales.get('components-alarm-records-yhysa75fsw') },
                            ],
                        })(
                            <Input.TextArea
                                placeholder={locales.get('components-alarm-records-iotcjtozs1f')}
                                maxLength={150}
                            />
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
Default = Form.create()(Default);
export default componentInjection(Default, { styles });
