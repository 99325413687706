/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { BaseComponent, IMAGE_PATH, isPermission, getPermissionMenuItem, getJSON, imgOnLoad } from '../../utils';
import proxy from '@Utils/api';
import { Link, Icon } from '../../ui';
import { Layout, Menu } from 'udesk-ui'; //antd 4.0 upgrade
import { dispatch } from '../../store';

//该处不可引入models中导出，会导致国际化locales.get取不到值， 所用导入当页面写死  所用导入当页面写死 此处引入的值在对应的models中改变时，该页面也需要手动更改
// import { LIST_STATUS_KEY, projectTypes, FILTER_KEY } from '../../models/projects';
import withStyles from 'react-jss';
import extend from 'lodash/extend';
import some from 'lodash/some';
import filter from 'lodash/filter';

import allRoutes from '../../routes';

export let rootMenuList = [];

const { Sider } = Layout;
const logoStyle = {
    display: 'block',
    height: 25,
    margin: '16px 0 10px 21px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
};
const styles = {
    logo: extend({}, logoStyle, {
        backgroundImage: `url(${IMAGE_PATH}/logox4.png)`,
    }),
    logoSm: extend({}, logoStyle, {
        marginLeft: 0,
        height: 23,
        backgroundPosition: 'center',
        backgroundImage: `url(${IMAGE_PATH}/logo-sm-new.png)`,
        margin: '16px 0 12px 21px',
    }),
};

class Default extends BaseComponent {
    state = { openKeys: [] };

    constructor(props) {
        super(props);
        this.state = {
            rootMenuList: [],
            openKeys: [],
            settedLogo: '',
            isRequestLogoDown: false, // 请求logo的ajax执行完毕
        };
    }

    componentDidMount() {
        this.appContext.on('onMenuListChange', (menuList) => {
            this.setState({ rootMenuList: this.filterMenuList(menuList) });
        });
        this.getLogo();
    }

    getLogo = () => {
        proxy[`companySysConfig/get`]({
            type: 'get',
        })
            .then((res) => {
                this.setState({
                    isRequestLogoDown: true,
                });
                if (res.code === 200 && res.data) {
                    this.setState({
                        settedLogo: res.data.logo || '',
                    });
                }
            })
            .catch(() => {
                this.setState({
                    isRequestLogoDown: true,
                });
            });
    };

    filterMenuList = (menuList) => {
        rootMenuList = filter(allRoutes, (route) => !!route.permission).map((item) => {
            const hasPermissionRouteArray =
                item.routes && item.routes.length > 0 ? filter(item.routes, (route) => !!route.permission) : [];
            return this.mergePermissionItem(menuList, {
                ...item,
                icon: item.icon,
                key: item.path,
                permission: item.permission,
                subMenuPermission:
                    hasPermissionRouteArray.length > 0
                        ? some(hasPermissionRouteArray, (route) => isPermission(menuList, route.permission, 2))
                        : true,
                routes:
                    hasPermissionRouteArray.length > 0
                        ? hasPermissionRouteArray
                              .map((route) => this.mergePermissionItem(menuList, route, 2))
                              .filter((o) => !!o)
                        : [],
            });
        });
        return rootMenuList;
    };

    willDestroy() {
        this.appContext.off('onMenuListChange');
    }

    onOpenChange = (openKeys) => {
        const { rootMenuList } = this.state;
        const latestOpenKey = openKeys.find((key) => this.state.openKeys.indexOf(key) === -1);
        if (rootMenuList.findIndex((menuItem) => menuItem && latestOpenKey === menuItem.key) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    };

    mergePermissionItem(menuList, item, type = 1) {
        const permissionItem = getPermissionMenuItem(menuList, type, item.permission);

        if (permissionItem || item.force) {
            return extend({}, item, permissionItem);
        }
        return null;
    }

    closeAllSubmenu = () => {
        if (this.state.openKeys && this.state.openKeys.length > 0) this.setState({ openKeys: [] });
    };

    jumpTo = () => {
        getJSON(`/insight`).always((res) => {
            window.open(res.data);
        });
    };

    render() {
        const { rootMenuList, openKeys, settedLogo, isRequestLogoDown } = this.state;
        const { classes, collapsed } = this.props;
        const key = window.location.pathname.split('/')[1];

        const LinkProps = settedLogo
            ? collapsed
                ? { className: classes['logoSm'] }
                : { style: { ...logoStyle, backgroundImage: `url(${settedLogo})` } }
            : { className: classes[collapsed ? 'logoSm' : 'logo'] };

        return (
            <Sider width={180} collapsedWidth={64} collapsed={!!collapsed}>
                {isRequestLogoDown && (!settedLogo || (settedLogo && collapsed)) && <Link to="/" {...LinkProps} />}
                {isRequestLogoDown && settedLogo && !collapsed && (
                    <Link to="/" style={{ ...logoStyle }}>
                        <img
                            onLoad={() => {
                                imgOnLoad('menuLogoImg', 136);
                            }}
                            alt=""
                            id="menuLogoImg"
                            src={settedLogo}
                            style={{
                                maxWidth: 136,
                                maxHeight: 136,
                            }}
                        />
                    </Link>
                )}

                <Menu defaultSelectedKeys={[key]} mode="inline" openKeys={openKeys} onOpenChange={this.onOpenChange}>
                    {rootMenuList.map((item) => {
                        return (
                            item &&
                            item.subMenuPermission &&
                            (!item.showRoutes ? (
                                <Menu.Item key={item.key} onClick={this.closeAllSubmenu}>
                                    <Link
                                        className="root-menu-submenu-item"
                                        to={item.path}
                                        onClick={this.changeProjectStatus.bind(this, item)}
                                    >
                                        <Icon type={item.icon} />
                                        <span title={item.name}>{item.name}</span>
                                    </Link>
                                </Menu.Item>
                            ) : (
                                <Menu.SubMenu
                                    key={item.key}
                                    title={
                                        <span className="root-menu-submenu-item">
                                            <Icon type={item.icon} />
                                            <span title={item.name}>{item.name}</span>
                                        </span>
                                    }
                                >
                                    {item.routes.map((subMenu) => {
                                        return subMenu && subMenu.permission === 'menu:seniorInsight' ? (
                                            <Menu.Item>
                                                <a onClick={this.jumpTo} title={subMenu.name}>
                                                    <span>{subMenu.name}</span>
                                                </a>
                                            </Menu.Item>
                                        ) : (
                                            // <span onClick={this.jumpTo}></span>
                                            <Menu.Item key={subMenu.permission}>
                                                <Link title={subMenu.name} to={`${item.path}${subMenu.path}`}>
                                                    <span>{subMenu.name}</span>
                                                </Link>
                                            </Menu.Item>
                                        );
                                    })}
                                </Menu.SubMenu>
                            ))
                        );
                    })}
                </Menu>
            </Sider>
        );
    }
    changeProjectStatus(item) {
        if (item.key === 'project') {
            //该组件中不可引入models中的导出 当页面写死
            //filter对应 models/projects下的FILTER_KEY, listStatus对应 models/projects下的LIST_STATUS_KEY
            dispatch('listStatus', 'objectSet', { key: 'allowLoadWithParams', value: false });
            dispatch('filter', 'objectReplace', {});
        }
    }
}

export default withStyles(styles)(Default);
