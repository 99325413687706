import React from 'react';
import isString from 'lodash/isString';

function load(path) {
    return import(`@Component/${path}`);
}

function buildRoutes(routes, parentPath) {
    if (routes == null || !(routes instanceof Array)) {
        routes = [];
    }
    routes = routes.map((route) => {
        if (route.routes instanceof Array && route.routes.length > 0) {
            route.routes = buildRoutes(route.routes);
        }
        if (isString(route.component)) {
            route.url = route.component;
            route.component = React.lazy(() => load(route.url));
        }

        // asyncPage(route.name);
        return route;
    });
    return routes;
}

const routes = [
    {
        exact: true,
        path: '/',
        component: 'dashboard' || 'project',
    },
    {
        exact: true,
        path: '/dashboard',
        component: 'dashboard',
        permission: 'instrumentpanel',
        icon: 'yibiaopan',
    },
    {
        exact: true,
        path: '/dashboard/edit/:project_id',
        component: 'dashboard/edit',
    },
    {
        exact: true,
        path: '/templateCenter',
        component: 'templateCenter',
    },
    {
        exact: true,
        path: '/templateCenter/detail/:type/:id/:tabKey',
        component: 'templateCenter/detail',
    },

    {
        path: '/feedback',
        component: 'commonRootBox',
        permission: 'feedbackCenter',
        icon: 'project',
        showRoutes: true,
        routes: [
            {
                path: '/nps',
                exact: true,
                component: 'experience/nps',
                permission: 'experienceCenter',
            },
            { path: '/nps/list', exact: true, component: 'experience/nps/list' },
            { path: '/nps/questionList/:projectId', exact: true, component: 'experience/nps/questionList' },
            {
                path: '/csat',
                exact: true,
                component: 'experience/csat',
                permission: 'experienceCenterCsat',
            },
            { path: '/csat/list', exact: true, component: 'experience/csat/list' },
            { path: '/csat/questionList/:projectId', exact: true, component: 'experience/csat/questionList' },
            {
                exact: true,
                path: '/evaluate',
                component: 'evaluate',
                permission: 'experienceCenterComment',
            },
            {
                path: '/projectList',
                component: 'project',
                permission: 'project',
            },
            {
                exact: true,
                path: '/insight',
                component: 'insight',
                permission: 'insight',
            },
            {
                exact: true,
                path: '/insight/:project_id',
                component: 'insight',
            },
            {
                path: '/automation',
                component: 'automation',
                permission: 'automation',
                routes: [
                    { path: '/list', component: 'automation/list' },
                    {
                        path: '/detail/:id',
                        component: 'automation/detail',
                        routes: [
                            { path: '/basics', component: 'automation/detail/basics' },
                            { path: '/process', component: 'automation/detail/process' },
                            { path: '/insight', component: 'automation/detail/insight' },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/intelligence',
        component: 'intelligence',
        routes: [
            {
                path: '/nps/questionList/:projectId',
                exact: true,
                permission: 'seniorInsight',
                component: 'experience/nps/questionList',
            },
            { path: '/message', permission: 'intelligencecenter:leavemessage', component: 'intelligence/message' },
            { path: '/target', permission: 'quota', component: 'intelligence/target' },
            {
                path: '/indicators',
                component: 'intelligence/indicators',
                permission: 'quotaAnalyze',
            },
            { exact: true, path: '/loss', permission: 'wastage', component: 'intelligence/loss' },
            { path: '/loss/edit', component: 'intelligence/loss/edit' },
            { path: '/hotspots', permission: 'chatAnalyze', component: 'intelligence/hotspots' },
            // { path: '/word', permission: 'word', component: 'intelligence/word' },
            {
                path: '/import-analyze',
                permission: 'importAnalyze',
                component: 'intelligence/import-analyze',
            },
            {
                path: '/import-file-manage',
                component: 'intelligence/import-file-manage',
            },
        ],
        permission: 'intelligencecenter',
        icon: 'zhinengzhongxin',
        showRoutes: true,
    },
    {
        path: '/action',
        component: 'commonRootBox',
        permission: 'actionCenter',
        icon: 'task',
        showRoutes: true,
        routes: [
            {
                path: '/alarm',
                component: 'alarm',
                routes: [
                    { path: '/type', permission: 'earlywarningtype', component: 'alarm/type' },
                    { path: '/records', permission: 'earlywarningrecord', component: 'alarm/records' },
                ],
                permission: 'earlywarning',
            },
            {
                path: '/mission',
                component: 'mission',
                permission: 'mission',
            },
        ],
    },
    {
        path: '/interactive',
        component: 'commonRootBox',
        permission: 'interactive',
        icon: 'hudongzhongxin',
        showRoutes: true,
        routes: [
            {
                exact: true,
                path: '/customercare',
                component: 'customercenter/care',
                permission: 'customercare',
            },
            {
                exact: true,
                path: '/customercare/edit/:id',
                component: 'customercenter/care/edit',
            },
            {
                exact: true,
                path: '/interactivemanage',
                component: 'interactive/list',
                permission: 'interactivemanage',
            },
            {
                path: '/interactivemanage/detail/:id/:extensionName',
                component: 'interactive/list/detail',
            },
            {
                path: '/rank',
                component: 'interactive/rank',
                permission: 'interactiveRank',
                routes: [
                    {
                        path: '/person',
                        component: 'interactive/rank/person',
                        permission: 'interactiveRankPerson',
                    },
                    {
                        path: '/org',
                        component: 'interactive/rank/organization',
                        permission: 'interactiveRankOrg',
                    },
                    {
                        path: '/detail',
                        component: 'interactive/rank/detail',
                        permission: 'interactiveRankDetail',
                    },
                ],
            },
            {
                path: '/poster',
                exact: true,
                component: 'interactive/poster',
                permission: 'poster',
            },
            {
                path: '/poster/detail/:id',
                component: 'interactive/poster/detail',
            },
            {
                path: '/entry',
                exact: true,
                permission: 'signUpList',
                component: 'interactive/entry',
            },
            {
                path: '/entry/:extensionId',
                exact: true,
                component: 'interactive/entry',
            },
            {
                path: '/entry/:extensionId/:extensionName',
                exact: true,
                component: 'interactive/entry',
            },
            {
                path: '/entry/:extensionId/:extensionName/:destName',
                exact: true,
                component: 'interactive/entry',
            },
            {
                path: '/analysis',
                exact: true,
                permission: 'interactiveanalysis',
                component: 'interactive/analysis',
            },
            {
                path: '/analysis/:id',
                exact: true,
                component: 'interactive/analysis',
            },
            {
                path: '/analysis/:id/:spreadId',
                exact: true,
                component: 'interactive/analysis',
            },
            {
                exact: true,
                path: '/manualcall',
                permission: 'callRecord',
                component: 'interactive/manualcall',
            },
            {
                exact: true,
                path: '/call',
                permission: 'callmission',
                component: 'customercenter/smartcall',
            },
            {
                exact: true,
                path: '/call/edit/:id',
                component: 'customercenter/smartcall/edit',
            },
            {
                exact: true,
                path: '/call/detail/:id',
                component: 'customercenter/smartcall/detail',
            },
        ],
    },
    {
        path: '/customercenter',
        component: 'customercenter',
        permission: 'customercenter',
        icon: 'kehuzhongxin',
        showRoutes: true,
        routes: [
            {
                exact: true,
                path: '/group',
                permission: 'customergroup',
                component: 'customercenter/group',
            },
            {
                path: '/group/detail/:id',
                component: 'customercenter/group/detail',
            },
            {
                exact: true,
                path: '/list',
                permission: 'customerlist',
                component: 'customercenter/list',
            },
            {
                path: '/detail/:id',
                component: 'customercenter/detail',
            },
            {
                exact: true,
                path: '/level',
                permission: 'customergrade',
                component: 'customercenter/level',
            },
            {
                path: '/encourage',
                component: 'encourage',
                permission: 'encourage',
                icon: 'encourage',
                showRoutes: true,
                routes: [
                    { path: '/integrals', permission: 'rewardpoints', component: 'encourage/integrals' },
                    { path: '/coupons', permission: 'electronmanage', component: 'encourage/coupons' },
                    { path: '/records', permission: 'electronrecord', component: 'encourage/records' },
                ],
            },
            {
                exact: true,
                path: '/group/edit/:id',
                component: 'customercenter/group/edit',
            },
            {
                exact: true,
                path: '/group/edit',
                component: 'customercenter/group/edit',
            },
            {
                path: '/custom-merge/:idListStr',
                component: 'customercenter/customMerge',
            },
        ],
    },
    {
        path: '/wework',
        component: 'wework',
        permission: 'weWork',
        icon: 'siyuguanli',
        showRoutes: true,
        routes: [
            {
                path: '/externalUser',
                component: 'wework/externalUser',
                permission: 'weWorkExternalUser',
            },
            {
                path: '/user',
                component: 'wework/user',
                permission: 'weWorkUser',
            },
            {
                path: '/record',
                component: 'wework/chatRecord',
                permission: 'weWorkChat',
                routes: [
                    {
                        path: '/chat',
                        permission: 'weWorkChatList',
                        component: 'wework/chat',
                    },
                    {
                        path: '/group',
                        permission: 'weWorkGroupChatList',
                        component: 'wework/groupChat',
                    },
                ],
            },
            {
                path: '/statistics',
                component: 'wework/statistics',
                permission: 'weWorkStatistics',
                routes: [
                    { path: '/friend', component: 'wework/statistics/friend' },
                    { path: '/chat', component: 'wework/statistics/chat' },
                ],
            },
            {
                path: '/groupSend',
                permission: 'weWorkGroupSending',
                component: 'wework/groupSend',
                exact: true,
            },
            {
                path: '/groupSend/edit/:id',
                component: 'wework/groupSend/edit',
            },
            {
                path: '/groupSend/detail/:id',
                component: 'wework/groupSend/detail',
            },
            {
                path: '/groupRobot/list',
                component: 'wework/groupRobot/list',
                permission: 'weWorkRobot',
            },
            {
                path: '/groupRobot/edit/:id',
                component: 'wework/groupRobot/edit',
            },
        ],
    },
    {
        path: '/journeys/customer',
        component: 'journeys/customer',
        permission: 'customerjourney',
        icon: 'kehulvcheng',
    },
    {
        path: '/journeys/employee',
        component: 'journeys/employee',
        permission: 'employeejourney',
        icon: 'yuangonglvcheng',
    },
    {
        path: '/journeys/product',
        component: 'journeys/product',
        permission: 'productjourney',
        icon: 'kehulvcheng1',
    },
    {
        exact: true,
        path: '/project/records/:project_id',
        component: 'project/records',
    },
    {
        path: '/project/detail/:id',
        component: 'project/detail',
        routes: [
            { exact: true, path: '/', component: 'project/detail/basics' },
            { path: '/encourage', component: 'project/detail/encourage' },
            { path: '/preview/:header', component: 'project/detail/preview' },
            { path: '/publish', component: 'project/detail/publish' },
            { path: '/replay', component: 'project/detail/replay' },
            { path: '/appearance', component: 'project/detail/appearance' },
            { path: '/editable', component: 'project/detail/editable' },
        ],
    },
    {
        path: '/project/investigate/detail/:id',
        component: 'experience/investigate/detail',
        routes: [
            { path: '/basics', component: 'experience/investigate/detail/basics' },
            { path: '/encourage', component: 'experience/investigate/detail/encourage' },
            {
                path: '/publish',
                component: 'experience/investigate/detail/publish',
                routes: [
                    { path: '/publishport', component: 'experience/investigate/detail/publish/publishport' },
                    { path: '/speechrobot/:id', component: 'experience/investigate/detail/publish/robotConfig' },
                ],
            },
        ],
    },
    {
        path: '/scorelist',
        component: 'scorelist',
        routes: [
            { path: '/organizations', permission: 'customerscorelist', component: 'scorelist/organizations' },
            { path: '/products', permission: 'productscorelist', component: 'scorelist/products' },
            { path: '/users', permission: 'normaluserscorelist', component: 'scorelist/users' },
            { path: '/orgs', permission: 'organizationscorelist', component: 'scorelist/orgs' },
        ],
        permission: 'scorelist',
        icon: 'score-list',
        showRoutes: true,
    },
    {
        path: '/material',
        component: 'material',
        permission: 'material',
        icon: 'sucaizhongxin',
        showRoutes: true,
        routes: [
            {
                path: '/investigate',
                permission: 'researchLibrary',
                component: 'material/investigate',
                routes: [
                    {
                        path: '/list',
                        component: 'material/investigate/list',
                    },
                    {
                        path: '/detail/:id',
                        component: 'material/investigate/detail',
                        routes: [
                            {
                                path: '/editable',
                                component: 'material/investigate/detail/editable',
                            },
                            { path: '/replay', component: 'material/investigate/detail/replay' },
                            { path: '/appearance', component: 'material/investigate/detail/appearance' },
                        ],
                    },
                ],
            },
            // 素材中心-活动库列表
            {
                path: '/extensionLibrary',
                component: 'material/actionLibrary/list',
                permission: 'extensionLibrary',
            },
            {
                path: '/multimedia',
                component: 'material/multimedia',
                permission: 'resource',
                routes: [
                    {
                        path: '/picture',
                        component: 'material/multimedia/picture',
                    },
                    {
                        exact: true,
                        path: '/article',
                        component: 'material/multimedia/article',
                    },
                    {
                        exact: true,
                        path: '/article/edit/:id',
                        component: 'material/multimedia/articleedit',
                    },

                    {
                        exact: true,
                        path: '/video',
                        component: 'material/multimedia/video',
                    },
                    {
                        exact: true,
                        path: '/video/edit/:id',
                        component: 'material/multimedia/videoedit',
                    },
                    {
                        exact: true,
                        path: '/audio',
                        component: 'material/multimedia/audio',
                    },
                    {
                        exact: true,
                        path: '/audio/edit/:id',
                        component: 'material/multimedia/audioedit',
                    },
                ],
            },
            {
                path: '/quotaManager',
                component: 'label/quota',
                permission: 'quotaManager',
            },
            {
                path: '/label/list',
                component: 'label/list',
                permission: 'tagCenter:list',
            },
            {
                path: '/word',
                component: 'intelligence/word',
                permission: 'word',
            },
        ],
    },
    // 素材中心-活动库-开始
    { exact: true, path: '/extensionLibrary/preview/:id', component: 'material/actionLibrary/preview' },
    {
        path: '/extensionLibrary/action/edit/:id',
        component: 'material/actionLibrary/edit',
        routes: [
            {
                path: '/',
                component: 'material/actionLibrary/edit/content',
            },
        ],
    },
    // 素材中心-活动库-结束
    {
        exact: true,
        path: '/notice/list',
        component: 'notice/list',
        permission: 'noticemanage',
        icon: 'laba',
    },
    {
        exact: true,
        path: '/notice/records/:projectId',
        component: 'notice/records',
    },
    {
        path: '/employeecenter',
        component: 'employeecenter',
        routes: [
            {
                exact: true,
                path: '/list',
                permission: 'employeeList',
                component: 'employeecenter/list',
            },
            {
                exact: true,
                path: '/promotion',
                component: 'employeecenter/promotion',
            },
            {
                exact: true,
                path: '/registe',
                permission: 'employeeRegister',
                component: 'employeecenter/registe',
            },
        ],
        permission: 'employeeCenter',
        icon: 'yuangongzhongxin',
        showRoutes: true,
    },
    {
        path: '/label',
        component: 'label',
        routes: [
            {
                path: '/list',
                component: 'label/list',
                permission: 'tagCenter:list',
                exact: true,
            },
            {
                path: '/charts',
                component: 'label/charts',
                permission: 'tagCenter:rank',
                exact: true,
            },
            {
                path: '/quota',
                component: 'label/quota',
                permission: 'quotaManager',
                exact: true,
            },
        ],
        permission: 'tagCenter',
        icon: 'biaoqian1',
        showRoutes: true,
    },
    {
        path: '/setting',
        component: 'setting',
        permission: 'system',
        icon: 'setting',
        showRoutes: true,
        routes: [
            { path: '/organizations', permission: 'organizationmanage', component: 'employeecenter/list' },
            // { path: '/employee/list', component: 'employeecenter/list', permission: 'employeeList' },
            { path: '/employee/registe', component: 'employeecenter/registe', permission: 'employeeRegister' },
            { path: '/customer/registe', component: 'customercenter/registe', permission: 'customerRegistration' },
            { path: '/roles', component: 'setting/roles', permission: 'rolemanage' },
            {
                path: '/channels',
                component: 'setting/channels',
                permission: 'channelCenter',
                routes: [
                    {
                        path: '/list',
                        permission: 'channelList',
                        component: 'setting/channels/list',
                    },
                    {
                        path: '/bind',
                        permission: 'channelPublicBound',
                        component: 'setting/channels/weChat',
                    },
                    {
                        path: '/qrCode',
                        permission: 'qrcodeDynamic',
                        component: 'setting/channels/qrCode',
                    },
                    {
                        path: '/jssdk',
                        permission: 'jssdk',
                        component: 'setting/channels/jssdk',
                    },
                    {
                        path: '/notice',
                        exact: true,
                        permission: 'noticesdk',
                        component: 'setting/channels/notice',
                    },
                    {
                        path: '/noticelist',
                        component: 'notice/list',
                        permission: 'noticemanage',
                        icon: 'laba',
                    },
                ],
            },
            {
                path: '/dataManage',
                component: 'setting/dataManage',
                permission: 'dataManager',
                routes: [
                    {
                        path: '/dataObject',
                        component: 'setting/dataObject',
                        permission: 'customobject',
                        routes: [
                            {
                                path: '/list',
                                component: 'setting/dataObject/list',
                            },
                            {
                                path: '/edit/:id',
                                component: 'setting/dataObject/edit',
                            },
                        ],
                    },
                    {
                        path: '/information',
                        component: 'information',
                        permission: 'data',
                        icon: 'shujuzhongxin',
                    },
                ],
            },
            {
                path: '/updownload',
                permission: 'updownload',
                component: 'setting/updownload',
            },
            {
                path: '/systemLink',
                component: 'setting/systemLink',
                permission: 'systemconnect',
                routes: [
                    {
                        path: '/list',
                        component: 'setting/systemLink/list',
                    },
                    {
                        path: '/detail',
                        component: 'setting/systemLink/detail',
                    },
                    {
                        path: '/domain-config',
                        component: 'setting/systemLink/domainConfig',
                    },
                    {
                        path: '/ccps-config',
                        component: 'setting/systemLink/ccpsConfig',
                    },
                    {
                        path: '/youzan',
                        component: 'setting/systemLink/youzan',
                    },
                    {
                        path: '/speech-robot',
                        component: 'setting/systemLink/speechRobot',
                    },
                ],
            },
            { path: '/systemConfig', permission: 'systemConfig', component: 'setting/systemSetting' },
            { path: '/logs', permission: 'operatelog', component: 'setting/logs' },
            {
                path: '/message',
                component: 'setting/weixin',
                routes: [
                    {
                        path: '/alarm',
                        component: 'setting/weixin/alarm',
                    },
                    {
                        path: '/client',
                        component: 'setting/weixin/client',
                    },
                    {
                        path: '/staff',
                        component: 'setting/weixin/staff',
                    },
                ],
            },
            { path: '/sso', component: 'setting/sso', permission: 'sso' },
        ],
    },
    {
        exact: true,
        path: '/profile',
        component: 'setting/profile',
    },
    {
        path: '/interaction/:id',
        component: 'interactive/edit',
        routes: [
            { exact: true, path: '/', component: 'interactive/edit/article' },
            { path: '/content', component: 'interactive/edit/content' },
            { path: '/reward', component: 'interactive/edit/reward' },
            { path: '/preview', component: 'interactive/edit/preview' },
            { path: '/publish', component: 'interactive/edit/publish' },
        ],
    },
    {
        exact: true,
        path: '/information/:project_id',
        component: 'information',
    },
    {
        exact: true,
        path: '/information/detail/:project_id',
        component: 'information/detail',
    },
    {
        path: '/blockDetail/:reportId/:projectId/:questionId/:chartType',
        component: 'insight/card/block_window',
    },
    {
        path: '/notice/detail/:id',
        component: 'notice/detail',
        routes: [
            { exact: true, path: '/', component: 'notice/detail/basics' },
            { path: '/design', component: 'notice/detail/design' },
            { path: '/channel', component: 'notice/detail/channel' },
        ],
    },
    // 素材中心添加活动库后 新的互动走new-action路由,旧数据走interaction路由
    {
        path: '/new-action/:id',
        component: 'interactive/new-action',
        routes: [
            { exact: true, path: '/', component: 'interactive/new-action/article' },
            { path: '/reward', component: 'interactive/new-action/reward' },
            { path: '/preview', component: 'interactive/new-action/preview' },
            { path: '/publish', component: 'interactive/new-action/publish' },
        ],
    },
];

const allRoutes = buildRoutes(routes);

export default allRoutes;
export * from './base';
