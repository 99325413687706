import { ajaxSettings, setAppContext, logout, IS_LOCAL, API_PATH } from './utils';
import { message } from 'udesk-ui';

//全局ajax设置
ajaxSettings({
    error: (res, settings) => {
        if (res.message && settings.type !== 'GET') {
            message.error(res.message);
        }

        if (res.code === 401) {
            res.ok = true;  // 401 会跳转到登录页面，失败的请求不需要再弹出错误提示信息，所以此处ok设置为true
            //未授权 (Unauthorized)
            if (IS_LOCAL) {
                localStorage.TMP_API_PATH = API_PATH;
            }
            logout();
        }
    },
});

//设置app context
setAppContext({
    user: {}, //当前用户
    company: {}, //所属公司
    organization: {},
    menuList: [], //授权菜单
    allMenuList: [], //所有菜单
    organizationList: [],
});
