import EventedClass from './eventClass';

const INITIALIZE = Symbol('initialize');

class GlobalMessage extends EventedClass {
    constructor() {
        super();
        this[INITIALIZE]();
    }

    [INITIALIZE]() {}

    postMessage(type, content) {
        this.trigger('message', null, { type, content });
    }

    destory() {
        this.off('message');
    }
}

export default new GlobalMessage();
