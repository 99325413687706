export const {
    APP_NAME,
    APP_VERSION,
    PUBLIC_URL,
    APP_HOST,
    APP_API_PATH,
    APP_LOGIN_URL,
    APP_STATIC_SRV_HOST,
    NODE_ENV,
    APP_MOCK,
    APP_SDK_NOTICE,
} = process.env;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const SHORT_DATE_FORMAT = 'MM/DD';
export const SHORT_DATETIME_FORMAT = `${SHORT_DATE_FORMAT} ${TIME_FORMAT}`;
export const LONG_TIME_FORMAT = 'HH:mm:ss';
export const LONG_DATETIME_FORMAT = `${DATE_FORMAT} ${LONG_TIME_FORMAT}`;
export const EMPTY_LINK = `javascript:;`;
export const STATIC_PATH = `${PUBLIC_URL}/static`;
export const IMAGE_PATH = `${STATIC_PATH}/images`;
export const API_PATH = `${APP_HOST || ''}${APP_API_PATH}`;
export const LOGIN_URL = APP_LOGIN_URL;
export const TEST_DATA_PATH = `${STATIC_PATH}/test/data`;
export const CLIENT_H5_URL = `${APP_HOST || ''}/h5#/pages/client/survey/index`;
// export const WX_AUTH_URL = `${PUBLIC_URL}/auth/wx/`;
export const PAGE_DEFAULT_SIZE = 20;
export const IMAGE_ALLOW_EXT = '.gif,.jpg,.jpeg,.bmp,.png,.ico';
export const VIDEO_ALLOW_EXT = '.mp4';
export const AUDIO_ALLOW_EXT = '.mp3,.wma,.wav,.amr,.m4a';
export const ROBOT_IMAGE_ALLOW_EXT = '.jpg,.png';
export const CURRENT_PROJECT_PREFERENCES = 'currentProjectPreferences';
export const IS_LOCAL = window.location.host.indexOf('localhost') === 0;
export const IS_DEV = NODE_ENV === 'development';
export const IS_PROD = !IS_DEV;
export const IS_MOCK = NODE_ENV === 'development' && APP_MOCK === 'true';
export const IS_SHOW_APP_SDK_NOTICE = APP_SDK_NOTICE === 'true';
export const CCPASS_DOMAIN = 'wss://tower-xcc1.s4.udesk.cn/ws' || 'wss://t6.ccps.tryudesk.com:8443/ws';
export const CCPASS_HTTPDOMAIN =
    'https://apigw-xcc1.s4.udesk.cn/backend' || 'https://t6.ccps.tryudesk.com:8443/backend';
