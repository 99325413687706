import './config';
import React from 'react';
import 'moment';

import routes, { mapRoutes, Switch } from './routes';
import { dispatch } from './store';
import { BaseComponent, getJSON, getAttr } from './utils';
import './utils/historyRecorder.ts';
import isFunction from 'lodash/isFunction';
import find from 'lodash/find';
import extend from 'lodash/extend';
import Menu from './components/layout/menu';
import Header from './components/layout/header';
import ErrorBoundary from './components/common/error-boundary';
import { IS_SHOW_APP_SDK_NOTICE } from './utils/consts';
import { genSign, genNonce, genTimestamp, appId, appKey, getSDKParams } from './utils/noticeSDK';
import { message, Layout } from 'udesk-ui';
import 'udesk-ui/es/style/base.less';
import GlobalMessage from './utils/message/globalMessage';
import MessageTypes from './utils/message/messageType';
import './styles/App.scss';

// #if process.env.NODE_ENV === 'development'
// #if process.env.APP_MOCK === 'true'
if (process.env.NODE_ENV === 'development' && process.env.APP_MOCK === 'true') {
    require('./mock');
}
// #endif
// #endif
class App extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            collapsed: localStorage.collapsed !== 'false',
        };
        this.isShowMenu();
    }

    isShowMenu = () => {
        const rootEl = window.document.querySelector('#root');
        let urlParams = new URLSearchParams(window.location.search);
        let showMenu = urlParams.get('showMenu');
        if (showMenu === 'false') rootEl.classList.add('fullscreen');
    };

    componentDidMount() {
        //加载初始数据
        this.loadInitData();
        message.config({
            prefixCls: 'udesk-cem-message',
        });
    }

    componentWillUnmount() {
        clearInterval(this.noticeSDKTimer);
        if (isFunction(window.UdeskNoticeSDK)) {
            window.UdeskNoticeSDK('destroy');
        }
    }

    runNoticeSDK(product, area, companyId, agentId) {
        (function (a, b, c, d, e, f) {
            var g = b.createElement(c);
            g.async = 1;
            g.src = d + 'js/udesk_notice_sdk.js';
            g.onload = function () {
                window[e]('init', f);
            };
            c = b.getElementsByTagName(c)[0];
            c.parentNode.insertBefore(g, c);
            var h = b.createElement('link');
            h.rel = 'stylesheet';
            h.type = 'text/css';
            h.href = d + 'css/udesk_notice_sdk.css';
            c.parentNode.insertBefore(h, c);
        })(
            window,
            document,
            'script',
            window.origin + '/sdk/notice/static/',
            'UdeskNoticeSDK',
            getSDKParams(product, area, companyId, agentId)
        );
    }

    loadInitData() {
        getJSON('/init').always((res) => {
            if (res.ok) {
                let data = res.data || {};
                let { organizationList, company } = data;

                let organization = find(data.organizationList, (item) => getAttr(item, 'parent.id') === 0) || {};

                this.setAppContext(
                    extend(
                        {
                            organization,
                        },
                        data
                    )
                );
                dispatch('organizations', organizationList);
                dispatch('company', company);
                this.setState({
                    loading: false,
                });
                GlobalMessage.postMessage(MessageTypes.UdeskCCPaasSdkLoad, data.ccpassInfo);
                localStorage.setItem('organizationID', data.user.organization.id);
                if (IS_SHOW_APP_SDK_NOTICE) {
                    window.jsSHA = require('./lib/sha.js');
                    this.runNoticeSDK('CEM', 's1', data.user.company.id, data.user.id);
                    this.noticeSDKTimer = setInterval(() => {
                        const nonce = genNonce();
                        const timestamp = genTimestamp();
                        const sign = genSign(nonce, appKey, timestamp);
                        window.UdeskNoticeSDK('updateVerifyData', { appId, nonce, timestamp, sign });
                    }, 180000);
                }
            } else {
                message.error(res.message);
            }
        });
    }

    onCollapse() {
        let { collapsed } = this.state;

        collapsed = !collapsed;
        localStorage.collapsed = collapsed;
        this.setState({ collapsed });
    }

    render() {
        const { loading, collapsed } = this.state;
        return (
            <Layout>
                <Menu collapsed={collapsed} />
                <Layout>
                    <Header collapsed={collapsed} onCollapse={this.onCollapse.bind(this)} />
                    <Layout.Content>
                        {/* <Suspense
                            fallback={
                                <Spin
                                    style={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        zIndex: '999',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                />
                            }
                        > */}
                        <ErrorBoundary>{!loading && <Switch>{mapRoutes(routes)}</Switch>}</ErrorBoundary>
                        {/* </Suspense> */}
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

export default App;
