import locales from 'locales';
import React from 'react';

import extend from 'lodash/extend';
import pick from 'lodash/pick';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
import { UIBaseComponent } from '../../utils/baseComponent';
import { componentInjection } from '../../utils/componentInjection';
import { emptyFn } from '../../utils/base';

import { Button, Dropdown, Menu, Checkbox, Input } from 'udesk-ui';
import { Style } from '../base';
import Icon from '../icon';
import './style.scss';

const styles = {
    button: {
        paddingLeft: 12,
        paddingRight: 12,
    },
    menuStyle: {
        maxHeight: 280,
        overflowY: 'auto',
    },
};

/**
 * 列选择组建
 * @param {string} [nameKey='text'] - 显示名称key
 * @param {string} [valueKey='name'] - 值key
 * @param {Array} selected - 选中值
 * @param {Function} showItem - 自定义显示方法
 * @param {Function} onSelect - 接受选中事件
 */
/* prettier-ignore */
class Default extends UIBaseComponent {
    state = extend(
        {
            nameKey: 'text',
            valueKey: 'name',
            selected: []
        },
        pick(this.props, 'nameKey', 'valueKey', 'selected')
    );

    componentDidUpdate(prevProps) {
        if (prevProps.selected !== this.props.selected) {
            this.setState({
                selected: this.props.selected || []
            });
        }
    }

    showItem(item) {
        const { showItem } = this.props;
        const { nameKey, keyword } = this.state;
        const name = item[nameKey];

        if (!name) {
            return false;
        }

        //搜索显示判断
        if (keyword) {
            return name.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
        }

        //自定义显示判断接口
        return showItem ? showItem(item) : true;
    }

    onVisibleChange(visible) {
        this.setState({
            visible,
            keyword: ''
        });
    }

    onSelect(item) {
        const { onSelect = emptyFn } = this.props;
        const { valueKey, selected } = this.state;
        const value = item[valueKey];
        const index = findIndex(selected, v => v === value);

        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(value);
        }

        this.setState({ selected });

        return onSelect(item, selected);
    }

    onSearch(ev) {
        this.setState({
            keyword: ev.target.value
        });
    }

    render() {
        const { classes, columns = [] } = this.props;
        const { nameKey, valueKey, selected, visible, keyword } = this.state;
        const Search = (
            <Input.Search
                size='small'
                placeholder={locales.get('components-common-option-4im7n4yc03g')}
                value={keyword}
                onChange={this.onSearch.bind(this)}
            />
        );

        return (
            <Dropdown
                visible={visible}
                trigger={['click']}
                overlay={
                    <Menu className={classes.menuStyle}>
                        {<Style padding='8px 10px'>{Search}</Style>}
                        {columns.map(
                            item =>
                                this.showItem(item) && (
                                    <Menu.Item key={item[valueKey]}>
                                        <Checkbox
                                            defaultChecked={includes(selected, item[valueKey])}
                                            onChange={this.onSelect.bind(this, item)}
                                        >
                                            {item[nameKey]}
                                        </Checkbox>
                                    </Menu.Item>
                                )
                        )}
                    </Menu>
                }
                onVisibleChange={this.onVisibleChange.bind(this)}
            >
                <Button className={`${classes.button} table-btn`}>
                    {locales.get('components-common-tableColumnFilterTransfer-o19wnb41fbn')}
                    <Icon antd type='caret-down' style={{ marginLeft: 2 }} />
                </Button>
            </Dropdown>
        );
    }
}

export default componentInjection(Default, { styles });
