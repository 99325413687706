import locales from 'locales';
import React from 'react';
import { componentInjection, BaseComponent, parseInt, getJSON, putJSON } from '../../../utils';
import { Icon } from '../../../ui';
import { List, Spin } from 'udesk-ui'; //antd 4.0 upgrade
import InfiniteScroll from 'react-infinite-scroller';
import remove from 'lodash/remove';
import extend from 'lodash/extend';
import find from 'lodash/find';

const styles = {
    listRoot: {
        height: '100%',
        overflow: 'auto',
    },
    listItem: {
        padding: '24px',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: '10px',
        fontSize: 16,
        fontWeight: 500,
        color: 'rgba(13,19,38,1)',
        '& span': {
            fontSize: 12,
            height: '30px',
            lineHeight: '30px',
            color: 'rgba(13,19,38,0.6)',
            fontWeight: 'normal',
        },
        '& strong': {
            marginLeft: '8px',
        },
    },
    content: {
        marginTop: '12px',
        fontSize: 14,
        lineHeight: '20px',
        width: 290,
    },
    handler: {
        display: 'block',
        textAlign: 'center',
        lineHeight: '32px',
        marginTop: '18px',
        width: 88,
        height: 32,
        background: 'rgba(243,243,244,1)',
        borderRadius: '3px',
        color: 'rgba(89,126,247,1)',
        cursor: 'pointer',
    },
    loadingList: {
        height: '45px',
        width: '100%',
        textAlign: 'center',
    },
};

class Default extends BaseComponent {
    state = {
        data: [],
        loading: false,
        hasMore: true,
    };

    conditions = {
        pageNum: 1,
        pageSize: 12,
    };

    statusTypes = [
        {
            color: '#597EF7',
            text: locales.get('components-layout-inform-0p5dk5j9ztw'),
            icon: 'chulifangshi',
            value: 0,
            handler: locales.get('components-layout-inform-3jc86rkfc0o'),
        },
        {
            color: '#F75959',
            text: locales.get('components-journeys-customer-6oa69xm0id'),
            icon: 'yujingzhuangtaibeifen',
            value: 1,
            handler: locales.get('components-layout-inform-klayq92wjx'),
        },
        {
            color: '#FFCC2F',
            text: locales.get('components-layout-inform-aon3eefmwi'),
            icon: 'tongzhifangshibeifen',
            value: 2,
            handler: locales.get('components-layout-inform-1vm0gqp8qay'),
        },
        {
            color: '#39C557',
            text: locales.get('components-layout-inform-hck6eh54ll'),
            icon: 'hudongzhongxin',
            value: 3,
            handler: locales.get('components-layout-inform-72pqo47chva'),
        },
        {
            color: '#95DE64',
            text: locales.get('components-layout-inform-u53f4uceg59'),
            icon: 'xiazai',
            value: 4,
            handler: locales.get('components-layout-inform-ky9ba3kws9i'),
        },
        {
            color: '#69C0FF',
            text: locales.get('components-common-ueditor-lang-zh-cn-mwuv3b4g07p'),
            icon: 'shangchuan',
            value: 5,
            handler: locales.get('components-layout-inform-ngcgwx1akt'),
        },
    ];

    componentDidMount() {
        this.props.onRef(this);
        this.reload();
    }

    reload() {
        this.conditions = {
            pageNum: 1,
            pageSize: 12,
        };
        this.fetchData((res) => {
            this.setState({
                hasMore: true,
                loading: false,
                data: res.data,
            });
        });
    }

    fetchData = (callback) => {
        const { updateUnreadNum, readStatus } = this.props;
        getJSON(`/notifications`, {
            pageNum: this.conditions.pageNum,
            pageSize: this.conditions.pageSize,
            readStatus: readStatus,
        }).always((res) => {
            extend(this.conditions, {
                pageNum: res.paging.pageNum + 1,
                total: res.paging.total,
            });
            updateUnreadNum && updateUnreadNum(res.paging.total);
            callback(res);
        });
    };

    handleInfiniteOnLoad = () => {
        let { data } = this.state;
        if (
            this.conditions.pageNum >=
            (this.conditions.total + this.conditions.pageSize - 1) / this.conditions.pageSize
        ) {
            this.setState({
                hasMore: false,
                loading: false,
            });
            return;
        }
        this.setState({
            loading: true,
        });
        this.fetchData((res) => {
            data = data.concat(res.data);
            this.setState({
                data,
                loading: false,
            });
        });
    };

    informClickHandler = (item) => {
        const { data } = this.state;
        const { switchoverDrawer, updateUnreadNum, readStatus } = this.props;
        putJSON(`/notifications/${item.id}`, {
            readStatus: 1,
        }).always((res) => {
            if (res.ok) {
                if (readStatus === '0') {
                    updateUnreadNum && updateUnreadNum(this.conditions.total - 1);
                    remove(data, (n) => n.id === item.id);
                    this.setState({ data });
                }
                let type = item.type.toString();
                if (type === '4' || type === '5') {
                    //上传下载直接跳页面
                    let key = type === '4' ? 0 : 1;
                    this.goPage(`/setting/updownload`, {
                        type: key,
                    });
                }
                switchoverDrawer && switchoverDrawer(item);
            }
        });
    };

    render() {
        const { loading, hasMore, data } = this.state;
        const { classes, readStatus } = this.props;
        let config = (item) => {
            return (
                find(this.statusTypes, { value: parseInt(item.type) }) || {
                    color: '#B6B9C1',
                    text: locales.get('components-common-ueditor-lang-zh-cn-66exlvjuey'),
                    icon: 'tongzhi1',
                    handler: null,
                }
            );
        };
        return (
            <InfiniteScroll
                initialLoad={false}
                pageStart={1}
                loadMore={this.handleInfiniteOnLoad}
                hasMore={!loading && hasMore}
                useWindow={false}
            >
                <List
                    dataSource={data}
                    itemLayout="vertical"
                    renderItem={(item) => (
                        <List.Item key={item.id} className={classes.listItem}>
                            <div className={classes.title}>
                                <div>
                                    <Icon
                                        // theme='filled'
                                        style={{ color: config(item).color, fontSize: 20 }}
                                        type={config(item).icon}
                                    />
                                    <strong>
                                        {config(item).text}
                                        {locales.get('components-layout-inform-qokfekainth')}
                                    </strong>
                                </div>
                                <span>{item.createTime}</span>
                            </div>
                            <div className={classes.content}>
                                {item.subject.length > 36 ? `${item.subject.substr(0, 36)}...` : item.subject}
                            </div>
                            {config(item).handler && (
                                <span className={classes.handler} onClick={this.informClickHandler.bind(this, item)}>
                                    {readStatus === '0' ? config(item).handler : `查看${config(item).text}`}
                                </span>
                            )}
                        </List.Item>
                    )}
                >
                    {loading && hasMore && (
                        <div className={classes.loadingList}>
                            <Spin />
                        </div>
                    )}
                </List>
            </InfiniteScroll>
        );
    }
}

export default componentInjection(Default, { styles });
