import { APP_HOST } from './consts';
import { log } from './base';

export const localStoragePrefix = 'notice_sdk_demo_';

export const appId = localStorage.getItem(`${localStoragePrefix}appId`) || '896d08422cba4fcdab5103f1a9166e13';
export const appKey = localStorage.getItem(`${localStoragePrefix}appKey`) || '6189238fac898bc4f05ea48d1d10498412248c20';

export function genSign(nonce, appKey, timestamp) {
    if (!window.jsSHA) {
        log('error', 'load jsSHA file failed!');
        return null;
    }
    const shaObj = new window.jsSHA('SHA-1', 'TEXT');
    shaObj.update('nonce=' + nonce + '&timestamp=' + timestamp + '&' + appKey);
    return shaObj.getHash('HEX').toUpperCase();
}

export function genNonce() {
    const nonce = window.crypto.getRandomValues(new Uint32Array(1));
    return nonce[0];
}

export function genTimestamp() {
    return Math.round(new Date().getTime() / 1000);
}

export function getSDKParams(product, area, companyId, agentId) {
    const nonce = genNonce();
    const timestamp = genTimestamp();
    const sign = genSign(nonce, appKey, timestamp);
    const runCode = localStorage.getItem(`${localStoragePrefix}run_code`);
    let runCodeObject = {};
    try {
        runCodeObject = JSON.parse(runCode) || {};
    } catch (error) {}
    return {
        data: {
            product: product,
            area: area,
            companyId: companyId,
            agentId: agentId,
            ...runCodeObject.data,
            appId: appId,
            sign: sign,
            timestamp: timestamp,
            nonce: nonce
        },
        props: {
            apiPath: APP_HOST + '/apibackend/v1/sdkNotice'
        }
    };
}
