import html2canvas from 'html2canvas';
import isElement from 'lodash/isElement';
import domToImage from 'dom-to-image';
// import Canvg from 'canvg';
// /**
//  * 网页截图
//  * @param {string|Element} [snapshotSelector] CSS选择器或Element
//  * @param {string} [fileType] 文件类型 默认: image/png
//  */
export function snapshotByHtml2Canvas(snapshotSelector, fileType = 'image/png') {
    return new Promise((resolve, reject) => {
        let snapshotTarget = null;

        try {
            snapshotTarget = isElement(snapshotSelector) ? snapshotSelector : document.querySelector(snapshotSelector);
        } catch (error) {
            reject(error);
        }
        if (snapshotTarget) {
            html2canvas(snapshotTarget, {
                // 暂时不允许跨域图片 allowTaint:false
                logging: false,
                removeContainer: true,
                // allowTaint: true,
                useCORS: true,
            }).then(
                (canvas) => {
                    const uri = canvas.toDataURL(fileType);

                    resolve({
                        snapshotSelector,
                        uri,
                    });
                },
                (error) => {
                    console.log('html2canvas error', error);
                    reject(error);
                }
            );
        } else {
            reject(new Error('can not find the selector.'));
        }
    });
}
/**
 * 网页截图
 * @param {string|Element} [snapshotSelector] CSS选择器或Element
 * @param {string} [fileType] 文件类型 默认: image/png 导出图片一直为png格式
 */
export function snapshot(snapshotSelector, fileType = 'image/png') {
    return new Promise((resolve, reject) => {
        let snapshotTarget = null;

        try {
            snapshotTarget = isElement(snapshotSelector) ? snapshotSelector : document.querySelector(snapshotSelector);
        } catch (error) {
            reject(error);
        }
        if (snapshotTarget) {
            domToImage.toPng(snapshotTarget).then(
                (uri) => {
                    resolve({
                        snapshotSelector,
                        uri,
                    });
                },
                (error) => {
                    console.log('DomToImage error', error);
                    reject(error);
                }
            );
        } else {
            reject(new Error('can not find the selector.'));
        }
    });
}
/**
 *url转blob
 */
export function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export function downloadImage(imgsrc, name) {
    //下载图片地址和图片名
    let image = new Image();
    // 解决跨域 Canvas 污染问题
    image.crossOrigin = 'Anonymous';
    image.onload = function () {
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);

        let url = canvas.toDataURL('image/png'); //得到图片的base64编码数据
        let a = document.createElement('a'); // 生成一个a元素
        let event = new MouseEvent('click'); // 创建一个单击事件
        a.download = name || 'photo'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
    };

    image.src = imgsrc;
}

export function convertImgUrlToBase64(url, callback) {
    let canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d'),
        img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        canvas.height = img.height + 20;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL('image/png');
        callback.call(this, dataURL);
        canvas = null;
    };
    img.src = url;
}
/**
 *
 * @param {*} imgUrl 图片路径
 * @param {*} legendName 图例名称
 */
export function convertOneLegendImgToBase64(imgUrl, legendName) {
    return new Promise((resolve, reject) => {
        convertImgUrlToBase64(imgUrl, function (base64Img) {
            //转化后的base64
            resolve({
                legendName,
                base64Img: 'image://' + base64Img,
            });
            if (!base64Img) {
                reject('err');
            }
        });
    });
}
// SVG 转换成canvas
// const complizeSVGInCanvas = (snapshotSelector) => {
//     return;
//     let snapshotTarget = null;
//     try {
//         snapshotTarget = isElement(snapshotSelector) ? snapshotSelector : document.querySelector(snapshotSelector);
//     } catch (error) {
//         throw new Error('No Element');
//     }

//     const copyDom = snapshotTarget.cloneNode(true);
//     console.log(copyDom, 'copyDom');

//     let svgElems = copyDom.querySelectorAll('svg');
//     svgElems.forEach((node, index) => {
//         let parentNode = node.parentNode;
//         node.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');

//         let svg = node.outerHTML.trim();
//         let canvas = document.createElement('canvas');
//         if (node.style.position) {
//             canvas.style.position += node.style.position;
//             canvas.style.left += node.style.left;
//             canvas.style.top += node.style.top;
//         }
//         canvas.style = node.style;
//         setTimeout(() => {
//             parentNode.removeChild(node);
//             parentNode.appendChild(canvas);
//         }, 1000);
//     });
//     document.body.appendChild(copyDom);
//     // copyDom.style.display = 'none';
//     return { copyDom };
// };
