export function roundUp(num, step) {
    //将数值取整处理，避免小数问题
    let number = Math.trunc(num);
    number = Number(number);

    if (number === 0 || isNaN(number)) {
        return 0;
    }

    let numString = number.toString();
    let numStringLength = numString.length;

    let isNegative = numString.startsWith("-");
    let hasNumericSymbol = isNegative || numString.startsWith("+");

    step = Number(step);
    if (isNaN(step) || step === 0) {

        let tempNum = number;
        while (tempNum % 10 === 0) {
            tempNum /= 10;
        }
        if (tempNum < 10) {
            return number;
        }

        if (hasNumericSymbol) {
            if (numStringLength > 2) {
                if (isNegative) {
                    let numCount = parseInt(numString[1], 10) - 1;
                    let magnitude = numStringLength - 2;
                    if (numCount === 0) {
                        return 0;
                    }
                    step = (numCount) * Math.pow(10, magnitude);
                } else {
                    step = (parseInt(numString[1], 10) + 1) * Math.pow(10, numStringLength - 2);
                }
            } else {
                step = 10;
            }
        } else {
            if (numStringLength > 1) {
                step = (parseInt(numString[0], 10) + 1) * Math.pow(10, numStringLength - 1);
            } else {
                step = 10;
            }
        }
    } else {
        if (number % step === 0) {
            return number;
        }
    }

    return (Math.floor(number / step) + 1) * step;
}