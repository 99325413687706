import locales from 'locales';
import React from 'react';
import { BaseComponent, isShowLanguageSwitch, APP_HOST, CCPASS_DOMAIN, CCPASS_HTTPDOMAIN } from '@Utils';
import { Link, Icon, Space } from '../../ui';
import { Layout, Avatar, Dropdown, Menu, Tooltip, Badge, message } from 'udesk-ui'; //antd 4.0 upgrade
import { CaretDownOutlined } from '@ant-design/icons';
import Inform from './inform';
import { withRouter } from 'react-router-dom';
import withStyles from 'react-jss';
import { SUPPOER_LOCALES, langKeysOptions } from '@Utils/locales';
import find from 'lodash/find';
import { API_PATH } from '@Utils/consts';
import GlobalMessage from '../../utils/message/globalMessage';
import MessageTypes from '../../utils/message/messageType';

import './style.scss';

import proxy from '@Utils/api';

const { Header } = Layout;
const styles = {
    voiceSdkS: {
        display: 'inline-block',
    },
    foldIcon: {
        fontSize: 24,
        padding: '0 24px',
        cursor: 'pointer',
        color: '#9EA1A8',
        transition: 'color 0.3s',
        '&:hover': {
            color: '#1890ff',
        },
    },
    profile: {
        float: 'right',
        display: 'flex',
        alignItems: 'center',
        '& a': {
            color: 'inherit',
            display: 'block',
            padding: '0 24px',
            '&:hover': {
                backgroundColor: '#F5F5F5',
            },
        },
        '& i': {
            fontSize: 12,
        },
        '& .udesk-cem-dropdown-trigger': {
            color: 'inherit',
            display: 'block',
            padding: '0 24px',
            '&:hover': {
                backgroundColor: ' #F5F5F5',
            },
        },
    },
    informIcon: {
        marginRight: '18px',
        cursor: 'pointer',
        color: 'rgba(0,0,0,.65)',
        '&:hover': {
            color: '#597EF7',
        },
        '& .udesk-cem-badge-count': {
            left: '12px',
            right: 'auto',
            minWidth: '16px',
            padding: '0 4px',
            height: '16px',
            lineHeight: '16px',
            transform: 'translateY(-45%)',
        },
    },
    logoutBox: {
        '&:hover': {
            color: 'rgba(89, 126, 247, 0.7)',
        },
    },
    headerSettingIcon: {
        marginRight: '8px',
    },
};
const roundInterval = 50; // 轮询间隔(s)

class Default extends BaseComponent {
    state = {
        unreadNum: 0,
    };

    longTime = {
        last: null,
        now: null,
    };

    informMouseDown = () => {
        this.longTime.last = new Date();
    };

    informMouseUp = () => {
        this.longTime.now = new Date();
        const informSwitch = localStorage.getItem('informSwitch') && Number(localStorage.getItem('informSwitch'));
        if (Date.parse(this.longTime.now) - Date.parse(this.longTime.last) >= 8 * 1000) {
            if (informSwitch) {
                localStorage.setItem('informSwitch', 0);
                this.beginPolling();
            } else {
                localStorage.setItem('informSwitch', 1);
                clearInterval(this.getInformRound);
            }
        }
    };

    componentDidMount() {
        const informSwitch = localStorage.getItem('informSwitch') && Number(localStorage.getItem('informSwitch'));
        this.fetchData();
        if (!informSwitch) {
            this.beginPolling();
        } else {
            this.fetchData();
        }
        GlobalMessage.on('message', null, ({ type, content }) => {
            if (type === MessageTypes.UdeskCCPaasSdkLoad) {
                this.voiceSdk(window.UdeskCCPaasApiClass, content);
            }
        });
    }
    //SDK接入
    voiceSdk = (UdeskCCPaasApiClass, ccpassInfo) => {
        if (!ccpassInfo || !ccpassInfo.openCcpassFlag || !ccpassInfo.agent) {
            return;
        }
        let AppDataProviderBase = UdeskCCPaasApiClass.AppDataProvider;
        class AppDataProvider extends AppDataProviderBase {
            // 用来获取token,进行鉴权
            GetToken() {
                return new Promise((resolve, reject) => {
                    //发送请求获取鉴权信息
                    proxy[`connectParams/ccpass/init`]({ type: 'get', showSuccessMessage: false }).then((res) => {
                        if (res.data) {
                            resolve({
                                appId: res.data.appId, // 租户唯一标识
                                timestamp: res.data.timestamp, // 鉴权用的时间戳
                                token: res.data.token, // 鉴权token
                            });
                        }
                    });
                });
            }
        }

        let UdeskCCPaasApiClassInstance = new UdeskCCPaasApiClass({
            // 初始化apiJsUrl需要传的参数
            AgentId: ccpassInfo.agent.agentId,
            AppId: ccpassInfo.appId,
            AutoConnect: true,
            AppDataProvider: new AppDataProvider(),
            Domain: CCPASS_DOMAIN,
            HttpDomain: CCPASS_HTTPDOMAIN,
            Environment: 'development',
            Log: function (msg, type) {
                // message: 提示信息
                // type: 提示信息所属的状态
                switch (type) {
                    case 'success':
                        message.success(`${msg}`);
                        break;
                    case 'warning':
                        message.warning(`${msg}`);
                        break;
                    case 'info':
                        message.info(`${msg}`);
                        break;
                    case 'danger':
                        message.error(`${msg}`);
                        break;
                    default:
                        break;
                }
            },
        });
        UdeskCCPaasApiClassInstance.init();
        UdeskCCPaasApiClassInstance.on('AdvancedCallStatusChanged', (resp) => {
            console.log('AdvancedCallStatusChanged', resp);
            const AdvancedCallStatus = resp.Data && resp.Data.AdvancedCallStatus;
            if (AdvancedCallStatus) {
                proxy[`ccpassCallRecords`]({
                    type: 'post',
                    showSuccessMessage: false,
                    data: { callId: resp.Data.CallID },
                });
            }
        });
        GlobalMessage.on('message', null, ({ type, content }) => {
            if (type === MessageTypes.UdeskCCPaasMakeCall) {
                console.log(content);
                UdeskCCPaasApiClassInstance.MakeCallAsync(content);
            }
        });

        let udeskCtiPhoneBarInstance = new window.UdeskCtiPhoneBar({
            udeskCCPaasApi: UdeskCCPaasApiClassInstance,
            Buttons: {
                AgentStatus: {
                    Visible: true,
                    ClassNames: 'udesk-ccpass-component-button',
                },
                MakeCall: {
                    ClassNames: 'udesk-ccpass-component-button',
                },
            },
        });
        udeskCtiPhoneBarInstance.RenderTo(document.getElementById('voiceSdk'));
    };

    beginPolling = () => {
        this.getInformRound = setInterval(() => {
            this.fetchData();
        }, roundInterval * 1000);
    };

    componentWillUnmount() {
        clearInterval(this.getInformRound);
    }

    fetchData = () => {
        proxy[`/notifications`]({ type: 'get', showSuccessMessage: false }).then((res) => {
            this.updateUnreadNum(res.paging.total);
        });
    };

    onCollapse = () => {
        const { onCollapse } = this.props;

        onCollapse && onCollapse();
    };

    onLogout() {
        // 后端控制登出URL
        window.location.replace(API_PATH + '/logout');

        // 后端控制登出URL
        // proxy['/logout']({
        //     type: 'get',
        //     showSuccessMessage: false,
        //     showErrorMessage: false,
        // });
        // postJSON('/logout').always((res) => {
        //     if (res.ok) {
        //         logout();
        //     } else {
        //         message.error(res.message);
        //     }
        // });
    }

    bindRef = (ref) => {
        this.informBox = ref;
    };

    updateUnreadNum = (num) => {
        this.setState({
            unreadNum: num,
        });
    };

    onChangeLang = () => {
        const langOptions = this.getLangOptions();
        const { origin, pathname } = window.location;
        proxy[`language`]({
            type: 'put',
            data: {
                language: langOptions.value,
            },
            showSuccessMessage: false,
        }).then((res) => {
            if (res && res.code === 200) {
                localStorage.setItem(langKeysOptions.localStorageLocaleKey, langOptions.value);
                window.location.href = `${origin}${pathname}?lang=${langOptions.value}`;
            }
        });
    };

    getLangOptions = () => {
        const localStorageLocaleKey = localStorage.getItem(langKeysOptions.localStorageLocaleKey);
        return localStorageLocaleKey
            ? find(SUPPOER_LOCALES, (o) => o.value !== localStorageLocaleKey)
            : SUPPOER_LOCALES[0];
    };

    render() {
        const { unreadNum } = this.state;
        const { classes, collapsed, history } = this.props;

        return (
            <Header>
                <Icon
                    className={classes.foldIcon}
                    type={collapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={this.onCollapse.bind(this, collapsed)}
                />
                <div id="voiceSdk" className={classes.voiceSdkS}></div>
                <div className={classes.profile}>
                    <img
                        src="/static/images/bangzhuzhongxin.gif"
                        alt="bangzhuzhongxin"
                        style={{ height: 24, cursor: 'pointer' }}
                        onClick={() => window.open(`${APP_HOST}/docs/`)}
                    />
                    <Space size={2} />
                    <span className="moban" onClick={() => this.goPage(`/templateCenter`)}>
                        <Icon type="mobanku" style={{ fontSize: 20, position: 'relative', top: 2 }} theme="filled" />
                        <Space size={1} />
                        <span className="moban-title">{locales.get('pages.template.title')}</span>
                    </span>
                    <Space size={2} />
                    <Badge count={unreadNum} className={classes.informIcon}>
                        <Tooltip placement="top" title={locales.get('components-layout-8sz8i6lodqh')}>
                            <Icon
                                type="tongzhi"
                                style={{ fontSize: 20 }}
                                theme="filled"
                                onClick={() => this.informBox.openDrawer()}
                                onMouseDown={this.informMouseDown}
                                onMouseUp={this.informMouseUp}
                            ></Icon>
                        </Tooltip>
                    </Badge>
                    <Space />
                    <Dropdown
                        overlay={
                            <Menu>
                                {isShowLanguageSwitch && (
                                    <Menu.Item key="0" onClick={this.onChangeLang}>
                                        <span>
                                            <Icon type="yuyan" className={classes.headerSettingIcon}></Icon>
                                            {this.getLangOptions().name}
                                        </span>
                                    </Menu.Item>
                                )}
                                {isShowLanguageSwitch && <Menu.Divider />}
                                <Menu.Item key="1">
                                    <Link to="/profile">
                                        <Icon
                                            type="shezhi"
                                            className={classes.headerSettingIcon}
                                            onClick={() => this.informBox.openDrawer()}
                                        ></Icon>
                                        {locales.get('components-layout-mvudfb5eujs')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Divider />
                                <Menu.Item key="2" onClick={this.onLogout.bind(this)} className={classes.logoutBox}>
                                    <span>
                                        <Icon
                                            type="zhuxiao"
                                            className={classes.headerSettingIcon}
                                            onClick={() => this.informBox.openDrawer()}
                                        ></Icon>
                                        {locales.get('components-layout-qep5592scn7')}
                                    </span>
                                </Menu.Item>
                            </Menu>
                        }
                        placement="bottomRight"
                        trigger={['click']}
                    >
                        <span>
                            {this.appContext.user.realname} <CaretDownOutlined />
                            <Space />
                            <Avatar src={this.appContext.user.headPortraitUrl || ''}>
                                {this.appContext.user.realname}
                            </Avatar>
                        </span>
                    </Dropdown>
                </div>
                <Inform
                    history={history}
                    updateUnreadNum={this.updateUnreadNum}
                    unreadNum={unreadNum}
                    onRef={this.bindRef}
                />
            </Header>
        );
    }
}

export default withStyles(styles)(withRouter(Default));
