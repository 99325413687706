import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../../ui';
import { Modal, Spin } from 'udesk-ui';
import './style.scss';
import isEqual from 'lodash/isEqual';
class PreviewImg extends Component {
    static propTypes = {
        itemList: PropTypes.array,
        itemListIndex: PropTypes.number,
        handleCancel: PropTypes.func,
        previewBoxVisable: PropTypes.bool,
    };

    static defaultProps = {
        itemList: [],
        itemListIndex: 0,
        handleCancel: () => {},
        previewBoxVisable: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            itemListIndex: props.itemListIndex || 0,
            itemList: props.itemList,
            imgComplete: true,
        };
        this.imgRef = React.createRef();
    }
    getIndex = (direction) => {
        if (isEqual(direction, 'left')) {
            this.setState({
                itemListIndex: this.state.itemListIndex - 1,
            });
        } else {
            this.setState({
                itemListIndex: this.state.itemListIndex + 1,
            });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(this.props.itemList, this.state.itemList)) {
            this.setState({
                itemListIndex: this.props.itemListIndex,
                itemList: this.props.itemList,
            });
        }
    }
    // 放大
    enlarge = () => {
        this.imgRef.current.style.width = `${this.imgRef.current.width * 1.1}px`;
        this.imgRef.current.style.maxWidth = `unset`;
    };
    unenlarge = () => {
        this.imgRef.current.style.width = `${this.imgRef.current.width / 1.1}px`;
        this.imgRef.current.style.maxWidth = `unset`;
    };
    render() {
        let { itemListIndex, itemList, imgComplete } = this.state;
        let { previewBoxVisable, handleCancel } = this.props;
        return (
            <Fragment>
                {previewBoxVisable && itemList.length > 0 && (
                    <Modal
                        visible={previewBoxVisable}
                        className="preview-modal-box"
                        footer={null}
                        closable={false}
                        onCancel={handleCancel}
                    >
                        <div className="preview-box">
                            <div className="preview-box-content">
                                {!isEqual(itemListIndex, 0) && (
                                    <div className="preview-left-box" onClick={() => this.getIndex('left')}>
                                        <Icon type="qiehuan1"></Icon>
                                    </div>
                                )}
                                <div className="preview-center-box">
                                    <Spin spinning={imgComplete}>
                                        <img
                                            ref={this.imgRef}
                                            src={itemList[itemListIndex].imgSrc}
                                            alt=""
                                            onLoad={() => {
                                                this.setState({
                                                    imgComplete: false,
                                                });
                                            }}
                                        ></img>
                                        {/* <Button
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '30%'
                                            }}
                                            onClick={() => {
                                                this.enlarge();
                                            }}
                                        >
                                            放大
                                        </Button>
                                        <Button
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '60%'
                                            }}
                                            onClick={() => {
                                                this.unenlarge();
                                            }}
                                        >
                                            缩小
                                        </Button> */}
                                    </Spin>
                                </div>

                                <div className="preview-right-close-box">
                                    <button
                                        type="button"
                                        aria-label="Close"
                                        className="udesk-cem-modal-close"
                                        onClick={handleCancel}
                                    >
                                        <Icon type="guanbi1"></Icon>
                                    </button>
                                    {!isEqual(itemListIndex, itemList.length - 1) && (
                                        <div className="preview-right-box" onClick={() => this.getIndex('right')}>
                                            <Icon type="qiehuan1"></Icon>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default PreviewImg;
