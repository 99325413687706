import locales from 'locales';
import React, { Fragment } from 'react';
import moment from 'moment';
//该处不可引入models中导出，会导致国际化locales.get取不到值， 所用导入当页面写死。 此处引入的值在对应的models中改变时，该页面也需要手动更改
// import { missionStatus, missionPriority, missionGoal, controlColumns } from '../../models/mission.js';
import {
    BaseComponent,
    componentInjection,
    getJSON,
    parseInt,
    DATETIME_FORMAT,
    postJSON,
    deleteJSON,
    putJSON,
    isPermission,
    deepFreeze,
    dateFormat,
} from '../../utils';

import extend from 'lodash/extend';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import UsersSelect from '../common/select/users';
import { Table, Drawer, Icon, Editor } from '../../ui';
import { Form } from '@ant-design/compatible';
import '@Styles/_compatibleIndex.css';
import {
    Button,
    Popconfirm,
    DatePicker as AntdDatePicker,
    Divider,
    Tabs,
    Comment,
    List,
    Mentions,
    Avatar,
    Select,
    Popover,
    Input,
} from 'udesk-ui'; //antd 4.0 upgrade
import CheckBox from 'rc-checkbox';
import RecordsDetails from '../alarm/records/details';
import PreviewImg from '../common/preview-img';
const { Option, getMentions } = Mentions;
class Default extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            isEndTimeEditor: false,
            isLeaderEditor: false,
            isPriorityEditor: false,
            isStatusEdit: false,
            isGoalEdit: false,
            isCopyUserEdit: false,
            isDescribeEdit: false,
            isCheckEdit: false,
            isYuJing: false,
            loading: false,
            conditionsTab: { pageNum: 1, pageSize: 10 },
            drawerData: this.props.drawerData,
            mentionIds: this.props.mentionIds,
            logsData: this.props.logsData,
            id: this.props.id,
            allLeaders: [],
            isTitleEdit: false,
            isCommentEdit: false,
            mentions: [],
            mode: 'date',
            previewBoxVisable: false,
            itemListIndex: 0,
            itemList: [],
            saveCheckItemsLoading: false,
        };

        this.missionStatusModel = [
            { color: 'rgba(13,19,38,0.6)', text: locales.get('models-dkhrk0h35v4'), value: 1, code: 'complete' },
            { color: '#FFFFFF', text: locales.get('components-mission-8dvzjt1w5gf'), value: 0, code: 'unfinished' },
        ];

        this.missionPriorityModel = [
            { key: '3', color: '#F75959', text: locales.get('models-ld7h4tlgxce'), value: 3 },
            { key: '2', color: '#FC8D01', text: locales.get('models-1q9nqkllnyx'), value: 2 },
            { key: '1', color: '#FFD54F', text: locales.get('components-notice-detail-design-j0oz0x4neks'), value: 1 },
            { key: '0', color: '#53C205', text: locales.get('models-1cjqryc156i'), value: 0 },
        ];

        this.missionGoalModel = [
            { key: '0', text: locales.get('models-bjy9vbq0iz5'), value: 0 },
            { key: '1', text: locales.get('models-watryj2yvk'), value: 1 },
            { key: '2', text: locales.get('models-4lbxwlswpq4'), value: 2 },
            { key: '3', text: locales.get('components-common-ueditor-lang-zh-cn-66exlvjuey'), value: 3 },
        ];

        this.missionTypeModel = [
            { text: locales.get('models-5oxasgcul56'), value: 1 },
            { text: locales.get('models-2bqwdp746eg'), value: 0 },
        ];

        this.infoFunctionModel = function (params) {
            switch (params) {
                case 'priority':
                    return locales.get('components-mission-2wvjydylcnf');
                case 'status':
                    return locales.get('components-alarm-records-7cz5ha2z74m');
                case 'type':
                    return locales.get('models-g3c7vymc7z');
                case 'target':
                    return locales.get('components-mission-510c1eqap6q');
                case 'name':
                    return locales.get('models-ymqxmpvlqkp');
                case 'ccs':
                    return locales.get('components-mission-pydfsgxkaa');
                case 'leaderUserId':
                    return locales.get('components-mission-3mjtdll3d98');
                case 'deadlineTime':
                    return locales.get('components-mission-ujcnaf6wu6l');
                case 'description':
                    return locales.get('components-mission-o9e4omkg1q');
                default:
                    break;
            }
        };

        this.controlColumnsModel = deepFreeze([
            {
                dataIndex: 'operateTime',
                title: locales.get('models-ji9we7knvtl'),
                render: (value) => dateFormat(value),
            },
            {
                dataIndex: 'operator',
                title: locales.get('models-ry6uh7yvwfr'),
                render: (value) => value.name,
            },
            {
                dataIndex: 'operateFields',
                title: locales.get('models-op0rcp8ekqj'),
                render: (value) => {
                    if (value.length > 1) {
                        return value.map((item) => <div>{this.infoFunctionModel(item)}</div>);
                    } else {
                        return this.infoFunctionModel(value[0]);
                    }
                },
            },
            {
                dataIndex: 'operateBefore',
                title: locales.get('models-fy6m7pum2wa'),
                render: (value) => {
                    let obj = JSON.parse(value);
                    if (value === '{}') return;
                    let str = value.split(':')[0].split('"')[1];
                    switch (str) {
                        case 'priority':
                            return this.checkMentionmissionPriorityModel.find((ite) => ite.value === obj.priority).text;
                        case 'status':
                            return this.missionStatusModel.find((ite) => ite.value === obj.status).text;
                        case 'name':
                            return (
                                <div style={{ width: '100px' }} className="ellipsis">
                                    {obj.name}
                                </div>
                            );
                        case 'type':
                            return this.missionTypeModel.find((ite) => ite.value === obj.type).text;
                        case 'target':
                            return this.missionGoalModel.find((ite) => ite.value === obj.target).text;
                        case 'leaderUserId':
                            return (
                                <div style={{ width: '100px' }} className="ellipsis">
                                    {obj.leaderUserId}
                                </div>
                            );
                        case 'deadlineTime':
                            return (
                                <div style={{ width: '100px' }} className="ellipsis">
                                    {' '}
                                    {obj.deadlineTime}
                                </div>
                            );
                        case 'description':
                            return (
                                <div style={{ width: '100px' }} className="ellipsis">
                                    {obj.description && obj.description.replace('<p>', '').replace('</p>', '')}
                                </div>
                            );
                        case 'ccs':
                            return (
                                <div style={{ width: '100px' }} className="ellipsis">
                                    {obj.ccs && obj.ccs.replace('[', '').replace(']', '')}
                                </div>
                            );
                        default:
                            break;
                    }
                },
            },
            {
                dataIndex: 'operateAfter',
                title: locales.get('models-u0ednvjw9s'),
                render: (value, record) => {
                    let obj = JSON.parse(value);
                    let str = value.split(':')[0].split('"')[1];
                    if (record.operateFields.length > 1) {
                        let arr = record.operateFields.map((ite) => {
                            switch (ite) {
                                case 'priority':
                                    return this.missionPriorityModel.find((e) => e.value === obj.priority).text;
                                case 'status':
                                    return this.missionStatusModel.find((e) => e.value === obj.status).text;
                                case 'name':
                                    return (
                                        <div style={{ width: '100px' }} title={obj.name} className="ellipsis">
                                            {obj.name}
                                        </div>
                                    );
                                case 'type':
                                    return this.missionTypeModel.find((e) => e.value === obj.type).text;
                                case 'target':
                                    return this.missionGoalModel.find((e) => e.value === obj.target).text;
                                case 'leaderUserId':
                                    return (
                                        <div style={{ width: '100px' }} className="ellipsis">
                                            {obj.leaderUserId}
                                        </div>
                                    );
                                case 'deadlineTime':
                                    return (
                                        <div style={{ width: '100px' }} className="ellipsis" title={obj.deadlineTime}>
                                            {' '}
                                            {obj.deadlineTime}
                                        </div>
                                    );
                                case 'description':
                                    return (
                                        <div
                                            style={{ width: '100px' }}
                                            title={
                                                obj.description &&
                                                obj.description.replace('<p>', '').replace('</p>', '')
                                            }
                                            className="ellipsis"
                                        >
                                            {obj.description.replace('<p>', '').replace('</p>', '')}
                                        </div>
                                    );
                                case 'ccs':
                                    return (
                                        <div style={{ width: '100px' }} className="ellipsis">
                                            {obj.ccs && obj.ccs.replace('[', '').replace(']', '')}
                                        </div>
                                    );
                                default:
                                    return null;
                            }
                        });
                        return arr.map((item) => <div>{item}</div>);
                    } else {
                        switch (str) {
                            case 'priority':
                                return this.missionPriorityModel.find((e) => e.value === obj.priority).text;
                            case 'status':
                                return this.missionStatusModel.find((e) => e.value === obj.status).text;
                            case 'name':
                                return (
                                    <div style={{ width: '100px' }} className="ellipsis">
                                        {obj.name}
                                    </div>
                                );
                            case 'type':
                                return this.missionTypeModel.find((e) => e.value === obj.type).text;
                            case 'target':
                                return this.missionGoalModel.find((e) => e.value === obj.target).text;
                            case 'leaderUserId':
                                return (
                                    <div style={{ width: '100px' }} className="ellipsis">
                                        {obj.leaderUserId}
                                    </div>
                                );
                            case 'deadlineTime':
                                return (
                                    <div style={{ width: '100px' }} className="ellipsis">
                                        {' '}
                                        {obj.deadlineTime}
                                    </div>
                                );
                            case 'description':
                                return (
                                    <div style={{ width: '100px' }} className="ellipsis">
                                        {obj.description && obj.description.replace('<p>', '').replace('</p>', '')}
                                    </div>
                                );
                            case 'ccs':
                                return (
                                    <div style={{ width: '100px' }} className="ellipsis">
                                        {obj.ccs && obj.ccs.replace('[', '').replace(']', '')}
                                    </div>
                                );
                            default:
                                break;
                        }
                    }
                },
            },
        ]);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            drawerData: nextProps.drawerData,
            mentionIds: nextProps.mentionIds,
            logsData: nextProps.logsData,
            id: nextProps.id,
            showYuId: nextProps.showYuId,
        });
    }

    onToolbarClick(item) {
        deleteJSON(`/comments/${item.id}`).always((res) => {
            this.props.reloading();
        });
    }

    // tab页切换
    onTabClickChange = (key) => {
        if (isEqual(key, 1)) {
            this.getTabOne();
        } else {
            this.getTabTwo();
        }
    };
    getTabOne(params) {
        const { id, drawerData } = this.state;
        getJSON(`/comments/task/${id}`).always((res) =>
            this.setState({
                drawerData: {
                    ...drawerData,
                    comments: res.data,
                },
            })
        );
    }
    getTabTwo(params) {
        //获取列表接口
        const { conditionsTab, id } = this.state;
        this.setState({
            loadingTab: true,
            conditionsTab: extend(conditionsTab, params),
        });
        getJSON(`/logs/${id}`, conditionsTab).always((res) => {
            extend(conditionsTab, res.paging);
            this.setState({
                loadingTab: false,
                logsData: res.data,
            });
        });
    }

    confirmStatus() {
        const { id } = this.state;
        putJSON(`/tasks/status/${id}`).always((res) => {
            this.props.reloading();
        });
    }

    onTabTableChange = (pagination) => {
        this.getTabTwo({
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
        });
    };
    handleReset = (e) => {
        this.props.form.resetFields();
    };

    checkMention = (rule, value, callback) => {
        const { mentionIds } = this.state;
        let mentionSelect = getMentions(value);
        let newMentions = mentionSelect.map((e) => {
            return { id: mentionIds.find((ite) => ite.name === e.value).id, name: e.value };
        });
        this.setState(
            {
                mentions: newMentions,
            },
            () => {
                callback();
            }
        );
    };

    handleSubmit = () => {
        const { id, mentions } = this.state;
        this.props.form.validateFields((errors, values) => {
            if (errors) {
                return;
            }
            let content = JSON.stringify({ comment: values.mention, atItems: mentions });
            postJSON(`/comments`, { content: content, taskId: id }).always((res) => this.props.reloading());
        });
        this.handleReset();
    };

    handleSaveCheckItems = (item) => {
        const { id } = this.state;
        this.setState({
            saveCheckItemsLoading: true,
        });
        item.name.length > 0 &&
            postJSON(`/checkItems`, {
                taskId: id,
                name: item.name,
                handlerId: item.handler.id,
                status: item.status || 0,
                deadlineTime: item.deadlineTime || '',
            }).always((res) => {
                this.props.reloading();
                this.setState({
                    saveCheckItemsLoading: false,
                });
            });
        this.setState({
            isCreate: false,
        });
    };

    handleResetCheckItems = (e, item) => {
        const { checkItems } = this.state.drawerData;
        this.setState({
            isCreate: false,
            checkItems: checkItems.splice(checkItems.length - 1, 1),
        });
    };

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };
    editorChange = (value) => {
        this.setState({
            drawerData: {
                ...this.state.drawerData,
                description: value,
            },
        });
    };

    nameEdit = (value) => {
        this.setState({
            drawerData: {
                ...this.state.drawerData,
                name: value,
            },
        });
    };

    userEditorChange = (value) => {
        this.setState(
            {
                drawerData: {
                    ...this.state.drawerData,
                    ccs: value,
                },
            },
            () => {
                this.props.reloading();
            }
        );
    };

    saveTitle = (e) => {
        const { id } = this.state;
        const { name } = this.state.drawerData;
        putJSON(`/tasks/${id}`, { id: id, name: name }).always((res) => this.props.reloading());
    };
    saveDescription = (e) => {
        const { id } = this.state;
        const { description } = this.state.drawerData;
        putJSON(`/tasks/${id}`, { id: id, description: description }).always((res) => this.props.reloading());
    };

    deleteTask = () => {
        const { id } = this.state;
        deleteJSON(`/tasks/${id}`).always((res) => {
            if (this.props.reloading) {
                this.props.reloading();
            }
            this.onClose();
        });
    };

    getSuggestionContainer = () => this.popover.getPopupDomNode();

    componentDidMount() {
        getJSON(`/tasks/users`).always((res) => {
            this.setState({
                ccIds: res.data,
            });
        });
    }
    setImg = () => {
        let div = document.getElementsByClassName('description-box')[0];
        let img = div && div.getElementsByTagName('img');
        if (img) {
            let arr = Array.from(img);
            arr.forEach((item) => {
                item.style.maxWidth = '500px';
                item.style.height = 'auto';
            });
        }
    };
    previewClick = (data, i) => {
        let itemList = data;
        this.setState({
            previewBoxVisable: true,
            itemListIndex: i,
            itemList,
        });
    };
    handleCancel = () => {
        this.setState({
            previewBoxVisable: false,
            itemListIndex: 0,
            itemList: [],
        });
    };
    render() {
        let {
            drawerData,
            ccIds,
            loadingTab,
            conditionsTab,
            isDescribeEdit,
            isCopyUserEdit,
            isGoalEdit,
            isStatusEdit,
            isPriorityEditor,
            isLeaderEditor,
            isEndTimeEditor,
            mentionIds,
            logsData,
            allLeaders,
            isTitleEdit,
            isCommentEdit,
            mode,
            id,
            showYuId,
            previewBoxVisable,
            itemListIndex,
            itemList,
            saveCheckItemsLoading,
        } = this.state;
        let status =
            find(this.missionStatusModel, {
                value: parseInt(drawerData.status),
            }) || {};

        let priority =
            find(this.missionPriorityModel, {
                value: parseInt(drawerData.priority),
            }) || {};

        const {
            form: { getFieldDecorator },
            visible,
            isYuJing,
            recordReload,
            zIndex = 1000,
        } = this.props;
        const { menuList } = this.appContext;

        const isPermitMissionComment = !isYuJing && isPermission(menuList, 'mission:comment', 3);
        const isPermitAlarmComment = isYuJing && isPermission(menuList, 'earlywarning:record:comment', 3);
        const isPermitComment = isPermitMissionComment || isPermitAlarmComment;
        const isPermitMissionAddCheckItem = !isYuJing && isPermission(menuList, 'mission:addcheckitem', 3);
        const isPermitAlarmAddCheckItem = isYuJing && isPermission(menuList, 'earlywarning:record:addcheckitem', 3);
        const isPermitAddCheckItem = isPermitMissionAddCheckItem || isPermitAlarmAddCheckItem;

        const isPermitEditAlarmMission = isYuJing && isPermission(menuList, 'earlywarning:record:edittask', 3);
        const isPermitEditOnlyMission = !isYuJing && isPermission(menuList, 'mission:edit', 3);
        const isPermitEditMissonTask = isPermitEditAlarmMission || isPermitEditOnlyMission;

        this.setImg();
        return (
            <Drawer
                className="mission-drawer-box"
                width={'auto'}
                title={
                    <div className="drawer-title" style={{ width: isYuJing ? 'auto' : '660px' }}>
                        <div className="drawer-title-text">{locales.get('components-mission-hv0ljjaqz2i')}</div>
                        <div className="drawer-title-icon">
                            <Popconfirm
                                title={locales.get('components-alarm-type-z4uhfy8nktm')}
                                placement="left"
                                onConfirm={this.deleteTask}
                            >
                                <Icon type="shanchu"></Icon>
                            </Popconfirm>
                            <Icon className="closeDrawer" type="guanbiX" onClick={this.onClose}></Icon>
                        </div>
                    </div>
                }
                closable={false}
                destroyOnClose
                visible={visible}
                onClose={this.onClose}
                style={{ zIndex }}
            >
                <div className="mission-processing-box">
                    <div className="processing-left" style={{ marginBottom: !isYuJing && !drawerData.status ? 56 : 0 }}>
                        <div className="processing-left-title">
                            <div className="processing-left-title-item">
                                {locales.get('components-mission-4o4iicqngqv')}
                                {drawerData.code}
                            </div>
                            <div className="processing-left-title-item">
                                {drawerData.creator ? drawerData.creator.name : ''}
                                {locales.get('components-mission-j2pvrco03rd')}
                                {drawerData.createTime}
                            </div>
                        </div>
                        <div className="task-title">
                            <div className="task-title-icon">
                                <Icon style={{ color: 'white' }} type="yujingzhongleibeifen"></Icon>
                            </div>
                            {!isTitleEdit ? (
                                <h2 title={drawerData.name} className="title ellipsis">
                                    {drawerData.name}
                                </h2>
                            ) : (
                                <h2 style={{ maxWidth: '400px', display: 'inline-block', margin: '0 10px' }}>
                                    <Input.TextArea
                                        size="large"
                                        max={100}
                                        maxLength={100}
                                        defaultValue={drawerData.name}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.nameEdit(value);
                                        }}
                                        onBlur={() => {
                                            this.setState({
                                                isTitleEdit: false,
                                            });
                                            this.saveTitle();
                                        }}
                                    />
                                </h2>
                            )}
                            <Button
                                type="link"
                                className="edit-wrapper"
                                onClick={() => {
                                    this.setState({
                                        isTitleEdit: true,
                                    });
                                }}
                            >
                                <Icon type="bianjibeifen" className="edit-name"></Icon>
                            </Button>
                        </div>
                        <div className="processing-left-info">
                            <div className="info-title">
                                <div className="info-title-item">
                                    <div className="info-title-item-span">
                                        {locales.get('components-mission-510c1eqap6q')}
                                    </div>
                                    {!isGoalEdit ? (
                                        <div
                                            className="cursor-box"
                                            onClick={() => {
                                                this.setState({
                                                    isGoalEdit: true,
                                                });
                                            }}
                                        >
                                            {
                                                (
                                                    find(this.missionGoalModel, {
                                                        value: parseInt(drawerData.target),
                                                    }) || {}
                                                ).text
                                            }
                                        </div>
                                    ) : (
                                        <Select
                                            defaultOpen
                                            dropdownMatchSelectWidth={false}
                                            defaultValue={
                                                (
                                                    find(this.missionGoalModel, {
                                                        value: parseInt(drawerData.target),
                                                    }) || {}
                                                ).text
                                            }
                                            className="tree-box"
                                            onChange={(value) => {
                                                this.setState({
                                                    drawerData: {
                                                        ...this.state.drawerData,
                                                        target: value,
                                                    },
                                                    isGoalEdit: false,
                                                });
                                                putJSON(`/tasks/${id}`, { id: id, target: value }).always((res) =>
                                                    this.props.reloading()
                                                );
                                            }}
                                            onBlur={() => {
                                                this.setState({
                                                    isGoalEdit: false,
                                                });
                                            }}
                                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                        >
                                            {this.missionGoalModel.map((item) => (
                                                <Select.Option value={item.value}>{item.text}</Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </div>
                                <Divider type="vertical" />
                                <div className="info-title-item">
                                    <div className="info-title-item-span">
                                        {locales.get('components-mission-y44gx26s2qi')}
                                    </div>
                                    {!isStatusEdit ? (
                                        <div
                                            className="cursor-box"
                                            onClick={() => {
                                                this.setState({
                                                    isStatusEdit: true,
                                                });
                                            }}
                                        >
                                            {status.code === 'unfinished' ? (
                                                <div className="mission-status">{status.text}</div>
                                            ) : (
                                                <div className="mission-yesstatus">{status.text}</div>
                                            )}
                                        </div>
                                    ) : (
                                        <Select
                                            defaultOpen
                                            defaultValue={status.text}
                                            dropdownMatchSelectWidth={false}
                                            className="tree-box"
                                            onChange={(value) => {
                                                this.setState({
                                                    drawerData: {
                                                        ...this.state.drawerData,
                                                        status: value,
                                                    },
                                                    isStatusEdit: false,
                                                });
                                                putJSON(`/tasks/${id}`, { id: id, status: value }).always((res) =>
                                                    this.props.reloading()
                                                );
                                            }}
                                            onBlur={() => {
                                                this.setState({
                                                    isStatusEdit: false,
                                                });
                                            }}
                                        >
                                            {this.missionStatusModel.map((item) => (
                                                <Select.Option value={item.value}>{item.text}</Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </div>
                                <Divider type="vertical" />
                                <div className="info-title-item">
                                    <div className="info-title-item-span">
                                        {locales.get('components-mission-2wvjydylcnf')}
                                    </div>
                                    {!isPriorityEditor ? (
                                        <div
                                            className="cursor-box"
                                            onClick={() => {
                                                this.setState({
                                                    isPriorityEditor: true,
                                                });
                                            }}
                                        >
                                            {}
                                            <span
                                                className="priority-box"
                                                style={{
                                                    backgroundColor: priority.color,
                                                }}
                                            ></span>
                                            <span style={{ marginLeft: '6px' }}>{priority.text}</span>
                                        </div>
                                    ) : (
                                        <Select
                                            defaultOpen
                                            defaultValue={priority.text}
                                            dropdownMatchSelectWidth={false}
                                            className="tree-box"
                                            onChange={(value) => {
                                                this.setState({
                                                    drawerData: {
                                                        ...this.state.drawerData,
                                                        priority: value,
                                                    },
                                                    isPriorityEditor: false,
                                                });
                                                putJSON(`/tasks/${id}`, { id: id, priority: value }).always((res) =>
                                                    this.props.reloading()
                                                );
                                            }}
                                            onBlur={() => {
                                                this.setState({
                                                    isPriorityEditor: false,
                                                });
                                            }}
                                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                        >
                                            {this.missionPriorityModel.map((item) => (
                                                <Select.Option value={item.value}>{item.text}</Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </div>
                                <Divider type="vertical" />
                                <div className="info-title-item">
                                    <div className="info-title-item-span">
                                        {locales.get('components-mission-3mjtdll3d98')}
                                    </div>
                                    {!isLeaderEditor ? (
                                        <div
                                            className="cursor-box"
                                            onClick={() => {
                                                this.setState({
                                                    isLeaderEditor: true,
                                                });
                                            }}
                                        >
                                            {drawerData.leader && drawerData.leader.name}
                                        </div>
                                    ) : (
                                        <Select
                                            defaultOpen
                                            defaultValue={drawerData.leader.name}
                                            dropdownMatchSelectWidth={false}
                                            className="tree-box"
                                            onChange={(value) => {
                                                this.setState({
                                                    drawerData: {
                                                        ...this.state.drawerData,
                                                        leader: {
                                                            id: value,
                                                            name: allLeaders.filter((e) => {
                                                                return e.id === value;
                                                            }).name,
                                                        },
                                                    },
                                                    isLeaderEditor: false,
                                                });
                                                putJSON(`/tasks/${id}`, { id: id, leaderUserId: value }).always(
                                                    (res) => {
                                                        this.props.reloading();
                                                    }
                                                );
                                            }}
                                            onBlur={() => {
                                                this.setState({
                                                    isLeaderEditor: false,
                                                });
                                            }}
                                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                        >
                                            {ccIds.map((item) => (
                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </div>
                                <Divider type="vertical" />
                                <div className="info-title-item">
                                    <div className="info-title-item-span">
                                        {locales.get('components-mission-ujcnaf6wu6l')}
                                    </div>
                                    {!isEndTimeEditor ? (
                                        <div
                                            className="cursor-box"
                                            onClick={() => {
                                                this.setState({
                                                    isEndTimeEditor: true,
                                                });
                                            }}
                                        >
                                            {drawerData.deadlineTime}
                                        </div>
                                    ) : (
                                        <div className="date-picker-box">
                                            <AntdDatePicker
                                                defaultValue={moment(drawerData.deadlineTime)}
                                                mode={mode}
                                                showTime={{ format: 'HH:mm' }}
                                                style={{ width: '180px' }}
                                                onPanelChange={(moment, item) => {
                                                    this.setState({ mode: item });
                                                }}
                                                onOpenChange={(open) => {
                                                    if (open) {
                                                        this.setState({ mode: 'date' });
                                                    }
                                                }}
                                                format={DATETIME_FORMAT}
                                                onOk={() => {
                                                    this.setState({
                                                        isEndTimeEditor: false,
                                                    });
                                                }}
                                                onChange={(date, dateString) => {
                                                    this.setState({
                                                        drawerData: {
                                                            ...this.state.drawerData,
                                                            deadlineTime: dateString,
                                                        },
                                                    });
                                                    putJSON(`/tasks/${id}`, {
                                                        id: id,
                                                        deadlineTime: dateString,
                                                    }).always((res) => this.props.reloading());
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="info-describe">
                                <Icon type="renyuanbeifen" style={{ color: 'rgba(207, 208, 212, 1)' }} />
                                <span className="left">{locales.get('components-mission-pydfsgxkaa')}</span>
                                {!isCopyUserEdit ? (
                                    <div style={{ width: 500, display: 'flex', alignItems: 'center' }}>
                                        <span
                                            title={(drawerData.ccs || [])
                                                .map((item, index) => {
                                                    return item.user && item.user.name;
                                                })
                                                .toString()}
                                            style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}
                                        >
                                            {(drawerData.ccs || []).map((item, index) => {
                                                return (
                                                    <span className="right user-item" key={index}>
                                                        {item.user && item.user.name}
                                                    </span>
                                                );
                                            })}
                                        </span>
                                        <Button
                                            type="link"
                                            className="edit-wrapper"
                                            onClick={() => {
                                                this.setState({
                                                    isCopyUserEdit: true,
                                                });
                                            }}
                                        >
                                            <Icon type="bianjibeifen" className="plus-box"></Icon>
                                        </Button>
                                    </div>
                                ) : (
                                    <div>
                                        <UsersSelect
                                            defaultOpen
                                            showSearch
                                            mode={'multiple'}
                                            dropdownMatchSelectWidth={false}
                                            defaultValue={drawerData.ccs.map((e) => {
                                                return e.user.id;
                                            })}
                                            className="tree-box"
                                            minWidth="200px"
                                            onChange={(value) => {
                                                putJSON(`/tasks/${id}`, {
                                                    id: id,
                                                    ccs: value.map((e) => {
                                                        return { userId: ccIds.find((item) => item.id === e).id };
                                                    }),
                                                }).always((res) => this.props.reloading());
                                            }}
                                            onBlur={() => {
                                                this.setState({
                                                    isCopyUserEdit: false,
                                                });
                                            }}
                                        >
                                            {ccIds.map((item) => (
                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                            ))}
                                        </UsersSelect>
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    isCopyUserEdit: false,
                                                });
                                            }}
                                        >
                                            {locales.get('components-common-integrated-table-op96u9kmem')}
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className="info-describe">
                                <Icon type="zhouqibeifen" style={{ color: 'rgba(207, 208, 212, 1)' }} />
                                <span className="left">{locales.get('components-mission-o9e4omkg1q')}</span>
                                {!isDescribeEdit ? (
                                    <div
                                        onClick={(e) => {
                                            if (!isEqual(e.target.localName, 'img')) {
                                                return;
                                            }
                                            let data = [
                                                {
                                                    imgSrc: e.target.src,
                                                },
                                            ];
                                            this.previewClick(data, 0);
                                        }}
                                    >
                                        <span
                                            className="right description-box"
                                            dangerouslySetInnerHTML={{
                                                __html: drawerData.description,
                                            }}
                                        />
                                        {isPermitEditMissonTask ? (
                                            <div>
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        this.setState({
                                                            isDescribeEdit: true,
                                                        });
                                                    }}
                                                >
                                                    {locales.get('components-alarm-records-qglhwr0awhp')}
                                                </Button>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                ) : (
                                    <div className="editor-box">
                                        <Editor
                                            defaultValue={drawerData.description}
                                            config={{
                                                toolbar: [
                                                    'bold italic underline alignleft aligncenter alignright link image fontsizeselect fontselect',
                                                ],
                                                plugins: 'autoresize',
                                                // autoresize_bottom_margin: 20
                                            }}
                                            onChange={(value) => {
                                                this.editorChange(value);
                                            }}
                                        />
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                this.setState({
                                                    isDescribeEdit: false,
                                                });
                                                this.saveDescription();
                                            }}
                                        >
                                            {locales.get('components-alarm-records-03ews9u1ytdt')}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    isDescribeEdit: false,
                                                });
                                            }}
                                        >
                                            {locales.get('components-alarm-records-euc67z93qxl')}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="processing-left-info">
                            <div className="info-describe">
                                <Icon type="tongzhicishubeifen" style={{ color: 'rgba(207, 208, 212, 1)' }} />
                                <span className="left">{locales.get('components-mission-53rtp039h18')}</span>
                                <div className="check-box">
                                    <div>
                                        {(drawerData.checkItems || []).map((item, index) => {
                                            return (
                                                <Fragment>
                                                    <div key={index} className="check-box-item">
                                                        <CheckBox
                                                            className="check-box-item-check"
                                                            checked={item.status === 1}
                                                            disabled={!item.id}
                                                            onChange={(e) => {
                                                                let statusValue = e.target.checked ? 1 : 0;
                                                                this.setState({
                                                                    drawerData: {
                                                                        ...this.state.drawerData,
                                                                        checkItems: [
                                                                            ...drawerData.checkItems.slice(0, index),
                                                                            {
                                                                                ...drawerData.checkItems[index],
                                                                                status: statusValue,
                                                                            },
                                                                            ...drawerData.checkItems.slice(index + 1),
                                                                        ],
                                                                    },
                                                                });
                                                                item.id &&
                                                                    putJSON(`/checkItems/${item.id}`, {
                                                                        id: item.id,
                                                                        status: statusValue,
                                                                        taskId: id,
                                                                    });
                                                            }}
                                                        />
                                                        {item.flag && (
                                                            <Input.TextArea
                                                                className="check-box-item-input"
                                                                placeholder={locales.get(
                                                                    'components-mission-ili4fxzlal9'
                                                                )}
                                                                value={item.name}
                                                                style={{ marginRight: 8 }}
                                                                onFocus={() => {
                                                                    this.setState({
                                                                        isCheckEdit: true,
                                                                    });
                                                                }}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    this.setState({
                                                                        drawerData: {
                                                                            ...this.state.drawerData,
                                                                            checkItems: [
                                                                                ...drawerData.checkItems.slice(
                                                                                    0,
                                                                                    index
                                                                                ),
                                                                                {
                                                                                    ...drawerData.checkItems[index],
                                                                                    name: value,
                                                                                },
                                                                                ...drawerData.checkItems.slice(
                                                                                    index + 1
                                                                                ),
                                                                            ],
                                                                        },
                                                                    });
                                                                }}
                                                                onBlur={() => {
                                                                    const checkItem =
                                                                        drawerData.checkItems[index] || {};
                                                                    item.id &&
                                                                        this.setState({
                                                                            drawerData: {
                                                                                ...this.state.drawerData,
                                                                                checkItems: [
                                                                                    ...drawerData.checkItems.slice(
                                                                                        0,
                                                                                        index
                                                                                    ),
                                                                                    {
                                                                                        ...drawerData.checkItems[index],
                                                                                        flag: false,
                                                                                    },
                                                                                    ...drawerData.checkItems.slice(
                                                                                        index + 1
                                                                                    ),
                                                                                ],
                                                                            },
                                                                        });
                                                                    item.id &&
                                                                        putJSON(`/checkItems/${item.id}`, {
                                                                            id: item.id,
                                                                            name: checkItem.name,
                                                                            taskId: id,
                                                                        });
                                                                }}
                                                            />
                                                        )}
                                                        {!item.flag && (
                                                            <span
                                                                style={{
                                                                    maxWidth: 280,
                                                                    minWidth: item.id ? 10 : 280,
                                                                    marginRight: 8,
                                                                    textDecoration:
                                                                        item.status === 1 ? 'line-through' : 'none',
                                                                }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        drawerData: {
                                                                            ...this.state.drawerData,
                                                                            checkItems: [
                                                                                ...drawerData.checkItems.slice(
                                                                                    0,
                                                                                    index
                                                                                ),
                                                                                {
                                                                                    ...drawerData.checkItems[index],
                                                                                    flag: true,
                                                                                },
                                                                                ...drawerData.checkItems.slice(
                                                                                    index + 1
                                                                                ),
                                                                            ],
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                {drawerData.checkItems[index].name}
                                                            </span>
                                                        )}
                                                        <Popover
                                                            overlayClassName="popover-mission-name"
                                                            trigger="click"
                                                            content={
                                                                <div className="popover-contain">
                                                                    <div className="popover-contain-item">
                                                                        <span>
                                                                            {locales.get(
                                                                                'components-mission-cbs9p7grbn7'
                                                                            )}
                                                                        </span>
                                                                        <UsersSelect
                                                                            defaultOpen
                                                                            style={{ width: '280px' }}
                                                                            placeholder={locales.get(
                                                                                'components-mission-olbgkdnx6n'
                                                                            )}
                                                                            onChange={(value) => {
                                                                                this.setState({
                                                                                    drawerData: {
                                                                                        ...this.state.drawerData,
                                                                                        checkItems: [
                                                                                            ...drawerData.checkItems.slice(
                                                                                                0,
                                                                                                index
                                                                                            ),
                                                                                            {
                                                                                                ...drawerData
                                                                                                    .checkItems[index],
                                                                                                handler: {
                                                                                                    id: value
                                                                                                        ? ccIds.find(
                                                                                                              (e) =>
                                                                                                                  e.id ===
                                                                                                                  value
                                                                                                          ).id
                                                                                                        : '',
                                                                                                    name: value
                                                                                                        ? ccIds.find(
                                                                                                              (e) =>
                                                                                                                  e.id ===
                                                                                                                  value
                                                                                                          ).name
                                                                                                        : '',
                                                                                                },
                                                                                            },
                                                                                            ...drawerData.checkItems.slice(
                                                                                                index + 1
                                                                                            ),
                                                                                        ],
                                                                                    },
                                                                                });
                                                                                item.id &&
                                                                                    putJSON(`/checkItems/${item.id}`, {
                                                                                        id: item.id,
                                                                                        taskId: id,
                                                                                        handlerId: value,
                                                                                    }).always((res) =>
                                                                                        this.props.reloading()
                                                                                    );
                                                                            }}
                                                                            onBlur={() => {
                                                                                this.setState({
                                                                                    isCopyUserEdit: false,
                                                                                });
                                                                            }}
                                                                        >
                                                                            {ccIds.map((ite) => (
                                                                                <Select.Option value={ite.id}>
                                                                                    {ite.name}
                                                                                </Select.Option>
                                                                            ))}
                                                                        </UsersSelect>
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                            {item.handler && item.handler.name ? (
                                                                <Button
                                                                    type="link"
                                                                    className="check-item-form-quicklink"
                                                                >
                                                                    <span
                                                                        title={item.handler && item.handler.name}
                                                                        className="due"
                                                                    >
                                                                        {item.handler && item.handler.name}
                                                                    </span>
                                                                </Button>
                                                            ) : (
                                                                <Icon
                                                                    className="check-box-item-icon"
                                                                    type="renyuanbeifen"
                                                                />
                                                            )}
                                                        </Popover>
                                                        <Popover
                                                            overlayClassName="popover-mission-date"
                                                            trigger="click"
                                                            content={
                                                                <div className="popover-contain">
                                                                    <div className="popover-contain-item">
                                                                        <span>
                                                                            {locales.get(
                                                                                'components-mission-qgkpc2445jg'
                                                                            )}
                                                                        </span>
                                                                        <AntdDatePicker
                                                                            className="popover-contain-item-datepicker"
                                                                            mode={mode}
                                                                            showTime={{ format: 'HH:mm' }}
                                                                            defaultValue={item.time}
                                                                            style={{ width: '280px' }}
                                                                            onPanelChange={(moment, mode) => {
                                                                                this.setState({ mode });
                                                                            }}
                                                                            onOpenChange={(open) => {
                                                                                if (open) {
                                                                                    this.setState({ mode: 'date' });
                                                                                }
                                                                            }}
                                                                            onChange={(value) => {
                                                                                const time = value.format(
                                                                                    'YYYY-MM-DD HH:mm'
                                                                                );
                                                                                this.setState({
                                                                                    drawerData: {
                                                                                        ...this.state.drawerData,
                                                                                        checkItems: [
                                                                                            ...drawerData.checkItems.slice(
                                                                                                0,
                                                                                                index
                                                                                            ),
                                                                                            {
                                                                                                ...drawerData
                                                                                                    .checkItems[index],
                                                                                                deadlineTime: time,
                                                                                            },
                                                                                            ...drawerData.checkItems.slice(
                                                                                                index + 1
                                                                                            ),
                                                                                        ],
                                                                                    },
                                                                                });
                                                                                item.id &&
                                                                                    putJSON(`/checkItems/${item.id}`, {
                                                                                        id: item.id,
                                                                                        deadlineTime: time,
                                                                                        taskId: id,
                                                                                    }).always((res) =>
                                                                                        this.props.reloading()
                                                                                    );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                            {item.deadlineTime ? (
                                                                <Button
                                                                    type="link"
                                                                    className="check-item-form-quicklink"
                                                                    style={{ maxWidth: '110px' }}
                                                                >
                                                                    <span className="due">
                                                                        {item.deadlineTime.slice(0, 10)}
                                                                    </span>
                                                                </Button>
                                                            ) : (
                                                                <Icon
                                                                    className="check-box-item-icon"
                                                                    type="zhouqibeifen"
                                                                />
                                                            )}
                                                        </Popover>
                                                        <div className="delete-box">
                                                            {item.id ? (
                                                                <Icon
                                                                    style={{ position: 'relative', left: '-24px' }}
                                                                    className="check-box-item-icon"
                                                                    type="circle-delete"
                                                                    onClick={() => {
                                                                        deleteJSON(
                                                                            `/checkItems/${item.id}`
                                                                        ).always((res) => this.props.reloading());
                                                                    }}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                    {!item.id ? (
                                                        <div style={{ margin: '10px 0' }}>
                                                            <Button
                                                                type="primary"
                                                                loading={saveCheckItemsLoading}
                                                                onClick={(e) => {
                                                                    this.handleSaveCheckItems(item);
                                                                }}
                                                            >
                                                                {locales.get('components-alarm-records-03ews9u1ytdt')}
                                                            </Button>
                                                               
                                                            <Button
                                                                onClick={(e) => {
                                                                    this.handleResetCheckItems(e, item);
                                                                }}
                                                            >
                                                                {locales.get('components-alarm-records-euc67z93qxl')}
                                                            </Button>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                    </div>
                                    {isPermitAddCheckItem && (
                                        <Button
                                            type="link"
                                            style={{ marginTop: -10, display: 'inline-block' }}
                                            onClick={() => {
                                                if (
                                                    drawerData.checkItems.length > 0 &&
                                                    !drawerData.checkItems[drawerData.checkItems.length - 1].id
                                                ) {
                                                    return;
                                                } else {
                                                    this.setState({
                                                        isCreate: true,
                                                        drawerData: {
                                                            ...this.state.drawerData,
                                                            checkItems: [
                                                                ...this.state.drawerData.checkItems,
                                                                {
                                                                    handler: {
                                                                        id: null,
                                                                        name: '',
                                                                    },
                                                                    deadlineTime: '',
                                                                    status: 0,
                                                                    name: '',
                                                                    flag: false,
                                                                },
                                                            ],
                                                        },
                                                    });
                                                }
                                            }}
                                        >
                                            <Icon
                                                antd
                                                type="plus-circle"
                                                style={{ marginRight: 8 }}
                                                className="plus-box"
                                            />
                                            {locales.get('components-mission-g1frp4huskh')}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="bottom-info">
                            <Tabs
                                defaultActiveKey="1"
                                onTabClick={(key) => {
                                    this.onTabClickChange(key);
                                }}
                            >
                                {isPermitComment && (
                                    <Tabs.TabPane
                                        tab={<span>{locales.get('components-layout-inform-aon3eefmwi')}</span>}
                                        key="1"
                                    >
                                        <div className="comment-box">
                                            <Comment
                                                avatar={
                                                    <Avatar
                                                        style={{ marginTop: 7 }}
                                                        src={this.appContext.user.headPortraitUrl || ''}
                                                        alt="Han Solo"
                                                    />
                                                }
                                                content={
                                                    <div>
                                                        <Form layout="horizontal">
                                                            <Form.Item>
                                                                {getFieldDecorator('mention', {
                                                                    initialValue: '',
                                                                    rules: [
                                                                        {
                                                                            validator: this.checkMention,
                                                                        },
                                                                    ],
                                                                })(
                                                                    <Mentions
                                                                        onSelect={(value, prefix) => {}}
                                                                        onFocus={() => {
                                                                            this.setState({
                                                                                isCommentEdit: true,
                                                                            });
                                                                        }}
                                                                        placeholder={locales.get(
                                                                            'components-mission-iy3aj2es6a'
                                                                        )}
                                                                    >
                                                                        {mentionIds.map((item, index) => {
                                                                            return (
                                                                                <Option
                                                                                    value={item.name}
                                                                                    item={item}
                                                                                    key={index}
                                                                                >
                                                                                    {item.name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Mentions>
                                                                )}
                                                            </Form.Item>
                                                            {isCommentEdit && (
                                                                <Form.Item className="comment-box-btnright">
                                                                    <Button
                                                                        type="primary"
                                                                        onClick={() => {
                                                                            this.handleSubmit();
                                                                            this.setState({
                                                                                isCommentEdit: false,
                                                                            });
                                                                        }}
                                                                    >
                                                                        {locales.get(
                                                                            'components-alarm-records-03ews9u1ytdt'
                                                                        )}
                                                                    </Button>
                                                                       
                                                                    <Button
                                                                        onClick={(e) => {
                                                                            this.handleReset(e);
                                                                            this.setState({
                                                                                isCommentEdit: false,
                                                                            });
                                                                        }}
                                                                    >
                                                                        {locales.get(
                                                                            'components-alarm-records-euc67z93qxl'
                                                                        )}
                                                                    </Button>
                                                                </Form.Item>
                                                            )}
                                                        </Form>
                                                    </div>
                                                }
                                            />
                                            <List
                                                dataSource={drawerData.comments}
                                                locale={{
                                                    emptyText: (
                                                        <div className="no-data-box">
                                                            {locales.get('components-mission-plkmlat1ji')}
                                                        </div>
                                                    ),
                                                }}
                                                header={locales.get('components-layout-inform-aon3eefmwi')}
                                                itemLayout="horizontal"
                                                renderItem={(item) => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            avatar={<Avatar src={item.creator.avatar} />}
                                                            title={item.creator.name}
                                                            description={JSON.parse(item.content).comment}
                                                        />
                                                        <div className="comment-right">
                                                            <div className="comment-time">{item.createTime}</div>
                                                            <Popconfirm
                                                                title={locales.get('components-alarm-type-z4uhfy8nktm')}
                                                                onConfirm={this.onToolbarClick.bind(this, item)}
                                                            >
                                                                <Icon type="shanchu" />
                                                            </Popconfirm>
                                                        </div>
                                                    </List.Item>
                                                )}
                                            />
                                        </div>
                                    </Tabs.TabPane>
                                )}
                                <Tabs.TabPane tab={<span>{locales.get('components-mission-9naa6lbcbn')}</span>} key="2">
                                    <Table
                                        style={{
                                            marginTop: 10,
                                            marginBottom: !isYuJing && !drawerData.status ? 56 : 0,
                                        }}
                                        columns={this.controlColumnsModel}
                                        rowKey={(record) => record.id}
                                        loading={loadingTab}
                                        dataSource={logsData}
                                        pagination={conditionsTab}
                                        onChange={(pagination) => {
                                            this.onTabTableChange(pagination);
                                        }}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                        {!isYuJing && !drawerData.status && (
                            <Button type="primary" className="confirm-btn" onClick={this.confirmStatus.bind(this)}>
                                {locales.get('components-mission-759jeaath0i')}
                            </Button>
                        )}
                    </div>
                    {isYuJing && (
                        <div className="processing-right">
                            <RecordsDetails id={showYuId} recordReload={recordReload} />
                        </div>
                    )}
                </div>
                <PreviewImg
                    handleCancel={this.handleCancel}
                    previewBoxVisable={previewBoxVisable}
                    itemList={itemList}
                    itemListIndex={itemListIndex}
                ></PreviewImg>
            </Drawer>
        );
    }
}

export default componentInjection(Form.create()(Default));
