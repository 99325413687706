import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import '../styles/_basePage.scss';
import { PageHeader, Divider } from 'udesk-ui'; //antd 4.0 upgrade
const DEFAULTMINWIDTH = '768px';

export default class PageContainer extends React.Component {
    // #region defaultProps
    static defaultProps = {
        extra: null,
        title: null,
        subTitle: null,
        className: null,
        onBack: null,
        showDivider: true,
        showHeader: true,
        footer: null,
    };
    // #endregion

    // #region defaultProps
    static propTypes = {
        //float right && tool buttons
        className: PropTypes.string,
        extra: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        onBack: PropTypes.func,
        showDivider: PropTypes.bool,
        showHeader: PropTypes.bool,
        footer: PropTypes.element,
    };
    // #endregion
    // #region public function

    // #endregion

    // #region life cycle
    componentDidMount() {}
    componentWillUnmount() {}
    // #endregion

    // #region actions
    actions = {};
    // #endregion

    // #region render
    render() {
        const {
            title,
            subTitle,
            extra,
            className,
            classHeaderName,
            minWidth,
            onBack,
            showDivider,
            showHeader,
            footer,
            children,
        } = this.props;

        let showHeaderHeight = showHeader ? 59 : 0;
        let showDividerHeight = showDivider ? 17 : 0;

        // let PageContentHeight;
        // if (!showHeaderHeight && !showDividerHeight) {
        //     PageContentHeight = '100%';
        // } else {
        //     PageContentHeight = `calc(100% - ${showHeaderHeight + showDividerHeight}px)`;
        // }

        return (
            <div
                className={ClassNames('page page-layout page-container', className)}
                style={{ minWidth: minWidth || DEFAULTMINWIDTH }}
            >
                {showHeader && (
                    <PageHeader
                        className={ClassNames('page-header', classHeaderName)}
                        title={title}
                        subTitle={subTitle}
                        extra={extra}
                        onBack={onBack}
                        footer={footer}
                    ></PageHeader>
                )}

                {showDivider && <Divider style={{ marginBottom: 16 }} />}

                <div
                    className={ClassNames(
                        'page-content',
                        showHeader && showDivider
                            ? 'show-header-and-divider'
                            : showHeaderHeight
                            ? 'show-header'
                            : showDividerHeight
                            ? 'show-divider'
                            : ''
                    )}
                >
                    {children}
                </div>
            </div>
        );
    }
    // #endregion
}
