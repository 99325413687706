import { getAttr } from './base';
import find from 'lodash/find';

const permissionPrefixs = ['root', 'menu', 'button'];
/**
 * 获取有权限的菜单
 * @param {number} type - 菜单类型：1（一级菜单）、2（二级菜单）、3（功能）
 * @param {string} permission - 权限标示，无需前缀
 */
export function getPermissionMenuItem(menuList, type, permission) {
    let prefix = permissionPrefixs[type - 1];
    // let hasPrefix = permission.split(':').length > 1;

    // if (!hasPrefix) {
    permission = `${prefix}:${permission}`;
    // }
    const a = find(menuList, { type, permission });
    return a;
}

/**
 * 获取有权限的菜单名称
 * @param {array} menuList - 有权限的menuList
 * @param {number} type - 菜单类型：1（一级菜单）、2（二级菜单）、3（功能）
 * @param {string} permission - 权限标示，无需前缀
 * @param {string} defaultValue - 默认值
 */
export function getPermissionMenuItemName(menuList, type, permission, defaultValue = '') {
    return getAttr(getPermissionMenuItem(menuList, type, permission), 'name') || defaultValue;
}

/**
 * 是否授权
 * @param {array} menuList - 有权限的menuList
 * @param {*} permission - 权限标示，无需前缀
 * @param {*} type - 菜单类型：1（一级菜单）、2（二级菜单）、3（功能）
 */
export function isPermission(menuList, permission, type = 3) {
    return !!getPermissionMenuItem(menuList, type, permission);
}
