export default function withIsUnmount(componentTarget) {
    componentTarget.isUnmount = false;

    const unmount = componentTarget.prototype.componentWillUnmount;
    componentTarget.prototype.componentWillUnmount = function() {
        if (unmount) {
            unmount.call(this, ...arguments);
        }
        this.isUnmount = true;
    };

    const setState = componentTarget.prototype.setState;
    componentTarget.prototype.setState = function() {
        if (this.isUnmount) {
            return;
        }
        setState.call(this, ...arguments);
    };
    return componentTarget;
}
