import React from 'react';
import Exception from '../exception';
import * as Sentry from '@sentry/browser';
import { withRouter } from 'react-router-dom';

const ENVIRONMENT_DOMAINS = {
    production: [],
    staging: ['s-*.udesk.cn', '*.udeskt1.com', '*.udeskt2.com', '*.udeskt3.com', '*.tryudesk.com'],
    development: ['*.ud.com', 'local-*.udesk.cn', '*.local.udesk.cn', 'localhost'],
};

class ErrorBoundary extends React.Component {
    showException = false;

    componentDidMount() {
        if (process.env.NODE_ENV === 'production' && !this.matchTestEnv()) {
            Sentry.init({
                dsn: 'https://7ac37c851a1d4ec0803294f1221988c4@sentry.udesk.cn/138',
                integrations: [
                    new Sentry.Integrations.Breadcrumbs({
                        console: false,
                    }),
                ],
            });
        }
    }

    static getDerivedStateFromError(error) {}

    componentDidCatch(error, info) {
        console.log(error, info);
        if (process.env.NODE_ENV === 'production' && !this.matchTestEnv()) {
            Sentry.withScope((scope) => {
                Object.keys(info).forEach((key) => {
                    scope.setExtra(key, info[key]);
                });
                Sentry.captureException(error);
            });
        }
        console.error(error, '-----', info);
        this.showException = true;
        this.forceUpdate();
    }

    matchTestEnv() {
        let environmentKeys = Object.keys(ENVIRONMENT_DOMAINS);
        for (let index = 0; index < environmentKeys.length; index++) {
            let environment = environmentKeys[index];
            let domains = ENVIRONMENT_DOMAINS[environment];
            if (Array.isArray(domains) && domains.length > 0) {
                for (let domainsIndex = 0; domainsIndex < domains.length; domainsIndex++) {
                    let domainRule = domains[domainsIndex];
                    if (typeof domainRule === 'string') {
                        let regExp = new RegExp(
                            '^' + domainRule.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$&').replace('\\*', '.*') + '$',
                            'i'
                        );
                        if (regExp.test(window.location.hostname)) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    render() {
        if (this.showException) {
            this.showException = false;
            return <Exception />;
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
