import locales from 'locales';
import React from 'react';
import { BaseComponent, componentInjection } from '../../../utils';
import { Drawer } from '../../../ui';
import RecordsDetails from './details';
class Default extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id && this.props.id !== null) {
            this.setState({
                id: this.props.id
            });
        }
    }
    onClose = () => {
        if (this.props.onCloseDrawer) {
            this.props.onCloseDrawer(false);
        }
    };

    render() {
        const { visible, onloadList, zIndex = 1000 } = this.props,
            { id } = this.state;

        return (
            <Drawer
                className='alarm-record-drawer-box'
                width='auto'
                title={
                    <div className='drawer-title'>
                        <div className='drawer-title-text'>{locales.get('components-alarm-records-v52b8elhn7j')}</div>
                    </div>
                }
                placement='right'
                closable
                onClose={this.onClose}
                visible={visible}
                style={{ zIndex }}
            >
                <RecordsDetails id={id} onloadList={onloadList} />
            </Drawer>
        );
    }
}

export default componentInjection(Default);
