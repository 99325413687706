import { API_PATH, IS_LOCAL } from './consts';
import { $, log, execFn, getCurrentLocale } from './base';

import extend from 'lodash/extend';
import isUndefined from 'lodash/isUndefined';
import isObject from 'lodash/isObject';

import locales from 'locales';
// const CancelToken = axios.CancelToken;

const globalSettings = {};
const cacheData = {};

export function trimData(data) {
    for (let field in data) {
        if (typeof data[field] === 'string') {
            try {
                data[field] = data[field].trim();
            } catch (error) {}
        }
        // 这里使用递归，属性类型为对象则进一步遍历
        if (typeof data[field] === 'object') {
            trimData(data[field]);
        }
    }
}

function jsonStringify(data) {
    data = data || {};
    if (isObject(data)) {
        trimData(data);
        data = JSON.stringify(data);
    }
    return data;
}

function isBackend(url) {
    return !/^\/static/.test(url) && /^\//.test(url);
}

function genCacheKey(url, data) {
    return genUrl(url) + jsonStringify(data);
}

/**
 * 生成后端url
 * @param  {...any} params
 */
function genUrl(url, ...params) {
    params.unshift(url);
    url = params.join('/').replace(/\/\//g, '/');

    return isBackend(url) ? `${API_PATH}${url}` : url;
}

export function ajaxSettings(settings) {
    if (isObject(settings)) {
        extend(globalSettings, settings);
    }
    return globalSettings;
}

export function ajaxJSON(url, data, settings = {}) {
    let _isBackend = isBackend(url);
    let xhrFields = {};

    if (_isBackend) {
        xhrFields.withCredentials = true;
    }
    url = genUrl(url);
    settings = extend(
        {
            url: url,
            type: 'GET',
            dataType: 'json',
            contentType: 'application/json',
            timeout: IS_LOCAL ? null : 20000,
            xhrFields: xhrFields,
            headers: {
                lang: getCurrentLocale(locales),
            },
        },
        settings
    );

    settings.data = settings.type === 'GET' ? data : jsonStringify(data);
    // settings.data = jsonStringify(data);

    return $.ajax(settings).always((res) => {
        res = res || {};
        if (!_isBackend) return res;

        res = res.responseJSON ? res.responseJSON : res;
        res.code = isUndefined(res.code) ? res.status : res.code;
        res.message = res.message ? res.message : `${res.statusText || 'Error'}（${res.code}）`;
        if (res.code === 200) {
            res.ok = true;
            execFn(globalSettings, 'success', res, settings);
        } else if (res.code === 0 && res.statusText === 'abort') {
            // abort 请求处理
        } else {
            log('error', `[Ajax Error][${res.type} ${url}]`, settings.data, res.message, res);
            execFn(globalSettings, 'error', res, settings);
        }
        execFn(globalSettings, 'always', res, settings);
    });
}

export function getJSON(url, data, settings = {}) {
    const { cache } = settings;
    const cacheKey = genCacheKey(url, data);

    if (cache && cacheData[cacheKey]) {
        return $.Deferred((dfd) => {
            dfd.resolve(cacheData[cacheKey]);
        });
    }

    return ajaxJSON(url, data, settings).then((res) => {
        if (res.ok) {
            cacheData[cacheKey] = res;
        }
        return res;
    });
}

export function postJSON(url, data, settings) {
    return ajaxJSON(
        url,
        data,
        extend({}, settings, {
            type: 'POST',
        })
    );
}

export function putJSON(url, data, settings) {
    return ajaxJSON(
        url,
        data,
        extend({}, settings, {
            type: 'PUT',
        })
    );
}

export function deleteJSON(url, data, settings) {
    return ajaxJSON(
        url,
        data,
        extend({}, settings, {
            type: 'DELETE',
        })
    );
}
