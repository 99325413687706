import $ from 'jquery';
export default {
    upload
};

// {
//     token 信息
//     ossHost: host,
//     ossDir: dir,
//     ossAccessId: accessId,
//     ossPolicy: policy,
//     ossSignature: signature,
//
//     上传文件基本信息 {object}
//     file: attachments,
//
//     设置上传文件路径  function|string 若为function则需要传入所需参数calculateObjectNameData
//     calculateObjectNameData 为任意类型
//     calculateObjectName: fun,
//     calculateObjectNameData:;
//
//     进度、成功、失败回调函数
//     progressCallback: progressCallback.bind(that),
//     successCallback: uploadFileSuccess.bind(that),
//     errorCallback: uploadFileError.bind(that),
// }

function upload(options) {
    let file = options.file;
    if (file == null) {
        return;
    }
    if (file instanceof Array) {
        file = file[0];
    }

    let formData = new FormData();
    let url = options.ossHost;

    let fileData = {};
    let fileName = file.name;
    let calculateObjectName = options.calculateObjectName;
    if (typeof calculateObjectName === 'function') {
        fileName = calculateObjectName(options.calculateObjectNameData);
    } else {
        if (typeof calculateObjectName === 'string') {
            fileName = calculateObjectName;
        } else {
            throw new Error('`uploadImage` method must return a function or string.');
        }
    }
    fileData.name = file.name;
    fileData.filename = fileName;
    fileData.size = file.size;
    let key = fileName;
    fileData.key = key;
    fileData.createTime = file.createTime;
    formData.append('key', key);
    formData.append('Filename', fileName);
    formData.append('policy', options.ossPolicy);
    formData.append('OSSAccessKeyId', options.ossAccessId);
    formData.append('success_action_status', `200`);
    formData.append('signature', options.ossSignature);
    formData.append('file', file);
    let timeStamp;
    let lastTimeStamp;
    let xhr = $.ajax({
        data: formData,
        type: 'POST',
        url: url,
        cache: false,
        contentType: false,
        processData: false,
        xhr: xhrOnProgress(function(e) {
            let duration;
            if (!lastTimeStamp) {
                lastTimeStamp = e.timeStamp;
            }

            timeStamp = e.timeStamp;
            if (timeStamp) {
                duration = timeStamp - lastTimeStamp;
                let totalTime = duration / (e.loaded / e.total);
                fileData.remainingTime = totalTime - duration;
            }

            var percent = ((e.loaded / e.total) * 100).toFixed(2); //计算百分比

            fileData.percent = percent;
            fileData.loaded = e.loaded;
            let schedule = percent + '%';
            if (typeof options.progressCallback === 'function') {
                options.progressCallback(file, schedule);
            }
        }),
        success: function(resp) {
            if (typeof options.successCallback === 'function') {
                options.successCallback(file, fileData);
            }
        },
        error: function(reason) {
            if (typeof options.errorCallback === 'function') {
                options.errorCallback(file, reason, fileData);
            }
        }
    });

    fileData.xhr = xhr;
    return {
        abort() {
            fileData.xhr.abort();
        }
    };
}

//监听
function xhrOnProgress(fun) {
    xhrOnProgress.onprogress = fun; //绑定监听
    //使用闭包实现监听绑
    return function() {
        //通过$.ajaxSettings.xhr();获得XMLHttpRequest对象
        var xhr = $.ajaxSettings.xhr();
        //判断监听函数是否为函数
        if (typeof xhrOnProgress.onprogress !== 'function') {
            return xhr;
        }
        //如果有监听函数并且xhr对象支持绑定时就把监听函数绑定上去
        if (xhrOnProgress.onprogress && xhr.upload) {
            xhr.upload.onprogress = xhrOnProgress.onprogress;
        }
        return xhr;
    };
}
