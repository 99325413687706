import locales from 'locales';
import React, { Component } from 'react';
import { Icon } from '../../../ui';
import { PageContainer } from '../../../utils';

import './index.scss';
export default class Exception extends Component {
    render() {
        return (
            <PageContainer showHeader={false} showDivider={false} className='flay-away-box'>
                <div>
                    <div className='flay-away-icon'>
                        <Icon type='yemiancuowu'></Icon>
                    </div>
                    <div className='flay-away-text'>{locales.get('components-common-exception-t1beidevwho')}</div>
                </div>
            </PageContainer>
        );
    }
}
