import locales from 'locales';
import React from 'react';
import { message, Upload as AntdUpload, Modal, Button, Typography } from 'udesk-ui';
import { getAttr } from '../../utils';
import { VSpace, DownloadLink } from '../base';
import { beforeUpload } from './base';
import extend from 'lodash/extend';
import omit from 'lodash/omit';
const { Text } = Typography;
export function Upload(props) {
    props = extend(
        {
            withCredentials: true,
        },
        {
            beforeUpload: (file) =>
                beforeUpload({
                    file: file,
                    limitM: 5,
                }),
        },
        props
    );
    return <AntdUpload {...props} />;
}
export function UploadModal(props) {
    const {
        title = locales.get('components-information-228bicpddtq'),
        uploadUrl,
        downloadUrl,
        uploadProps = {},
    } = props;
    const modalProps = omit(props, 'title', 'uploadUrl', 'downloadUrl', 'uploadProps');
    let newUploadProps = extend(
        {
            // showUploadList: false,
            accept: '.xlsx',
            action: uploadUrl,
        },
        uploadProps,
        {
            beforeUpload: (file) => {
                if (getAttr(uploadProps, 'fileList.length') > 0) {
                    message.error(locales.get('ui-upload-fndfqlhrj9t'));
                    return false;
                }
                if (!beforeUpload({ file: file })) {
                    return false;
                }
                return true;
            },
        }
    );
    return (
        <Modal visible centered title={title} {...modalProps}>
            <Upload {...newUploadProps}>
                <Button>{locales.get('ui-upload-gdsfny16r3h')}</Button>
            </Upload>
            <VSpace size={4} />
            <Text type="secondary">
                {locales.get('ui-upload-x7sd51ue7jh')}
                <DownloadLink href={downloadUrl}>{locales.get('ui-upload-m0avr66v8ei')}</DownloadLink>
                {locales.get('ui-upload-rckpy0euy3')}
                <br />
                {locales.get('ui-upload-zhja5gzeck9')}
            </Text>
        </Modal>
    );
}
extend(Upload, AntdUpload);
