import locales from 'locales';
import React from 'react';
import { BaseComponent, putJSON, getJSON } from '../../../utils';
import { Drawer, Fragment } from '../../../ui';
import { Button, Divider, message } from 'udesk-ui'; //antd 4.0 upgrade
import List from './list';
import './style.scss';
import { withRouter } from 'react-router-dom';
import DrawerBox from '../../mission/drawer';
import AlarmDrawer from '../../alarm/records/drawer';
import GlobalMessage from '../../../utils/message/globalMessage';
import messageType from '../../../utils/message/messageType';

class Default extends BaseComponent {
    state = {
        drawerVisible: false,
        tabIndex: 1,
        alarmDrawerVisible: false,
    };

    componentDidMount() {
        this.informListRefs = [];
        this.props.onRef(this);
    }

    openDrawer() {
        this.setState({
            drawerVisible: true,
            misssionVisible: false,
            alarmDrawerVisible: false,
        });
    }

    closeDrawer() {
        this.setState({ drawerVisible: false });
    }

    tabsOnClickHandle(tabIndex) {
        this.setState({
            tabIndex: tabIndex,
        });
    }

    notificationsReadAll() {
        putJSON(`/notifications`).always(() => {
            this.listRefReload();
        });
    }

    listRefReload = () => {
        this.informListRefs.forEach((item) => {
            item.reload();
        });
    };

    switchoverDrawer = (item) => {
        switch (item.type) {
            case 0:
            case 2:
                this.setState({ drawerVisible: false }, () => {
                    this.loadMisssionData(item);
                });
                break;
            case 1:
                this.setState({ drawerVisible: false }, () => {
                    this.loadAlarmData(item);
                });
                break;
            case 3:
                this.setState({ drawerVisible: false }, () => {
                    this.goPage('/interactive/interactivemanage');
                });
                break;
            // TODO 预警通知
            case 4:
            case 5:
                this.setState({ drawerVisible: false });
                break;
            default:
                break;
        }
    };

    loadMisssionData(item) {
        const id = item.billId;
        getJSON(`/tasks/${id}`).always((res) => {
            if (res.ok) {
                this.setState(
                    {
                        misssionDrawerData: res.data,
                        misssionId: id,
                    },
                    () => {
                        this.setState({
                            misssionVisible: true,
                        });
                    }
                );
            } else {
                message.error(locales.get('components-layout-inform-tkw64peamt9'));
            }
        });
        getJSON(`/comments/task/${id}`).always((res) => {
            this.setState({
                misssionCommentData: res.data,
            });
        });

        getJSON(`/tasks/users/${id}`).always((res) => {
            this.setState({
                misssionMentionIds: res.data,
            });
        });
        getJSON(`/logs/${id}`).always((res) => {
            this.setState({
                misssionLogsData: res.data,
            });
        });
    }

    loadAlarmData(item) {
        this.setState({
            showDetailAlarmId: item.billId,
            alarmDrawerVisible: true,
        });
    }

    bindRef = (ref) => {
        this.informListRefs.push(ref);
    };

    reloading = () => {
        const { misssionId } = this.state;
        getJSON(`/tasks/${misssionId}`).always((res) => {
            this.setState({
                misssionDrawerData: res.data,
            });
        });
        this.reloadTask();
    };

    reloadTask = () => {
        GlobalMessage.postMessage(messageType.ReloadMission);
    };

    onCloseAlarmDrawer = () => {
        this.setState({
            showDetailAlarmId: null,
            alarmDrawerVisible: false,
        });
    };

    render() {
        const {
            drawerVisible,
            tabIndex,
            misssionId,
            misssionVisible,
            misssionMentionIds = [],
            misssionLogsData = [],
            misssionDrawerData = {},
            misssionCommentData = [],
            alarmDrawerVisible,
            showDetailAlarmId,
        } = this.state;

        const { updateUnreadNum, unreadNum, history } = this.props;

        return (
            <Fragment>
                <Drawer
                    title={locales.get('components-layout-inform-qokfekainth')}
                    onClose={this.closeDrawer.bind(this)}
                    width="360"
                    destroyOnClose
                    className="layout-inform-index-root"
                    visible={drawerVisible}
                    // 层级最高 在基类中被强制设置为1
                    style={{ zIndex: 1200 }}
                >
                    <Button
                        style={{ display: tabIndex === 1 ? 'block' : 'none' }}
                        onClick={this.notificationsReadAll.bind(this)}
                        type="link"
                        size="small"
                        className="layout-inform-mark-review"
                    >
                        {locales.get('components-layout-inform-nwdt780axhd')}
                    </Button>
                    <div className="layout-inform-drawer-box">
                        <div
                            style={{ display: tabIndex === 1 ? 'block' : 'none' }}
                            className="layout-inform-drawer-list-box"
                        >
                            <List
                                history={history}
                                onRef={this.bindRef}
                                switchoverDrawer={this.switchoverDrawer}
                                readStatus="0"
                                listRefReload={this.listRefReload}
                                updateUnreadNum={updateUnreadNum}
                            ></List>
                        </div>
                        <div
                            style={{ display: tabIndex === 2 ? 'block' : 'none' }}
                            className="layout-inform-drawer-list-box"
                        >
                            <List
                                history={history}
                                onRef={this.bindRef}
                                switchoverDrawer={this.switchoverDrawer}
                                listRefReload={this.listRefReload}
                                readStatus="1"
                            ></List>
                        </div>
                        <div className="layout-inform-drawer-tabs">
                            <span
                                className={tabIndex === 1 ? 'tabs-active' : ''}
                                onClick={this.tabsOnClickHandle.bind(this, 1)}
                            >
                                {locales.get('components-layout-inform-mnzp78ihryf')}
                                {unreadNum > 0 && <strong>({unreadNum})</strong>}
                            </span>
                            <Divider type="vertical" />
                            <span
                                className={tabIndex === 2 ? 'tabs-active' : ''}
                                onClick={this.tabsOnClickHandle.bind(this, 2)}
                            >
                                {locales.get('components-layout-inform-nsbw1a751i')}
                            </span>
                        </div>
                    </div>
                </Drawer>
                <DrawerBox
                    visible={misssionVisible}
                    onClose={() => this.setState({ misssionVisible: false })}
                    mentionIds={misssionMentionIds}
                    logsData={misssionLogsData}
                    drawerData={misssionDrawerData}
                    id={misssionId}
                    commentData={misssionCommentData}
                    reloading={this.reloading}
                    zIndex={1001}
                />
                <AlarmDrawer
                    visible={alarmDrawerVisible}
                    id={showDetailAlarmId}
                    onCloseDrawer={this.onCloseAlarmDrawer}
                    onloadList={() => {}}
                    destroyOnClose
                    zIndex={1001}
                />
            </Fragment>
        );
    }
}

export default withRouter(Default);
