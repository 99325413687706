import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { LONG_DATETIME_FORMAT } from './consts';
import { moment, $, getAttr } from './base';
import EventedClass from './message/eventClass';

import extend from 'lodash/extend';
import pick from 'lodash/pick';
import isPlainObject from 'lodash/isPlainObject';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import has from 'lodash/has';
import isString from 'lodash/isString';
import each from 'lodash/each';

import { getData } from '../store';
import withIsUnmount from './hoc/withIsUnmount';

let uid = 0;
const rootEl = window.document.querySelector('#root');

class AppContext extends EventedClass {}

const appContext = new Proxy(new AppContext(), {
    get: function (target, key, receiver) {
        if (key === 'organizationList') {
            return getData('organizations');
        } else {
            return Reflect.get(target, key, receiver);
        }
    },
    set: function (target, key, value, receiver) {
        if (key === 'menuList') {
            target.trigger('onMenuListChange', null, [value]);
        }
        return Reflect.set(target, key, value, receiver);
    },
});

export function setAppContext(obj) {
    if (isPlainObject(obj)) {
        return extend(appContext, obj);
    }
    return appContext;
}

class BaseComponentClass extends Component {
    rootEl = rootEl;
    appContext = appContext;
    setAppContext = setAppContext;
    state = {};

    constructor(props) {
        setAppContext({
            uid: ++uid,
        });

        super(props);
    }

    $(selector, context) {
        if (selector instanceof React.Component) {
            return $(ReactDOM.findDOMNode(selector));
        }

        context = context || ReactDOM.findDOMNode(this);
        if (selector) {
            selector = selector.currentTarget || selector;
            return $(selector, context);
        }
        return $(context);
    }

    /**
     * 获取路由props
     */
    getRouteProps() {
        return pick(this.props, 'match', 'history', 'parentMatch');
    }

    /**
     * 获取路由状态
     */
    getRouteState(key) {
        let state = this.props.history.location.state || {};
        if (key) {
            return getAttr(state, key) || '';
        }
        return state;
    }

    /**
     * 跳转页面
     * @param {string|Object} pathname
     * @param {Object} [state]
     * @param {boolean} [isReplace]
     * @example
     *   this.goPage(pathname, state, isReplace)
     *   this.goPage({ pathname, state, isReplace })
     */
    goPage(pathname, state = {}, isReplace) {
        const { history } = this.props;
        let config = { pathname, state };

        if (isObject(pathname)) {
            isReplace = pathname.isReplace;
            config = omit(pathname, 'isReplace');
        }

        history[isReplace ? 'replace' : 'push'](config);
    }

    /**
     * 返回上一个页面
     */
    goBack() {
        this.props.history.goBack();
    }

    toggleState(key, state) {
        this.setState(
            extend(
                {
                    [key]: true,
                },
                state
            ),
            () => {
                this.setState({
                    [key]: false,
                });
            }
        );
    }

    openFullscreen() {
        this.rootEl.classList.add('fullscreen');
    }

    quitFullscreen() {
        this.rootEl.classList.remove('fullscreen');
    }

    /**
     * 监听自定义变更事件
     * @param {string|Object} customKey - 自定义key或者原对象
     * @param {string|Object} key - 更改的key或者更改的对象
     * @param {*} value - 更改的value
     * @example
     *   this.onCustomChange('data', 'key', value)
     *   this.onCustomChange(data, { key1: value1, key2: value2, ... })
     */
    onCustomChange(customKey, key, value) {
        const isObj = isObject(customKey);
        const data = isObj ? customKey : this.state[customKey] || {};
        const handle = (obj, key, value) => {
            if (value) {
                if (isArray(value)) {
                    value = value.map((v) => (moment.isMoment(v) ? v.format(LONG_DATETIME_FORMAT) : v));
                } else if (moment.isMoment(value)) {
                    value = value.format(LONG_DATETIME_FORMAT);
                } else if (isObject(value) && value.target) {
                    let target = value.target;
                    if (has(target, 'value')) {
                        value = target.value;
                    } else if (has(target, 'checked')) {
                        value = target.checked;
                    }
                }
            }

            obj[key] = value;
        };

        if (isObject(key)) {
            each(key, (value, key) => {
                handle(data, key, value);
            });
        } else if (isString(key)) {
            handle(data, key, value);
        } else {
            return data;
        }

        if (!isObj) {
            this.setState({
                [customKey]: data,
            });
        } else {
            this.forceUpdate();
        }
        return data;
    }

    /**
     * 监听数据变更事件
     * @param {string} key - 更改的key
     * @param {*} value - 更改的value
     */
    onDataChange(key, value) {
        return this.onCustomChange('data', key, value);
    }
}

class UIBaseComponentClass extends BaseComponentClass {}

export const BaseComponent = withIsUnmount(BaseComponentClass);

export const UIBaseComponent = withIsUnmount(UIBaseComponentClass);
