import extend from 'lodash/extend';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import each from 'lodash/each';
import isObject from 'lodash/isObject';
import sortBy from 'lodash/sortBy';
import clone from 'lodash/clone';

import isString from 'lodash/isString';
class Base {
    constructor(props) {
        extend(
            this,
            {
                primaryKey: 'id'
            },
            props
        );
    }

    _mapItem(item) {
        return extend(
            extend(
                {
                    // created_at: new Date(),
                    // updated_at: new Date()
                },
                this.defaultItem
            ),
            item
        );
    }
    _find(state, obj) {
        var pk = this.primaryKey;
        return find(state, {
            [pk]: obj[pk]
        });
    }
    _add(state, obj, method = 'push') {
        state[method](this._mapItem(obj));
    }
    _edit(state, source, target) {
        source = extend(source, this._mapItem(extend({}, source, target)));
    }
    _upsert(state, obj, method = 'push') {
        var item = this._find(state, obj);
        if (item) {
            this._edit(state, item, obj);
        } else {
            this._add(state, obj, method);
        }
    }

    add(state, obj, method) {
        if (!isArray(obj) && (obj._data || obj.data)) {
            obj = obj._data || obj.data;
            method = obj.method;
        }

        if (obj) {
            if (isArray(obj)) {
                each(obj, item => {
                    return this._add(state, item, method);
                });
            } else {
                this._add(state, obj, method);
            }
        }

        return [...state];
    }
    edit(state, obj) {
        return state.map(item => {
            return this._edit(state, item, obj);
        });
    }
    upsert(state, obj, method) {
        if (!isArray(obj) && (obj._data || obj.data)) {
            obj = obj._data || obj.data;
            method = obj.method;
        }

        if (obj) {
            if (isArray(obj)) {
                each(obj, item => {
                    this._upsert(state, item, method);
                });
            } else {
                this._upsert(state, obj, method);
            }
        }

        return [...state];
    }
    remove(state, id) {
        return state.filter(item => {
            if (isObject(id)) {
                return item !== id || item[this.primaryKey] !== id[this.primaryKey];
            }
            return item[this.primaryKey] !== id;
        });
    }
    clear(state) {
        state.length = 0;
        return [...state];
    }
    sortBy(state, key = 'sn') {
        return sortBy(state, key);
    }
    objectSet(state, { key, value }) {
        if (key && isString(key)) {
            return extend({}, state, {
                [key]: value
            });
        }
        return state;
    }
    objectSetProperties(state, obj) {
        if (Object.prototype.toString.call(obj) === '[object Object]') {
            return extend({}, state, obj);
        }
        return state;
    }
    objectDelete(state, key, value) {
        if (state.hasOwnProperty([key])) {
            let newState = extend({}, state);
            delete newState[key];
            return newState;
        }
        return state;
    }
    objectDeleteProperties(state, obj) {
        if (isArray(obj)) {
            let newState = clone(state);
            obj.forEach(field => {
                if (newState.hasOwnProperty([field])) {
                    delete newState[field];
                }
            });
            return newState;
        }
        return state;
    }
    objectReplace(state, obj) {
        if (Object.prototype.toString.call(obj) === '[object Object]') {
            return extend({}, obj);
        }
        return state;
    }
}

export default Base;
