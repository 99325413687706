/**
 * 组织架构数据
 */
import { createReducer, createAction } from 'redux-act';
import Base from './base';

const base = new Base();

export const add = createAction('add');
export const edit = createAction('edit');
export const upsert = createAction('upsert');
export const remove = createAction('remove');
export const clear = createAction('clear');

export default createReducer({
    [add]: base.add.bind(base),
    [edit]: base.edit.bind(base),
    [upsert]: base.upsert.bind(base),
    [remove]: base.remove.bind(base),
    [clear]: base.clear.bind(base)
}, []);