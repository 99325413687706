/**
 * 项目中心列参数
 */
import { createReducer, createAction } from 'redux-act';
import Base from '../base';

const base = new Base();

export const objectSet = createAction('objectSet');

export default createReducer(
    {
        [objectSet]: base.objectSet.bind(base),
    },
    {}
);
