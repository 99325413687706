import locales from 'locales';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { classnames } from '../utils';

import extend from 'lodash/extend';
import omit from 'lodash/omit';
import filter from 'lodash/filter';
import kebabCase from 'lodash/kebabCase';
import capitalize from 'lodash/capitalize';

import { Empty, message, Button, Popover } from 'udesk-ui';
import Icon from './icon';
import copy from 'copy-to-clipboard';

export { Link } from 'react-router-dom';

export const Fragment = React.Fragment;

export const spacing = {
    unit: 8,
};

export function Html(props) {
    return <div {...omit(props, 'children')} dangerouslySetInnerHTML={{ __html: props.children }} />;
}

export function NoAuth(props) {
    return (
        <Style centerBox>
            <Empty description={locales.get('ui-55d69l9gfts')} />
        </Style>
    );
}

/**
 * 水平占位组建
 * @param {number} [props.size=1] - 设置大小，1=8px
 */
export function Space(props) {
    const width = `${(props.size || 1) * spacing.unit}px`;

    return (
        <span
            style={{
                display: 'inline-block',
                width: width,
            }}
        />
    );
}

/**
 * 垂直占位组建
 * @param {number} [props.size=1] - 设置大小，1=8px
 */
export function VSpace(props) {
    const marginTop = `${(props.size || 1) * spacing.unit}px`;

    return <div style={{ marginTop }} />;
}

export function Style(props) {
    const { children, className, inline, middle } = props;
    const classNameFlags = ['clearfix', 'ellipsis', 'centerBox'];
    const style = omit(props, 'children', 'className', 'inline', 'middle', ...classNameFlags);
    const validClassNames = filter(classNameFlags, (name) => props[name]).map((name) => kebabCase(name));
    const newProps = {
        style,
        className: classnames(className, ...validClassNames),
    };

    inline && (style.display = 'inline-block');
    middle && (style.verticalAlign = 'middle');

    return <div {...newProps}>{children}</div>;
}

/**
 * 外部链接，提供安全的外部链接
 */
export function BlankLink(props) {
    props = extend(
        {
            target: '_blank',
            rel: 'noopener',
        },
        props
    );

    return (
        <Button type="link" {...props}>
            {props.children}
        </Button>
    );
}
/**
 * 专门用于下载，下的的话不要用上面的BlankLink了
 */
export function DownloadLink(props) {
    props = extend(
        {
            rel: 'noopener noreferrer',
            download: true,
        },
        props
    );

    return (
        <Button type="link" {...props}>
            {props.children}
        </Button>
    );
}

/**
 * 多语言消息组建
 */
export function LangMsg(props) {
    props = extend(
        {
            defaultMessage: capitalize(props.id),
        },
        props
    );

    return <FormattedMessage {...props} />;
}

export function StarRed(props) {
    return <span className="udesk-cem-legacy-form-item-required">{props.children}</span>;
}

export function CopyToClipboard(props) {
    return (
        <Button
            type="link"
            onClick={() => {
                if (props.text) {
                    copy(props.text);
                    message.success(locales.get('components-customercenter-care-publishmodal-u5dxpc34yk'));
                } else {
                    message.warn(locales.get('ui-mar8jj5fops'));
                }
            }}
        >
            {props.children || locales.get('components-notice-detail-channel-vp0ajp2trfj')}
        </Button>
    );
}

export function Pointer(props) {
    const { style, children } = props;
    return (
        <span {...props} style={extend({ cursor: 'pionter' }, style)}>
            {children}
        </span>
    );
}

export function Hint(props) {
    const { children, iconStyle = {} } = props;
    return (
        <Popover {...props}>
            {children ? (
                children
            ) : (
                <Icon type="wenti" style={extend({ color: '#b6b9c1', fontWeight: 'normal' }, iconStyle)} />
            )}
        </Popover>
    );
}
