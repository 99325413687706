import React from 'react';
import { Drawer as AntdDrawer } from 'udesk-ui';
import { classnames } from '../utils';

import extend from 'lodash/extend';
import { Style } from './base';
import { UIBaseComponent } from '../utils/baseComponent';
import withStyles from 'react-jss';

export const drawerStyles = {
    root: {
        '& .udesk-cem-drawer-wrapper-body': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .udesk-cem-drawer-body': {
            flex: 1,
            overflow: 'auto',
        },
    },
};

class Drawer extends UIBaseComponent {
    render() {
        const props = this.props;
        const { classes } = props;
        let innerProps = extend(
            {
                width: 560,
                // mask: false,
                getContainer: '#root>section>section',
                placement: 'right',
            },
            props,
            {
                className: classnames(classes.root, props.className),
                title: props.title && (
                    <Style color="rgba(13,19,38,0.6)" fontSize={14}>
                        {props.title}
                    </Style>
                ),
                style: extend(
                    {
                        top: 60,
                        zIndex: 1,
                    },
                    props.style
                ),
            }
        );

        return <AntdDrawer {...innerProps} />;
    }
}

Drawer = withStyles(drawerStyles)(Drawer);

export { Drawer };
