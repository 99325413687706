import locales from 'locales';
/**
 * 任务用户选择列表
 */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseComponent, getJSON, emptyFn } from '../../../utils';
import { Select, Spin } from 'udesk-ui';

import extend from 'lodash/extend';
import debounce from 'lodash/debounce';

class Default extends BaseComponent {
    static propTypes = {
        onChange: PropTypes.func,
    };
    static defaultProps = {
        onChange: () => {},
    };

    constructor(props) {
        super(props);
        this.reload = debounce(this.reload.bind(this), 800);

        this.state = {
            data: [],
            loading: false,
            conditions: {
                pageNum: 1,
                pageSize: 10,
            },
        };
    }

    componentDidMount() {
        this.reload();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.otherParams !== this.props.otherParams) {
            this.setState(
                {
                    conditions: {
                        pageNum: 1,
                        pageSize: 10,
                    },
                },
                () => {
                    this.reload();
                }
            );
        }
    }

    reload(params) {
        const { conditions } = this.state;
        const { specialUrl, otherParams = {} } = this.props;

        this.setState({
            loading: true,
            conditions: extend(conditions, params, otherParams),
        });

        getJSON(specialUrl || `/tasks/users`, conditions).always((res) => {
            this.setState({
                loading: false,
                data: res.data || [],
            });
        });
    }

    onChange = (id) => {
        const { onChange = emptyFn } = this.props;
        onChange(id);
        this.handleSearch('');
    };

    handleSearch = (keyword) => {
        this.reload({ keyword });
    };

    onPopupScroll = (event) => {
        const clientHeight = event.target.clientHeight,
            scrollHeight = event.target.scrollHeight,
            scrollTop = event.target.scrollTop,
            isBottom = clientHeight + scrollTop >= scrollHeight;
        if (isBottom) {
            this.reload({
                pageNum: 1,
                pageSize: this.state.conditions.pageSize + 10,
            });
        }
    };

    reset = () => {
        this.reload();
    };

    render() {
        const { loading, data } = this.state,
            { value, specialUrl, selectOptionKeyName, selectOptionValueKey, selectOptionLabelKey } = this.props;

        return (
            <Select
                labelInValue={specialUrl}
                showSearch
                defaultValue={value}
                placeholder={locales.get('components-common-select-xmfdorvr0z')}
                filterOption={false}
                loading={loading}
                allowClear={true}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onSearch={this.handleSearch}
                onPopupScroll={this.onPopupScroll}
                {...this.props}
                onChange={this.onChange}
            >
                {data.map((item) => (
                    <Select.Option key={item[selectOptionKeyName || 'id']} value={item[selectOptionValueKey || 'id']}>
                        {item[selectOptionLabelKey || 'name']}
                    </Select.Option>
                ))}
            </Select>
        );
    }
}

export default Default;
