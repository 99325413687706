import { $ } from './base';
import isArray from 'lodash/isArray';

const cacheData = {};

function toArr(arr) {
    return arr ? (isArray(arr) ? arr : [arr]) : [];
}

function _loadJs(url, cache) {
    if (cache && cacheData[url]) {
        return $.Deferred(function(deferred) {
            $(deferred.resolve);
        });
    }

    return $.getScript(url).then(function(res) {
        cacheData[url] = true;
        return res;
    });
}

/**
 * 加载JS文件
 * @param {string|string[]} url
 * @param {Object} cfg
 * @param {string} cfg.dir - 根路径
 * @param {string} [cfg.cache=true]
 */
export function loadJS(url, cfg) {
    var mapUrls;

    cfg = $.extend(
        {
            dir: '',
            cache: true
        },
        cfg
    );

    mapUrls = $.map(toArr(url), function(val) {
        return _loadJs((cfg.dir || '') + val, cfg.cache);
    });

    return $.when.apply($, mapUrls);
}
