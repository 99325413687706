import locales from 'locales';
import React from 'react';
import { Table as AntdTable } from 'udesk-ui';
import extend from 'lodash/extend';
import throttle from 'lodash/throttle';
import { UIBaseComponent } from '../../utils/baseComponent';

export { default as TableColumnFilter } from './columnFilter';

export class Table extends UIBaseComponent {
    static defaultProps = {
        subTableHeight: 240,
    };

    state = {
        tableHeight: 700,
    };

    constructor(props) {
        super(props);

        this.calculateHeight = throttle(this.calculateHeight.bind(this), 300);
    }

    calculateHeight() {
        const { subTableHeight } = this.props;

        this.setState({
            tableHeight: window.innerHeight - subTableHeight - 40,
        });
    }

    componentDidMount() {
        const { resizeFlag } = this.props;

        if (resizeFlag) {
            window.addEventListener('resize', () => {
                this.calculateHeight();
            });
            window.dispatchEvent(new Event('resize'));
        }
    }

    componentWillUnmount() {
        const { resizeFlag } = this.props;

        if (resizeFlag) {
            window.removeEventListener('resize', this.calculateHeight);
        }
    }

    render() {
        const { resizeFlag } = this.props;
        let props = this.props;
        let pagination = props.pagination;
        let totalPageNum;

        let mergeProps = {};
        if (resizeFlag) {
            mergeProps.scroll = {
                x: props.x || 1550,
                y: this.state.tableHeight,
            };
        }
        //props无法拓展，新建mergeProps合并props
        extend(mergeProps, props);

        if (pagination !== false && pagination !== null) {
            pagination = extend(
                {
                    total: 0,
                    pageSize: 20,
                    current: 1,
                    defaultPageSize: 20,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    showSizeChanger: true,
                },
                props.pagination
            );

            if (pagination.pageNum) {
                pagination.current = pagination.pageNum;
                delete pagination.pageNum;
            }

            totalPageNum = Math.ceil(pagination.total / pagination.pageSize);
            pagination.showTotal = (total) =>
                locales.get('ui-table-pageSize-0l99vx2zqjkm', {
                    pageSize: pagination.pageSize,
                    totalPageNum: totalPageNum,
                    total: total,
                });
        }

        //wordBreak: 'break-all'解决表头和表体宽度不一致
        return <AntdTable style={{ wordBreak: 'break-all' }} {...mergeProps} pagination={pagination} />;
    }
}
