// import { DragDropContext } from 'react-dnd'
// import HTML5Backend from 'react-dnd-html5-backend'
import { connect } from '../store';
import { withStyles } from './base';

/**
 * 组件注入
 * @param {Class} Component
 * @param {Object} cfg
 * @param {Object} [cfg.styles] - jss样式配置
 */
export function componentInjection(Component, cfg) {
    cfg = cfg || {};

    if (cfg.styles) {
        Component = withStyles(cfg.styles)(Component);
    }

    // if (cfg.dnd) {
    //     Component = DragDropContext(HTML5Backend)(Component);
    // }

    Component = connect(Component);

    return Component;
}
