import isString from 'lodash/isString';
import isElement from 'lodash/isElement';

export const emptyVarReg = new RegExp(/\{#选择数据\}/g);

/**
 * 获取输入框光标位置
 * @param {Element} el
 */
export function getInputCursorPosition(el) {
    return isElement(el) ? el.selectionStart : 0;
}

/**
 * 插入文本到输入框
 * @param {Element} el
 * @param {string} text
 */
export function inserTextToInput(el, text, start) {
    if (!isElement(el) || !isString(text)) {
        return;
    }

    if (isNaN(start)) {
        start = el.selectionStart;
    }
    if (emptyVarReg.test(el.value)) {
        el.value = el.value.replace(emptyVarReg, text);
        el.selectionStart = el.value.length;
    } else {
        el.value = el.value.substring(0, start) + text + el.value.substring(start);
        el.setSelectionRange(start + text.length, start + text.length, 'forward');
    }

    return el.value || '';
}

//用于echarts
export function encodeHTML(source) {
    return String(source)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;');
}

/**
 * 指定img加载完后 其宽高按 等比例缩放
 * 长边要是超过最长边长度 就缩到最长边长度 然后宽边相应比例缩小
 * @param {string} imgDomId img的id
 * @param {number} longSideLength 最长边长度
 */
export function imgOnLoad(imgDomId, longSideLength) {
    let imgEle = document.getElementById(imgDomId);
    let { width, height } = imgEle;
    let finalWidth = 0;
    let finalHeight = 0;
    let rate = width / height;

    let longSide = width > height ? width : height;
    let isHeightLong = longSide === height;

    if (longSide > longSideLength) {
        if (isHeightLong) {
            finalHeight = longSideLength;
            finalWidth = finalHeight * rate;
        } else {
            finalWidth = longSideLength;
            finalHeight = finalWidth / rate;
        }
    } else {
        finalWidth = width;
        finalHeight = height;
    }
    imgEle.width = finalWidth;
    imgEle.height = finalHeight;
}
