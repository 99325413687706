/**
 * 项目中心列表状态
 */
import { createReducer, createAction } from 'redux-act';
import Base from '../base';

const base = new Base();

export const objectSet = createAction('objectSet');
export const objectSetProperties = createAction('objectSetProperties');
export const objectDelete = createAction('objectDelete');
export const objectDeleteProperties = createAction('objectDeleteProperties');

export default createReducer(
    {
        [objectSet]: base.objectSet.bind(base),
        [objectSetProperties]: base.objectSetProperties.bind(base),
        [objectDelete]: base.objectDelete.bind(base),
        [objectDeleteProperties]: base.objectDeleteProperties.bind(base)
    },
    {}
);
