const EVENT_HANDLERS_MAPPING_SYMBOL = Symbol('[EventHandlers]');
const EVENT_MANAGER_SYMBOL = Symbol('[EventProvider]');

export default class EventedClass {
    constructor() {
        this[EVENT_HANDLERS_MAPPING_SYMBOL] = {};
    }

    static injectTo(target, parser) {
        if (target) {
            target[EVENT_MANAGER_SYMBOL] = new EventedClass();
        }
        let memberDescriptors = Object.getOwnPropertyDescriptors(EventedClass.prototype);
        delete memberDescriptors.constructor;
        for (let name of Object.keys(memberDescriptors)) {
            let sourceValue = target[EVENT_MANAGER_SYMBOL][name];
            if (typeof sourceValue === 'function') {
                sourceValue = (function(methodName) {
                    return function() {
                        return target[EVENT_MANAGER_SYMBOL][methodName].apply(target[EVENT_MANAGER_SYMBOL], arguments);
                    };
                })(name);
            }
            let result = {};
            if (typeof parser === 'function') {
                let parsedResult = parser.apply(target, [name, sourceValue]);
                if (parsedResult == null) {
                    continue;
                } else if (typeof parsedResult === 'object') {
                    result = parsedResult;
                } else {
                    result = {
                        [name]: parsedResult
                    };
                }
            } else {
                result = {
                    [name]: sourceValue
                };
            }

            //Remove duplicated keys, i.e. the method already exists on target.
            let duplicatedKeys = Object.keys(result).filter(key => target.hasOwnProperty(key));
            duplicatedKeys.forEach(key => {
                delete result[key];
            });
            Object.assign(target, result);
        }
        return target;
    }

    on(eventName, eventSource, handler) {
        if (typeof eventName !== 'string') {
            throw new Error(`'eventName' must be string and cannot be null.`);
        }
        if (handler == null && typeof eventSource === 'function') {
            handler = eventSource;
            eventSource = null;
        }
        if (eventSource == null) {
            eventSource = '';
        }
        if (typeof eventSource !== 'string') {
            throw new Error(`'eventSource' must be string.`);
        }
        if (typeof handler !== 'function') {
            throw new Error(`'handler' must be function.`);
        }
        let eventNameResult = parseEventName(eventName);

        if (this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName] == null) {
            this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName] = {};
        }

        let eventNamespaces = this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName];
        if (eventNamespaces[eventNameResult.namespace] == null) {
            eventNamespaces[eventNameResult.namespace] = {};
        }

        let eventSources = eventNamespaces[eventNameResult.namespace];
        if (eventSources[eventSource] == null) {
            eventSources[eventSource] = [];
        }

        let eventHandlers = eventSources[eventSource];
        eventHandlers.push(handler);
        return this;
    }

    off(eventName, eventSource, handler) {
        if (eventName == null) {
            eventName = '';
        }
        if (typeof eventName !== 'string') {
            throw new Error(`'eventName' must be string and cannot be null.`);
        }
        if (handler == null && typeof eventSource === 'function') {
            handler = eventSource;
            eventSource = null;
        }
        if (eventSource == null) {
            eventSource = '';
        }
        if (typeof eventSource !== 'string') {
            throw new Error(`'eventSource' must be string.`);
        }
        if (handler != null && typeof handler !== 'function') {
            throw new Error(`'handler' must be function.`);
        }

        let eventNameResult = parseEventName(eventName);
        if (eventNameResult.eventName) {
            if (this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName]) {
                let namespaces = [];
                if (eventNameResult.namespace) {
                    namespaces.push(eventNameResult.namespace);
                } else {
                    namespaces = Object.keys(this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName]);
                }

                for (let namespace of namespaces) {
                    let eventHandlers = this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName][namespace][
                        eventSource
                    ];
                    if (handler != null) {
                        let matchIndex = eventHandlers.indexOf(handler);
                        if (matchIndex !== -1) {
                            eventHandlers.splice(matchIndex, 1);
                        }
                    } else {
                        delete this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName][namespace];
                        if (Object.keys(this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName]).length === 0) {
                            delete this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName];
                        }
                    }
                }
            }
        } else {
            this[EVENT_HANDLERS_MAPPING_SYMBOL] = {};
        }
        return this;
    }

    trigger(eventName, eventSource, args) {
        if (typeof eventName !== 'string') {
            throw new Error(`'eventName' must be string and cannot be null.`);
        }
        if (args == null && Array.isArray(eventSource)) {
            args = eventSource;
            eventSource = null;
        }
        if (eventSource == null) {
            eventSource = '';
        }
        if (typeof eventSource !== 'string') {
            throw new Error(
                `If you are passing event \`args\` when triggering an event, they must be an array! If you are using 'eventSource', it must be the second parameter (before \`args\`) and must be string.`
            );
        }

        if (args == null) {
            args = [];
        }
        if (!Array.isArray(args)) {
            //throw new Error(`'args' must be Array.`);
            args = [args];
        }

        let eventNameResult = parseEventName(eventName);
        if (this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName]) {
            let namespaces = Object.keys(this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName]);
            for (let namespace of namespaces) {
                let eventHandlers = [];
                if (eventSource.length > 0) {
                    eventHandlers =
                        this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName][namespace][eventSource] || [];
                    eventHandlers = eventHandlers.concat(
                        this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName][namespace][''] || []
                    );
                } else {
                    let eventSources = this[EVENT_HANDLERS_MAPPING_SYMBOL][eventNameResult.eventName][namespace];
                    for (let key of Object.keys(eventSources)) {
                        eventHandlers.push(...eventSources[key]);
                    }
                }
                for (let handler of eventHandlers) {
                    handler.apply(null, args);
                }
            }
        }
        return this;
    }
}

function parseEventName(eventName) {
    let result = {
        eventName: eventName,
        namespace: ''
    };
    let namespaceIndex = eventName.indexOf('.');
    if (namespaceIndex !== -1) {
        result.eventName = eventName.substr(0, namespaceIndex);
        result.namespace = eventName.substr(namespaceIndex + 1);
    }
    return result;
}
