import React, { Component } from 'react';
import { Tree as AntdTree } from 'udesk-ui';
import TreeSelect from 'udesk-ui/es/tree-select';
import { getAttr, emptyFn } from '../utils';
import extend from 'lodash/extend';
import pick from 'lodash/pick';
import filter from 'lodash/filter';

const Tree = AntdTree;
export { Tree, TreeSelect };

export class TreeNodes extends Component {
    state = {};

    constructor(props) {
        super(props);

        extend(
            this.state,
            extend(
                {
                    idKey: 'id',
                    parentIdKey: 'parent.id',
                },
                pick(props, 'idKey', 'parentIdKey')
            )
        );
    }

    canShow(node) {
        const { canShow } = this.props;

        return !canShow || canShow(node);
    }

    filterChildNodes(node = 0) {
        const { data } = this.props;
        const { idKey, parentIdKey } = this.state;

        return filter(data, (item) => {
            let id = getAttr(item, parentIdKey);
            return id === node || id === node[idKey];
        });
    }

    renderTreeNodes(node) {
        const { genNodeProps = emptyFn, originData } = this.props;
        const { Container = Tree, idKey } = this.state;
        const props = genNodeProps(node);

        if (!node || !node[idKey]) return;

        const childNodes = this.filterChildNodes(node);
        const isHasChildNodes = originData && originData.filter((eachNode) => eachNode.parentId === node.id).length > 0;
        const finalProps = originData
            ? {
                  ...props,
                  isLeaf: !isHasChildNodes,
              }
            : { ...props };

        return (
            this.canShow(node) && (
                <Container.TreeNode {...finalProps}>
                    {childNodes.map((node) => this.renderTreeNodes(node))}
                </Container.TreeNode>
            )
        );
    }

    render() {
        let { Container = Tree, containerProps } = this.props;
        let rootNodes = this.filterChildNodes();

        containerProps = extend(
            {
                blockNode: true,
                defaultExpandAll: true,
            },
            containerProps,
            this.props
        );

        return <Container {...containerProps}>{rootNodes.map((item) => this.renderTreeNodes(item))}</Container>;
    }
}
