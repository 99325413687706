import React from 'react';
import echarts from 'echarts';
// import echarts from "echarts/lib/echarts";
// import "echarts/lib/chart/line";
// import "echarts/lib/chart/bar";
// import "echarts/lib/chart/pie";
import 'echarts-wordcloud';
import { getJSON } from '../utils';

import extend from 'lodash/extend';
import each from 'lodash/each';
import pick from 'lodash/pick';

import { UIBaseComponent } from '../utils/baseComponent';

export class Echarts extends UIBaseComponent {
    componentDidUpdate(prevProps) {
        if (prevProps.options !== this.props.options) {
            this.myChart && echarts.dispose(this.myChart);
            this.loadComponent();
        }
    }

    componentDidMount() {
        this.loadComponent();
    }

    componentWillUnmount() {
        this.myChart && echarts.dispose(this.myChart);
    }

    loadComponent() {
        const { options, handlers, isMap = false } = this.props;
        let myChart;

        myChart = this.myChart = echarts.init(this.refs.box, '', options);
        if (isMap) {
            getJSON('/static/map/china.json').always(res => {
                echarts.registerMap('china', res);
                myChart.setOption(options);
            });
        } else {
            myChart.setOption(options);
        }
        each(handlers, (handler, eventName) => {
            myChart.on(eventName, handler);
        });
        this.getMyChart();
    }

    getMyChart() {
        const { getChartApp } = this.props;
        getChartApp && getChartApp(this.myChart || null);
    }

    render() {
        const props = pick(this.props, 'className', 'style');

        props.style = extend(
            {
                width: '100%',
                height: '100%'
            },
            props.style
        );

        return <div ref="box" {...props} />;
    }
}
