import locales from 'locales';
import { message } from 'udesk-ui';

export function beforeUpload(cfg) {
    cfg = cfg || {};

    const file = cfg.file;
    const limitM = cfg.limitM || 10;
    const isLimit = file.size / 1024 / 1024 > limitM;

    if (isLimit) {
        message.error(
            locales.get('ui-upload-fspydhyzmsf', {
                limitM: limitM,
            })
        );
        return false;
    }
    return true;
}
